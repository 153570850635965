
          @import "@/assets/css/vars.scss";
        

































































































@import '@/assets/css/statsOverlay.scss';
.avtimeMarket {
    box-sizing:border-box;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin: 0 0;
    padding:10px 20px;
    height: 383px;
    width: calc(50% - 5px);
    position:relative;
     

    .up{
         display:flex;
         width:100%;
         box-sizing: border-box;
         // padding:0px 20px;
         justify-content: space-between;
         align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height, or 156% */

        letter-spacing: 0.05em;
        text-transform: uppercase;

        color: #5A7E87;
        div{
            display: flex;
            align-items: center;
        }
        p{
            margin-top:12px;
        }

     }
     .down {
         display: flex;
         justify-content: center;
         align-items: center;
         height: calc(100% - 100px);
         margin-top: -3px;
         margin-bottom: 3px;
         p{
            font-style: normal;
            font-weight: bold;
            font-size: 26px;
            line-height: 25px;
            /* identical to box height, or 115% */

            text-align: center;
            color: #062931;
            br{
                height: 0 !important;
            }
            span{
                padding-left: 5px;
            }
            .days{
                // display: inline-block;
                //  margin-top:6px;
                 padding-left: 0px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                // line-height: 20px;
                text-align: center;
                letter-spacing: 0.01em;
                text-transform: capitalize;

                color: #76898E;

            }
        }
       
        .pLine{
            width: 27px;
            height:3px;
            font-family: Gotham Pro;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 35px;
            /* identical to box height, or 117% */

            text-align: center;

            /* black */

            background-color: #062931;
        }
     }
}
.avtimeMarket.hideContent{
    height:fit-content;
    padding-bottom: 6px;
}
@media only screen and (max-width: 1024px){
    .avtimeMarket{
        border-radius: 0 !important;
        box-shadow: none;
        width: 100% !important;
        margin:0;
        margin-top:10px;
        height: 300px;

        .down{
            height: calc(100% - 72px);
        }
    }
}
@media only screen and (max-width: 550px){
    .avtimeMarket{
        border-radius: 0 !important;
        box-shadow: none;
        padding:10px 16px;
        height: 240px;

        .up{
            font-size: 15px;
            line-height: 25px;
            letter-spacing: 0.05em;
            align-items: initial;
            // margin-top: 10px;
            margin-top: 6px;

            p{
                //  margin-top:2px; 
                //  margin-top: 10px;
                margin-top: 6px;
               
            }
        }
        .up>div{
           height: fit-content;
           align-self: flex-start;
        }

        .down {
            height: calc(100% - 58px);
            p{
                font-size: 25px;
                line-height: 20px;
                span{
                    padding-left: 5px;
                }
                .days{
                   
                    font-size: 15px;
                }
            }
        }
    }
}

