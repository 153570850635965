
          @import "@/assets/css/vars.scss";
        

























































.wrapVideo{
    box-sizing: border-box;
    width:100%;
    background: #F8F8F9 ;
    background-color: #EEEFF3;
    display: flex;
    flex-direction: row;
    padding: 80px 0 100px 0;

    .wrapper-inner {
      width: 100%;
      padding: 0 30px;
      max-width: 1275px;
      margin: 0 auto;
      display: flex;
      align-items: initial;
      box-sizing: border-box;
      flex-wrap: wrap;
    }
    .modal{
        box-sizing:border-box;
        // width:60%;
        width:55%;
        // padding-right: 50px;
        // padding-right: 5%;
        // padding-right: 10%;
        padding-right: 7%;
        display: flex;
        justify-content: flex-end;
        align-items: initial;
        overflow: hidden;
        position: relative;
        height: 92%;
        .video{
            position: relative;
            overflow: hidden;
            width: 100%;
            // max-width: 100%;
            padding-top: 54%;
            border-radius: 9px;
            //max-width: 735px;
        }
        #iframe{
            // border-radius: 10px;
            // width: 100%;
            // height: 362px;
            // height: 376px;
           //transform: scale(1.01);
            // max-height: 362px;
            // height:initial;
            // height: calc((100vw - 40px) / (16/9));
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            // max-width: 100%;
            height: 100%;
            // height: 86%;
        }
       
    }
    .txt{
        box-sizing: border-box;
        width: 40%;
        padding-top:8px;
        h1{
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 40px;
            /* or 111% */


            /* black */

            color: #062931;

            margin-top: 0px;
            margin-bottom: 34px;
            
        }
        .button {
            text-decoration: none;
            margin-top: 53px;
            width: 168px;
            height: 52px;
            background: $main-color;
            border-radius: 52px;
            display:flex;
            justify-content: center;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0.01em;

            /* identical to box height, or 160% */

            display: flex;
            align-items: center;
            text-align: center;

            color: #FFFFFF;
            transition-duration: 0.3s;
        }
        .button:hover{
            // background: #132727;
            background: $btn-hover !important;
            cursor: pointer;
        }
    }
}

.colorBlackBlure{
  position: fixed !important;
  top: 0;
  left: 0;
  // opacity: 0.94;
  width: 100vw;
  height: 100vh;
  z-index: 211;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1270px) {
    .wrapVideo{
        // .video{
        //     position: relative;
        //     overflow: hidden;
        //     width: 60%;
        //     padding-right: 0px;
        //     margin-right: 5%;
        //     padding-top: 33.75%;
        //     #iframe{
        //          position: absolute;
        //         top: 0;
        //         left: 0;
        //         bottom: 0;
        //         right: 0;
        //         width: 100%;
        //         height: 100%;
        //     }
        // }
         .txt{
            box-sizing: border-box;
            width: 35%;
         }
    }
}
// @media only screen and (max-width: 1200px) {
//     .wrapVideo{
//         .video{
//             overflow: hidden;
//              height: 342px;
//             #iframe{
               
//                 width: 100%;
//                 //height: 342px;
//                  //transform: scale(1.01);
//                 // max-height: 362px;
//                 // height:initial;
//                 // height: calc((100vw - 40px) / (16/9));
//             }
//         }
//     }
// }
 @media only screen and (max-width: 1100px){
    .wrapVideo{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 38px 0;
        padding-bottom: 0;
        .txt{
            width:100%;
            // padding:60px;
            // padding: 60px 0;
            padding: 44px 62px 100px 59px;
            .button{
                width: 168px;
                height: 48px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                //line-height: 26px;
                P{
                    margin: 0;
                }

            }
        }
      .modal{  
            width:100%;
            justify-content: center;
            padding: 0 !important;
            height: initial !important;
            overflow: initial;
            
            //   .video{
            //     // width:100%;
            //     // justify-content: center;
            //     // padding: 0 !important;
            //     // height: initial !important;
            //     // overflow: initial;
            //     #iframe{
            //     //     position: static;
            //     //     border-radius: 10px;
            //     //     width: 90vw;
            //     //  //   height: 49vw;
            //     //     height: 51vw;
            //     }
            //   }
            .video{
                position: relative;
                overflow: hidden;
                width: 100%;
                padding-top: 56%;
                border-radius: 9px;
            }
            #iframe{
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

  }
   @media only screen and (max-width: 767px){
    .wrapVideo{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 0 !important;
       // padding-top:50px;
         .wrapper-inner {
             padding:0 0 !important;
         }
        .txt{
            width:100%;
            padding:20px;
            
          }
          .modal{
               padding:0 20px !important;
          }
        // .video{
        //     width:100%;
        //     box-sizing:border-box;
        //     padding:0 20px !important;
        //     justify-content: center;
        //     #iframe{
        //         border-radius: 10px;
        //         // width: 90vw;
        //         // height: 60wv;
        //     }
        // }
    }

  }

 @media only screen and (max-width: 600px){
    .wrapVideo{
        .txt{
            .button{
               box-sizing: border-box;
                padding-top: 12px;
                margin-left: 10px;
                width: 140px;
                height: 45px;
                align-items: initial;
                p{
                    margin: 0px;
                }

            }
        }
    }
 }

