
          @import "@/assets/css/vars.scss";
        

































































































.newsroom{
  width:100wh;
}

