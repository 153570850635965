
          @import "@/assets/css/vars.scss";
        










































































































































































.slider-wrapper.horizontal {
    width: 500px;
  .sections-wrapper {
    display: flex;
    flex-direction: row;
    height: 135px;
    position: relative;
    width: 100%;
    // margin-bottom: 5px;
    text-transform: lowercase;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    /* or 133% */

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    /* Gray 3 text */

    color: #9498A0;

    div {
      //   border-left: 1px solid #DEE0E5;
      border-left: 0.5px solid #062931;

      height: 30px;
      box-sizing: border-box;
      padding-left: 10px;
      position: absolute;
      bottom: -25px;
    }
    .below{
      left: 1%;
    }
    .fair {
      left: 40%;
    }
    .above {
      left: 69%;
    }
     .max {
      left: 99%;
    }
    span {
        position: absolute;
        top:35px;
        left: -25px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;

        letter-spacing: 0.01em;
        width: 100%;

        /* black */

        color: #062931;
            
    }
  }
  .slider {
    width: 100%;
    // margin-bottom: 20px;
    // padding-bottom: 20px;

    .slider-background {
      width: 500;
      position: relative;
      #sliderBoi {
        -webkit-appearance: none;
        outline: none;
        width: 500px;
        height: 10px;
        background: linear-gradient(90deg, rgba(238, 239, 243, 0) 1.8%, #EEEFF3 74.8%), #51E6FB;
        border: 1px solid #E8E9ED;
        border-radius: 10px;
        margin-left: 0;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 20px;
          height: 20px;
          // background: #000; 
          cursor: pointer;
          z-index: 3;
          position: relative;
        }
      }
      .slide {
        height: 100px;
        width: 20px;
        position: absolute;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .slide-card {
          width: 114px;
          height: 60px;
          background: #fff;
          border: 1px solid #E8E9ED;
          border-radius: 5px;

          font-family: 'DM Sans';
          font-style: normal;
          font-weight: 700;

          .slide-card-header {
            background-color: rgb(238, 239, 243);
            display: flex;
            justify-content: center;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
          }

          .slide-card-price {
            max-height: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 22px;
            line-height: 25px;
            color: #062931;
          }

          .arrow-down-bg {
            height: 0;
            width: 0;
            position: absolute;
            bottom: 31px;
            left: 3px;

            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            
            border-top: 8px solid #E8E9ED;
          }

          .arrow-down {
            height: 0;
            width: 0;
            position: absolute;
            bottom: 32px;
            left: 4px;

            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            
            border-top: 7px solid #fff;
          }
        }
        .slide-thumb {
          width: 20px;
          height: 20px;
          background: #062931;
          border: 0.5px solid #DEE0E5;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
          border-radius: 19px;
        }
      }
    }
  }
  .prices-wrapper {
    display: flex;
    flex-direction: row;
    // color:red;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    letter-spacing: 0.01em;
    width: 100%;

    /* black */

    color: #062931;



    .below {
      width: 40%;
      position: relative;

    }
    .fair {
      width: 29%;
      position: relative;
      
    }
    span {
      position: absolute;
      right: -30px;
      min-width: 35px;
      display: none;
    }
  }
}
.slider-wrapper.vertical {
  flex-direction: row-reverse;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 300px;
  margin: 0 auto;

  .sections-wrapper {
    display: flex;
    flex-direction: column;
    height: 520px;
    position: relative;
    width: 135px;
    margin-left: 5px;
    text-transform: lowercase;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    /* or 133% */

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    /* Gray 3 text */

    color: #9498A0;

    div {
      border-bottom: 1px solid #DEE0E5;
      height: 20px;
      box-sizing: border-box;
      padding-left: 5px;
      position: absolute;
      width: 50px;
      font-size: 13px;
      left: -35px;
    }
    .max {
        bottom: 99%;
    }
    .fair {
      bottom: 41%;
    }
    .above {
      bottom: 68%;
    }
    .below {
      bottom: 0%;
      bottom: 1%;
    }
    span{
       position: absolute; 
       left:60px;
       top:10px;
       width: fit-content !important;
       font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        /* identical to box height, or 154% */
        white-space: nowrap;
        letter-spacing: 0.01em;

        /* black */

        color: #062931;

    }
  }
  .slider {
    width: 10px;
    height: 520px;

    .slider-background {
      height: 520px;
      position: relative;

      #sliderBoi {
        -webkit-appearance: none;
        transform: rotate(-90deg);
        position: absolute;
        top: 253px;
        left: -256px;
        width: 520px;
        height: 10px;
        background: linear-gradient(90deg, rgba(238, 239, 243, 0) 0%, #EEEFF3 100%), #51E6FB;
        border: 1px solid #E8E9ED;
        border-radius: 10px;
        margin: 0;
        z-index: 990;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          outline: none;
          border: none;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: transparent;
          // background: #0f0; 
          cursor: pointer;
          z-index: 999;
          position: relative;
        }

      }
      .slide {
        width: 154px;
        height: 20px;
        position: absolute;
        right: -6px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .slide-card {
          width: 114px;
          height: 60px;
          background: #fff;
          border: 1px solid #E8E9ED;
          border-radius: 5px;

          font-family: 'DM Sans';
          font-style: normal;
          font-weight: 700;

          .slide-card-header {
            background-color: rgb(238, 239, 243);
            display: flex;
            justify-content: center;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
          }

          .slide-card-price {
            max-height: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 22px;
            line-height: 25px;
            color: #062931;
          }

          .arrow-right-bg {
            height: 0;
            width: 0;
            position: absolute;
            top: 3px;
            right: 31px;

            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;

            border-left: 8px solid #E8E9ED;
          }

          .arrow-right {
            height: 0;
            width: 0;
            position: absolute;
            top: 4px;
            right: 32px;

            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;

            border-left: 7px solid #fff;
          }
        }

        .slide-thumb {
          width: 20px;
          height: 20px;
          background: #062931;
          border: 0.5px solid #DEE0E5;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
          border-radius: 19px;
          pointer-events: none;
          z-index: 990;
        }
      }
    }
  }
  .prices-wrapper {
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    height: 520px;
    padding-right: 20px;
    text-align: right;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    /* identical to box height, or 154% */

    letter-spacing: 0.01em;

    /* black */

    color: #062931;


    .below {
      margin-bottom: 204px;
      display: none;
    }
    .fair {
      margin-bottom: 126px;
      display: none;
    }
  }
  @media only screen and (max-width: 450px){
    .slider-wrapper.vertical{
      .slider {
        .slider-background {
          .slide {
            .triangle {
              height: 0;
              width: 0;

              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 10px solid green;
            }
          }
        }
      }
    }
    .prices-wrapper {
      flex-grow: 0;
    }
  }
}
@media only screen and (max-width: 768px){
  .instruction {
    margin-bottom: 50px !important;
  }
}

