
          @import "@/assets/css/vars.scss";
        













































































.unsubscribe-wrapper {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;

  .msg {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .text {
      padding: 20px;
      border-radius: 5px;
      // border: 2px solid $main-color;
      border: 2px solid $err-color;
      padding-top: 22px;
      font-family: 'Gotham Pro Medium';
      color: $main-color;
      margin-top: -20%;
    }
  }
  .footerAbso {
    margin-top: auto;
  }
}
