
          @import "@/assets/css/vars.scss";
        




















































































































































































































































































































































































































































































@media only screen and (max-width: 1000px){ //768px
    .wrappOnePressReleases{
        .holdContent{
            .content{
                 .sharePressRele{
                     margin-bottom: 38px;
                  }
            }
        }
    }
}
@media only screen and (max-width: 1000px){ //768px
    .wrappOnePressReleases{
        .holdContent{
            .content{
                 .sharePressRele{
                    //  margin-bottom: 60px;
                     margin-bottom: 23px;
                  }
            }
        }
    }
}

#aSnipet{
   transition-duration: .1s;
}
#aSnipet:hover {
      background-color:  $btn-hover!important;
}
