
          @import "@/assets/css/vars.scss";
        















































































































.pricing-wrapper {
  .header-banner {
    width: 100%;
    height: 185px;
    background: linear-gradient(116.78deg, #104756 13.98%, #3D7484 75.44%);
    position: relative;
    display: flex;
    justify-content: center;

    h1 {
      color: #fff;
      font-size: 36px;
      margin: 0;
      position: absolute;
      bottom: 47px;
    }
  }
  .select-text {
    width: 100%;
    font-size: 17px;
    font-weight: 500;
    color: #787883;
    opacity: 0.7;
    margin: 52px 0 43px 0;
    text-align: center;
  }
  .cards-wrapper {
    width: 1041px;
    height: 255px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 98px;
  }
  .contact-us-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .question {
      h1 {
        font-size: 36px;
        line-height: 40px;
        color: #5A7E87;
        margin: 0;
      }
    }
    .contact-text {
      width: 418px;
      line-height: 30px;
      font-size: 18px;
      color: #062931;
      text-align: center;
      margin: 26px 0 41px 0;
    }
    .contact-btn {
      height: 44px;
      width: 160px;
      color: #fff;
      background-color: #FF6359;
      border-radius: 55px;
      border: none;
      font-family: inherit;
      font-size: 15px;
      cursor: pointer;
    }
  }
  .faq-wrap {
    width: 100%;
    margin-top: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .faq-title {
      h1 {
        margin: 0;
        font-size: 36px;
        line-height: 40px;
        color: #5A7E87;
      }
      margin-bottom: 42px;
    }
    .faq-list {
      width: 743px;
      max-width: 743px;
    }
    .faq-btn {
      width: 743px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 60px 0 100px 0;
      border-radius: 50px;
      border: 1px solid rgba(6, 41, 49, 0.8);

      font-weight: 500;
      font-size: 17px;
      color: #062931;

      &:hover {
        border: 1.8px solid #062931;
      }
    }
  }
  .filler {
    height: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .pricing-wrapper {
    .cards-wrapper {
      width: 466px;
      height: 825px;
      flex-direction: column;

    }
  }
}

@media only screen and (max-width: 480px) {
  .pricing-wrapper {
    .header-banner {
      h1 {
        font-size: 34px;
        left: 16px;
        bottom: 32px;
      }
    }
    .select-text {
      width: 343px;
      text-align: unset;
      margin: 32px auto 23px auto;
      font-size: 16px;
    }
    .cards-wrapper {
      width: 343px;
      margin-bottom: 58px;
    }
    .contact-us-wrap {
      width: 343px;
      text-align: unset;
      align-items: unset;
      margin: 0 auto;

      .contact-text {
        text-align: unset;
        width: 343px;
        margin: 32px 0 30px 0;
      }
    }
    .faq-wrap {
      width: 343px;
      margin: 59px auto 0;

      .faq-list {
        width: 343px;
      }

      .faq-btn {
        width: 343px;
        height: 75px;
        font-size: 16px;
        margin: 40px 0 60px;
        span {
          width: 295px;
          text-align: center;
        }
      }
    }
  }
}
