
          @import "@/assets/css/vars.scss";
        






























































































.user-menu-wrapper {
  width: 100%;
  height: 100vh;
  z-index: 999;
  background-color: rgba(6, 41, 49, 0.9);

  position: absolute;
  box-sizing: border-box;
  bottom: 60px;
  left: 0;
  display: flex;
  align-items: flex-end;
  .menu-wrapper {
    width: 100%;
    background-color: #fff;
    border-radius: 11px 11px 0 0;

    padding-top: 27px;

    .text-wrapper {
      width: 100%;
      .title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.05em;
        color: #467F8F;

        padding-left: 30px;
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EEEFF3;
      }
      .section {
        width: 100%;
        .section-item {
          padding-left: 30px;
          height: 60px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #EEEFF3;
        }
        > *:last-child {
          padding-bottom: 12px;
        }
      }
    }
  }
}
