
          @import "@/assets/css/vars.scss";
        





































































































.report-wrap {
  width: 100%;
  height: 50px;
  background-color: #FFF;
  display: flex;
  align-items: center;
  color: #062931; 
  .icon {
    height: 16px;
    width: 17.5px;
    margin: 0 17px 0 15px;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
  .main-info-wrap {
    display: flex;
    .car-info-wrap {
      width: 462px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .car-name {
        font-size: 17px;
        line-height: 25px;
        font-weight: 500;
      }
      .dot {
        margin: 0 7px;
        height: 5px;
        width: 5px;
        border: 1px solid #062931;
        border-radius: 50%;
      }
      .car-info {
        // max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
      }
    }
    .expire {
      width: 97px;
      font-size: 15px;
      line-height: 25px;
      font-weight: 500;
      margin-right: 10px;
    }
    .expired {
      color: #FF6359;
    }
    .price {
      width: 97px;
      font-weight: 500;
      font-size: 15px;
      line-height: 25px;
      display: flex;
      margin-right: 10px;
  
      .stonk-ico {
        height: 9px;
        width: 9px;
        margin-left: 5px;
        img {
          height: 100%;
        }
      }
    }
    .stonks {
      color: #4C94FF;
    }
  }
  .btn-wrap {
    .btn {
      width: 83px;
      height: 30px;
      line-height: 30px;
      margin: 0 14px 0 10px;
      font-weight: 500;
      font-size: 15px;
      // padding: 0 25px;
      color: #fff;
      background-color: #FF6359;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .link {
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .report-wrap {
    height: 63px;
    align-items: unset;
    padding-top: 12px;
    .icon {
      margin-top: 5px;
    }
    .main-info-wrap {
      .car-info-wrap {
        flex-direction: column;
        align-items: unset;
        width: 300px;
        .dot {
          display: none;
        }
      }
      .expire {
        width: 94px;
        margin-right: 30px;
      }
      .price {
        width: 94px;
        margin-right: 30px;
      }
    }
    .btn-wrap {
      .btn {
        margin-right: 10px;
        margin-left: 14px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .report-wrap {
    max-height: 125px;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 15px;
    .icon {
      margin: 2px 13px 0 14px;
    }
    .main-info-wrap {
      flex-direction: column;
      width: 220px;
      .car-info-wrap {
        width: 100%;
        align-items: unset;
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
        .car-name {
          font-size: 16px;
        }
        .car-info {
          font-size: 14px;
          line-height: 20px;
          white-space: unset;
          overflow: unset;
          text-overflow: unset;
          margin-bottom: 4px;
        }
      }
      .expire {
        font-size: 14px;
        line-height: 20px;
        width: fit-content;
      }
      .price {
        margin-top: 5px;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .btn-wrap {
      .btn {
        width: 74px;
        font-size: 14px;
      }
    }
  }
}
