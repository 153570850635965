
          @import "@/assets/css/vars.scss";
        

















































































.delete-blogs-wrapper {
  .show-more-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 120px;
    max-width: 735px;

    .show-more {
      cursor: pointer;
      font-size: 15px;
      line-height: 24px;
      color: #5A7E87;
      // font-family: 'Gotham Pro Medium';
    }
  }
}
