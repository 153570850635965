
          @import "@/assets/css/vars.scss";
        

















.hero{
    position:relative;
    box-sizing: border-box;
    display:flex;
    justify-content: center;
    align-items: center;
    height:480px;
    background: rgb(0, 0, 0);
    // background-image: url("../../assets/images/newsroom.svg");
    background-image:  url("../../assets/images/ovaodje.png");
    background-repeat: no-repeat;
    background-position: center center; 
    //background-position: center;
    // background-size: cover;
    .hero1{
      position: absolute;
      top:0;
      right:0;
      width:100%;
      height:100%;
      overflow: hidden;
      background: #000000;
      opacity: 0.25;
      z-index: 0;
    }
    .divText{
      margin-top: -11px;
      z-index: 1;
      text-align: center;
      padding:10px;
      h1{
      // font-family: 'Gotham Pro Bold';
      // font-style: normal;
      // font-weight: bold;
      // font-size: 42px;
      // line-height: 40px;
      font-weight: bold;
      font-size: 51px;
      line-height: 55px;
      /* identical to box height, or 108% */

      // display: flex;
      // align-items: center;
      color: #FFFFFF;
      margin-bottom:15px;
      }
      p{
        // font-family: Gotham Pro;
        // font-style: normal;
        // font-weight: normal;
        // font-size: 18px;
        // line-height: 30px;
        /* identical to box height, or 167% */
        font-weight: normal;
        font-size: 26px;
        line-height: 35px;
        /* identical to box height, or 135% */

        text-align: center;

        /* White */

        color: #FFFFFF;

        // text-align: center;
        // color: #E7ECED;
        br{
          display:none;
        }
      }
    }
}
  @media only screen and (max-width: 767px){
    .hero{
      .divText{
        box-sizing: border-box;
        width: 580px;
        h1{
          font-weight: bold;
          font-size: 46px;
          line-height: 50px;
          margin-bottom:18px;
        }
        p{
          font-weight: normal;
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
   
  }
  @media only screen and (max-width: 500px){
    .hero{
      .divText{
        width:294px;
        h1{
          font-weight: bold;
          font-size: 46px;
          line-height: 50px;
          margin-bottom:18px;
        }
        p{
          font-weight: normal;
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
   
  }
  @media only screen and (max-width: 375px){
    
    .hero{
    
      div{
        padding:0px;
        p{
          br{
            display:initial !important;
          }
        }
      }
    }
  }
