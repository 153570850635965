$background-color: #EEEFF3;
$main-color: #FF5654;
$btn-hover: #FF4B49;
$err-color: #FF41D5;
$main-font: 'Gotham Pro';
$green-color: #103D49;
$text-lighter: #787883;
$text-darker: #082633;
$link:#5A7E87; /* bold */
$link-hover: #134753;
