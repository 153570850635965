
          @import "@/assets/css/vars.scss";
        























































.html-snippets-wrapper {
  .snippet-wrapper {
    margin-bottom: 20px;
    textarea {
      width: 100% !important;
      height: 100px !important;
    }
  }
}
