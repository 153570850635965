
          @import "@/assets/css/vars.scss";
        




































































































































.wrappProfile{
    z-index:1000;
    min-height: calc(100% - 80px);
    width:100% !important;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
    display:flex;
    margin: 0 auto;
    padding-top: 87px;
    padding-top: 98px;
     .holdContent {
        box-sizing: border-box;
        background: rgb(255, 255, 255);
        // display:flex;
        // justify-content: center;
        // align-items: center;
        padding: 0 30px;
        max-width: 1275px;
        width:1275px;
        margin: 0 auto;
        // min-height: 588px;
       
     }
}
 @media only screen and (max-width: 1305px){
     .wrappProfile{
        // flex-direction: column-reverse;
         width:100%;
         display: flex;
         .holdContent{
               display: flex;
                width:100%;
                display: flex;
                // justify-content: center;
                // align-items: center;
                // padding: inherit 0 !important;
         }
     }
 }

// @media only screen and (max-width: 900px){
//      .wrappProfile{
//             //  flex-direction: column;
//             //  align-items: flex-start;
//             // height: 120px;
//             //  padding:22px 35px;
//           .holdContent{
//             box-sizing:border-box;
//             height: 120px;
//             padding:22px 15px;
//             padding: 0 10px;
//         }
//      }
// }
@media only screen and (max-width: 767px){
     .wrappProfile{
         height: initial !important;
        //  padding-top: 47px;
         padding-top: 48px;
        .holdContent{
            padding:0px 35px;
           
            height:initial;
        }
    }
}
@media only screen and (max-width: 750px){
     .wrappProfile{
         height: initial !important;
         //  padding-top: 72px;
         padding-top: 73px;
        .holdContent{
            padding:0px 16px;
            height:initial;
            width: 100%;
            box-sizing: border-box;
        }
    }
}

