
          @import "@/assets/css/vars.scss";
        














































































































.more-menu-wrapper {
	// height: calc(100vh - 61px);
	height: calc(100% - 61px);
	overflow-y: auto;
	// height: 836px;
	// height: 100vh;
	width: 100%;
	background-color: #fff;
	box-sizing: border-box;
	position: absolute;
	z-index: 999;
	bottom: 60px;
	left: 0;
	padding-top: 10px;
	pointer-events: auto;
	div:last-child {
		border-bottom: none;
	}

	.section {
		width: 100%;
		border-bottom: 2px solid #DEE0E5;
		div:last-child {
			border-bottom: none;
		}
		.section-item {
			padding: 0 30px;
			height: 60px;
			// width: 100%;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #eeeff3;

			.icons-wrap {
				width: 132px;
				height: 36px;
				display: flex;
				justify-content: space-between;
				.icon-wrap {
					display: flex;
					justify-content: center;
					align-items: center;

					height: 36px;
					width: 36px;
					border-radius: 50%;
					background-color: #eeeff3;
				}
			}
		}
		.special {
			justify-content: space-between;
		}
		.title {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0.05em;
			color: #467F8F;
			text-transform: uppercase;
		}
	}
	> *:last-child {
		> *:last-child {
			padding-bottom: 12px;
		}
	}
}
@supports (-webkit-overflow-scrolling: touch) {
  .setcover1 {
    // mobileNav - height: 90px;
    bottom: 90px !important;
  }
}
