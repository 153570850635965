
          @import "@/assets/css/vars.scss";
        
















































































































.shareFooter{
    margin:0px;
    height:420px;
    background: #5C96A7;
    // border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    .shareHeaderTxt{
        //  padding-top:76px;
        padding-top: 72px;
        //  margin-bottom: 34px;
        margin-bottom: 33px;
        .shareHeader{
            font-style: normal;
            font-weight: bold;
            font-size: 46px;
            line-height: 55px;
            /* identical to box height, or 120% */
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;

            margin: 0;
        }
        .shareTxt{
            text-align: center;
            // color: #062931;
            // margin-bottom:0;
            margin-top: 10px;
            margin-bottom: 5px;
            // font-weight: 500;
            // font-size: 17px;
            // line-height: 25px;
            // color: #ffffff;

            font-size: 17px;
            line-height: 25px;
            text-align: center;
            color: #103D49;

        }
        .link{
            margin-bottom: 0;
            margin-top: 0;
            text-align: center !important;
            color: #CFD8DB;
            font-size: 17px;
            line-height: 25px;
            transition: 0.3s;
        }
         .link:hover{
           cursor: copy;
        }
        .link:active{
           cursor: grabbing;
           color: #bfc2c3;
        }

    }
    .shareIcon{
        display: flex;
        justify-content: center;
        align-items: center;
        .spanImg{
            margin:0 15px;
            width:70px;
            text-decoration: none!important;
            text-align: center;
            a{
                // display: inline-block;
                // height: fit-content;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            
                .aLink{
                    // display: inline-block;
                    height: 70px !important;
                    margin: 0;
                }
                p{
                    text-decoration: none!important;
                    // margin:5px 0;
                   
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    /* identical to box height, or 133% */

                    text-align: center;
                    letter-spacing: 0.01em;

                    /* text 80 */

                    color: #CFD8DB;
                    margin:10px 0;
                }
            }
        }
    }


}
a{
    text-decoration: none;
    }
@media only screen and (max-width: 768px){
            .shareFooter{
                // padding:0 26px;
                .shareHeaderTxt{
                    // padding-top:27px;
                    margin-bottom: 37px;
                    .shareHeader{
                        box-sizing: border-box;
                        // font-size: 42px;
                    }
                    .shareTxt{
                         box-sizing: border-box;
                        //  font-size: 13px;
                    }
                    .link{
                         box-sizing: border-box;
                        //  font-size: 13px;
                    }

                }
                .shareIcon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .spanImg{
                        margin:0 10px;
                        text-align: center;
                        .aLink{
                                height: 60px !important;   
                        }
                        p{
                           font-size: 15px;
                            line-height: 20px;
                        }
                    }
                }


        }
  }
@media only screen and (max-width: 550px){
            .shareFooter{
                box-sizing: border-box;
                padding:0 26px;
                margin-bottom: 0px;
                height:355px;
                .shareHeaderTxt{
                    // padding-top:27px;
                     padding-top: 54px;
                    margin-bottom: 38px;
                    text-align: center;
                    .shareHeader{
                        font-size: 40px;
                        line-height: 45px;
                    }
                    .shareTxt{
                        margin-top: 8px;
                        font-size: 16px;
                        line-height: 25px;
                        width: 255px;
                        
                       
                    }
                    .link{
                      width: 275px;
                       font-size: 16px;
                       line-height: 25px;
                       overflow: hidden;
                       text-overflow: ellipsis;
                       white-space: nowrap;
                    }

                }
                .shareIcon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .spanImg{
                        margin:0 13px;
                        text-align: center;
                         width:50px;
                         a{
                            .aLink{
                                    height: 50px !important;   
                            }
                            img{
                                width: 50px;
                            }
                            p{
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 20px;
                                /* identical to box height, or 143% */

                                text-align: center;

                                /* text 80 */

                                color: #CFD8DB;
                            }
                         }
                    }
                }


        }
  }

