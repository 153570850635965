.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  background: rgba(205, 216, 216, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  .overlay-btn {
    width: 160px;
    height: 40px;
    background: #134753;
    border-radius: 52px;
    font-size: 15px;
    line-height: 40px;
    color: #E7ECED;
    // font-family: 'Gotham Pro Medium';
    z-index: 12!important;
    // z-index: 5011;
    cursor: pointer;
    text-align: center;
  }
}
