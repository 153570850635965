
          @import "@/assets/css/vars.scss";
        


























































.wrappOneBadge{
  //margin:15px 0;
  margin:0;
  padding: 0;
}
.badge{
  margin-bottom: 15px;
  padding:7px 20px 8px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 20px;
  height: max-content;
  max-height:40px;
  display: flex;
  justify-content: center;
  align-items:center;
  // float: left;
  margin-right:10px;
  white-space: nowrap !important;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
}
.badgeMark {
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}
.badgeMark:hover{
  box-sizing: border-box;
  cursor: initial;
  border: 1.3px solid #fff;
  // padding:7px 20px 8px 20px;
  padding:6.7px 19.7px 7.7px 19.7px;
}
.badgeMark2{
  border: 1px solid #FFFFFF;
  color: #FFFFFF;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.8);
  }
  
}
.pointer{
  cursor: pointer !important;
}
.cap{
  text-transform: capitalize;
}
@media only screen and (max-width: 500px){
  .badge{
    // margin-bottom: 15px;
    // margin-bottom: 20px;
    margin-bottom: 10px;
  }
}
