
          @import "@/assets/css/vars.scss";
        
























































































































































.about-outer-wrapper {
  min-height: calc(100vh - 150px);

  .img-wrapper {
    height: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 300px;
    overflow: hidden;
    background-image: url("../assets/images/ValuationBackground3.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;

    img {
      height: 100%;
      margin-top: -150px;
    }
  }

  .content {
    max-width: 1275px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 30px;
    padding-bottom: 114px;
    display: flex;
    flex-direction: row;
    // margin-bottom: 30px;
    flex-wrap: wrap;
    justify-content: space-between;

    .outer-wrapper {
      width: 100%;
      max-width: 735px;
      position: relative;
      display: flex;
      // margin-top: 30px;
      margin-top: 60px;
    }

    .about-video-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      max-width: 735px;
      border-radius: 5px;

      #about-video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }

    h1 {
      // font-size: 30px;
      // line-height: 30px;
      // margin-top: 40px;
      margin-top: 50px;
      margin-bottom: 34px;
      width: 100%;

      font-weight: bold;
      font-size: 36px;
      line-height: 40px;

      color: #062931;
    }
    .about {
      // font-size: 16px;
      // line-height: 24px;
      // color: #062931;
      max-width: 735px;
      font-weight: normal;
      font-size: 17px;
      line-height: 25px;
      /* or 147% */

      /* black */

      color: #062931;
      p{
        margin-bottom: 15px;
        margin-top: 0;
      }
    }
    .address-wrapper {
      width: 100%;
      margin-top: 46px;
      margin-top: 38px; // 14px up
      margin-top: 24px;
      span {
        // color: #6A7F83;
        // font-size: 13px;
        // line-height: 24px;
        // font-family: 'Gotham Pro Medium';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */

        letter-spacing: 0.05em;
        text-transform: uppercase;

        /* Gray turquoise - */

        color: #9cb2b5;
      }
      div {
        color: #062931;
        // font-size: 16px;
        // line-height: 24px;
        // font-family: 'Gotham Pro';
        font-size: 17px;
        line-height: 25px;
      }
      div.lower {
        // margin-bottom: 20px;
        a{
          color: #062931;
        }
      }
      
    }
    
  }
  .linkMail{
    text-decoration: none;
    font-weight: 500;
    color: #062931 !important;
    }
    .linkMail:hover{
      opacity: .8;
    }
}

@media only screen and (max-width: 767px) {
  .img-wrapper {
    background-position: calc(50% + 120px) 50% !important;
  }
  // .content {
  //   padding: 0 30px !important;
  // }
}
@media only screen and (max-width: 1150px) {
  .about-outer-wrapper {
    .content {
      flex-direction: column !important;
      // margin-bottom: 84px !important;
      // margin-bottom: 93px;
      padding-left: 24px;
      padding-right: 27px;
      padding-bottom: 93px;
      margin-bottom: 0 !important;

      .outer-wrapper {
        margin-top: 30px;
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .about-outer-wrapper {
    // padding-bottom: 40px;
    .content {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 53px;
      //  margin-bottom: 100px;
      .outer-wrapper {
        // margin-top: calc( 68px + 10px)!important;
        margin-top: 80px;
      }

      h1 {
        font-size: 34px;
        line-height: 40px;
        margin-top: 30px !important;
        margin-bottom: 34px;
      }
    }
    .about {
      font-size: 16px;
      line-height: 25px;
    }
    .address-wrapper {
      margin-top: 53px !important;
    }
  }
}
@media only screen and (max-width: 450px) {
  .content {
    margin-bottom: 76px !important;
  }
}
