
          @import "@/assets/css/vars.scss";
        






























































































































































































































































































































































































.edit-blogs-wrapper {
  padding-bottom: 30px;

  .no-selection {
    .show-more-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 120px;
      max-width: 735px;

      .show-more {
        cursor: pointer;
        font-size: 15px;
        line-height: 24px;
        color: #5A7E87;
        // font-family: 'Gotham Pro Medium';
      }
    }
  }
  .current-image-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .remove {
      cursor: pointer;
      margin-left: 20px;
    }
    .current-image {
      max-height: 150px;
      width: auto;
    }
  }

  .return {
    margin-bottom: 30px;
    margin-left: auto;
    cursor: pointer;
    width: 50px;
  }
  .btn-wrapper {
    display: flex;
    flex-direction: row;

    .btn:first-child {
      margin-right: 20px;
    }
  }
  .btn.reset {
    background: white;
    border: 1px solid $main-color;
    color: $main-color;
  }
  .btn {
    height: 50px;
    width: 170px;
    line-height: 52px;
    color: white;
    // font-family: 'Gotham Pro Medium';
    text-align: center;
    background: $main-color;
    border-radius: 52px;
    cursor: pointer;
    transition-duration: .3s;
    margin-bottom: 40px;
  }
  // .btn:hover {
  //   background: #FF4B49;
  // }
  .btn:hover{
     background-color:  $btn-hover!important;
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .title {
      margin-bottom: 5px;
    }
    .setCheck{
      display: flex;
      flex-wrap: wrap;


      .itemCheck{
        margin-right:20px;
        word-wrap: unset;
        padding: 10px ;
      }
    }
  }
  .preview {
    margin-top: 30px;
    margin-bottom: 30px;

    .content {
      border: 1px solid rgb(204, 204, 204);
      padding: 10px;
    }
  }
}
