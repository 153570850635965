
          @import "@/assets/css/vars.scss";
        


















































































































































































































































































































































































































































.wrappSignupModal{

   .or {
           color: #062931;
           // padding-top: 7px; // 15 px margin from input field // 22 
           padding-top: 4px;
           padding-top: 0px;
           padding-bottom: 23px;
   }
   .basic {
       // margin-bottom: 15px ;
   }
   
}
@media only screen and (max-width: 500px){
    .wrappSignupModal{

   .or {
       
       
           color: #062931;
           padding-top: 2px; // 15 px margin from input field // 22 
           padding-bottom: 19px;
   }
}
}

