
          @import "@/assets/css/vars.scss";
        
































































































































































































































































































































































 .wrappLoginModal{
    .loginComponent{
        .last{ // basic:last-of-type
            // margin-bottom: 10px;
            // margin-bottom: 8px;
            margin-bottom: 7px;
        }
        .rember {
            // margin-top: 26px;
            // margin-bottom: 26px;
            margin-top: 31px;
            // margin-top: 29px;
            // margin-bottom: 25px;
            margin-top: 37px;
            margin-bottom: 30px ;
        }
    }
    .haveaccount{
        margin-top: 25px !important;
    }
 }
@media only screen and (max-width: 500px){
    .wrappLoginModal{
        .loginComponent{
            // .basic:last-of-type{
            //     margin-bottom: 10px;
            // }
            .rember {
                margin-top: 26px;
                margin-bottom: 23px;
            }
        } 
    } 
}

