
          @import "@/assets/css/vars.scss";
        



































.delete-wrapper {
  .tabs {
    margin-bottom: 20px;
    span {
      margin-right: 20px;
      cursor: pointer;
    }
    span:last-child {
      margin-right: 0px;
    }
    span.active {
      color: $main-color;
    }
  }
}
