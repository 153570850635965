
          @import "@/assets/css/vars.scss";
        


































































































































.terms{
  width:100%;
  box-sizing: border-box;
  .content{
    max-width: 1275px;
    margin: 0 auto;
    box-sizing: border-box;
    // padding: 50px 30px;
    padding: 98px 30px;
    padding-bottom: 113px;

    .inner-wrapper {
      max-width: 733px;

        .main{
          // font-style: normal;
          // font-weight: bold;
          // font-size: 30px;
          // line-height: 50px;
          /* identical to box height, or 167% */

          // display: flex;
          // align-items: center;

          // /* black */

          // color: #062931;

          font-weight: bold;
          font-size: 36px;
          line-height: 40px;
          /* identical to box height, or 111% */

          display: flex;
          align-items: center;

          /* black */

          color: #062931;
          margin-top: 0;
          margin-bottom: 54px;
        }
        .text{
          // font-style: normal;
          // font-weight: normal;
          // font-size: 16px;
          // line-height: 24px;
          // /* or 150% */


          // /* black */

          // color: #062931;
          // margin-bottom:33px;

          font-weight: normal;
          font-size: 17px;
          line-height: 25px;
          /* or 147% */


          /* black */

          color: #062931;
          .siteLink{
            color: #062931;
            font-weight: 500;
            text-decoration: none;
          }
          .siteLink:hover{
            color: $btn-hover;
          }

        }
        .team {
          // font-family: 'Gotham Pro Medium';
          // font-style: normal;
          // font-weight: 500;
          // font-size: 16px;
          // line-height: 24px;

          color: #062931;
          font-weight: 500;
          font-style: italic; 
          font-size: 17px;
          line-height: 25px;
        }
      }
  }
}
@media only screen and (max-width: 1200px) {
  .terms{
    width:100%;
    box-sizing: border-box;
    .content{
      box-sizing: border-box;
      //margin: 0 30px;
      padding: 48px 27px 93px 31px ;
      // margin: 0 auto;
      //width: 633px;
       box-sizing: border-box;
       width:100%;
       .inner-wrapper {
         box-sizing: border-box;
          max-width:100%;

          .team{
            margin-bottom:0px;
          }
       }
    }
  }
}
 @media only screen and (max-width: 767px) {
   .terms{
     box-sizing: border-box;
     width:100%;
    .content{
      box-sizing: border-box;
      width: 100% !important;
      padding: 73px 16px;
      padding: 78px 16px;
      margin-left: 0;
      .inner-wrapper{
        .main{
          // font-size: 28px;
          // line-height: 50px;
          font-size: 34px;
          line-height: 40px;
         
          color: #082633;
          margin-bottom: 34px;
        }
        .text{
          // font-family: Gotham Pro;
          // font-style: normal;
          // font-weight: normal;
          // font-size: 15px !important;
          // line-height: 24px;

          font-size: 16px;
          line-height: 25px;  
          
          color: #082633;
        }
        .team{
          font-size: 16px;
          line-height: 25px;  
        }
      }
    }
  }
 }
  // @media only screen and (max-width: 767px) {
  //  .terms{
  //   .content{
      
  //     .inner-wrapper{
  //       .main{
  //         font-size: 28px;
  //         line-height: 50px;
  //         // margin-top:72px;
  //         color: #082633;
  //       }
  //     }
  //   }
  //  }
  // }
