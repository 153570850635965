
          @import "@/assets/css/vars.scss";
        



























































































































































































































































































































































































































































@import '@/assets/css/statsOverlay.scss';
.myChart{
    //    border: 1.4px solid #929498;
    //    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    background: #FFFFFF;
    border-radius: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding:10px 20px;
    // padding-bottom: 59px;
    box-sizing: border-box !important;
    // width: calc(50% - 5px);
    // width: calc(70% - 5px);
    width: 100%;
    max-height: 540px !important;
   
    //display: flex;
    position: relative;
    .p{
        display: flex;
        align-items: center;
        margin:26px 0;
        margin-bottom: 4px;
       // margin-bottom: 10px;
        font-size: 13px;
        color: #132727;
        letter-spacing: 0.01em;
       span{
           margin-right: 4px;
       }
    }
    .p1{
       margin:33px 0;
       margin-top:35px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height, or 156% */


        /* black */

        color: #062931;

        .p1lass{
            font-weight: 500;
            color: #062931 !important;
        }
    }
     .up{
        display:flex;
        width:100%;
        box-sizing: border-box;
        // padding:0px 20px;
        justify-content: space-between;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height, or 156% */
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #5A7E87;
        // padding-top: 10px;
        padding-top: 6px;
        div{
            display: flex;
            align-items: center;
        }
        p{
            margin: 0;
        }
     }
     .down{
            display:flex;
            width:100%;
            box-sizing: border-box;
            height: 270px ;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: $text-lighter;
            margin: 0px 20px;
            .labels{
                box-sizing:border-box;
                width:17%;
                padding-left:1px;
                height:100% ;
								margin-bottom: 5px;
                .label{
                    box-sizing:border-box;
                    display:flex;
                    justify-content: left;
                    align-items: center;
                    height: 10%;
                    font-size: 13px;
                    line-height: 23px;
                    display: flex;
                    align-items: center;
                    color: #9498A0;
                }

            }
            .graf{
                border-left: 1px solid $green-color;
                box-sizing:border-box;
                width:83%;
                height:100% !important;
                position:relative;
                padding-top:1px;
                .avg{
                    position: absolute;
                    width:60%;
                    // border-bottom: 1px solid $green-color;
                    border-bottom: 0.5px solid #062931;
                    height:0px;
                    left:0;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 21px;
                    line-height: 25px;
                    text-align: right;
                    color: #062931;
                }
                .km{
                    position: absolute;
                    width:90%;
                    border-bottom: 1px solid #FF9F59;
                    height: 0px;
                    left:0;
                    font-weight: bold;
                    font-size: 25px;
                    line-height: 30px;
                    text-align: right;
                    color: #062931;

                }
                .val{
                    // background:#ffffff71;
                    padding:0 3px;
                    position: absolute;
                    z-index: 10;
                    top:-20px;
                    right: 0px;
                }
                .bar{
                    box-sizing: border-box;
                    background: rgba(241, 135, 0, 0.2);
                    height: calc(10% - 3px);
                    margin-bottom: 3px;
                    /* OUTLINE gray */
                    border: 1px solid rgba(241, 135, 0, 0.2);
                    border: transparent;
                    width:0;
                    animation-name: example;
                    transition-duration: 2s;
                }
                .bar:hover{
                    background: rgba(241, 137, 0, 0.233);
                    border: 1px solid rgba(241, 137, 0, 0.233);
                    border: transparent;
                }
            }
     }

      .down.rotate{
            // transform: rotate(-90deg) translate(-100%, 10%);
            transform: rotate(-90deg) translate(-100%, 0%);
            //  transform-origin: top left;
            // transform-origin: 0 0;
            transform-origin: 0 0;
            // vertical-align: center;
            // display:flex;
            width: 400px;
            box-sizing: border-box;
            height: 766px !important;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: $text-lighter;
            // margin: auto;

            .labels{
                box-sizing:border-box;
                width:17%;
                padding-left:1px;
                height:100% !important;
                .label{
                    transform: rotate(40deg) translate(10%, -5%);
                   
                    box-sizing:border-box;
                    display:flex;
                    justify-content: left;
                    // align-items: center;
                    // align-items: flex-end;
                    height: 10%;
                    display: flex;
                    align-items: center;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 25px;
                    /* identical to box height, or 192% */

                    text-align: center;
                    letter-spacing: 0.01em;

                    /* 55%black */

                    color: #76898E;
                }

            }
            .graf{
                border-left: 1px solid $green-color;
                box-sizing:border-box;
                width:83%;
                height:100% !important;
                position:relative;
                padding-top:1px;
                margin-left: 4px;

                .avg{
                    position: absolute;
                    width:60%;
                    // border-bottom: 1px solid $green-color;
                     border-bottom: 0.5px solid $green-color;
                    height:0px;
                    left:0;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    text-align: right;
                    color: #062931;

                }
                .km{
                    position: absolute;
                    width:90%;
                    border-bottom: 1px solid #FF9F59;
                    height: 0px;
                    left:0;
                    font-weight: bold;
                    font-size: 26px;
                    line-height: 35px;
                    color: #062931;
                }
                .val{
                    // background:#ffffff71;
                    padding:0 3px;
                    position: absolute;
                    z-index: 10;
                    // top:-20px ;
                    right: -10px;
                    bottom: -95px;
                    
                    transform: rotate(90deg);
                    // height: fit-content;
                }
                .avg{
                    .val{
                        
                        // //  right: -10px;
                         bottom: -85px;
                        // // bottom: initial;
                        // // top: -85px !important;
                        right: -55px;
                        height: fit-content;

                    // on side of line
                        // right: -35px;
                        // top: 40px !important;

                    }
                }
                .bar{
                    box-sizing: border-box;
                    background: rgba(241, 135, 0, 0.2);
                    height: calc(10% - 3px);
                    margin-bottom: 3px;
                    /* OUTLINE gray */
                    border: 1px solid rgba(241, 135, 0, 0.2);
                    border: transparent;
                    width:0;
                    animation-name: example;
                    transition-duration: 2s;
                    position: relative;

                    .replaceTooltip{
                        position: absolute;
                        right:-30px;
                        top:0;
                        bottom: 0;
                        transform: rotate(90deg);

                    }
                }
                .bar:hover{
                    background: rgba(241, 137, 0, 0.233);
                    border: 1px solid rgba(241, 137, 0, 0.233);
                    border: transparent;
                }
            }
     }
     /*end rotate */
}

.none{
    display: none !important;
}
.myChart.hideContent{
    padding-bottom: 15px;
    .up{
        padding-top: 8px;
    }
}

@media only screen and (max-width: 1275px) {
    .myChart{
        .down.rotate{
            //  transform: rotate(-90deg) translate(-100%, 0%);
            // //  transform-origin: top left;
            // // transform-origin: 0 0;
            // transform-origin: 0 0;
            // // height: 560px !important;
            .labels{
                .label{
                    // transform: rotate(40deg) translate(35%, -20%);
                    // display: flex;
                    // align-items: flex-start;
                    // font-weight: normal;
                    }
            }
            .graf{
                width: calc(100% - 90px);
                }
            }
    }
}

@media only screen and (max-width: 1024px){
  .myChart {
    border-radius: 0px;
    width: 100%;
     .down.rotate{
        //   transform: rotate(-90deg) translate(-100%, 10%);
       .labels{
          width: 90px;
          }
       .graf{
           width: calc(100% - 90px);
        }
     }
  }
}
@media only screen and (max-width: 768px){
  .myChart {
          padding-bottom:30px;
          padding-left: 16px;
          padding-right: 16px;
          .up{
                align-items: initial;
              p{
                 padding: initial !important;
                 line-height: 28px;
              }
          }
          .p1{
              //padding: initial !important;
               padding-left:10px;
               //line-height: 26px;
              
          }
            .down{
                box-sizing:border-box;
                .labels{
                     padding-left:10px;
                     box-sizing:border-box;
                     width: 87px;
                     .label{
                        box-sizing:border-box;
                        display:flex;
                        // justify-content: right!important;
                        justify-content: left!important;
                        align-items: center;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 25px;
                        /* or 192% */


                        /* 55%black */

                        color: #76898E;

                     }
                 }
                .graf{
                    width: calc(100% - 87px);
                    border-left: 1px solid $green-color;

                    .avg{
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        text-align: right;
                        letter-spacing: 0.01em;
                        text-transform: uppercase;
                        /* black */

                        color: #062931;
                        border-bottom: 0.75px solid #062931;
                        // border: 0.5px solid #062931;
                            .val{
                                  span{
                                        display:none !important;
                                  }
                             }
                    }
                .km{

                     font-style: normal;
                     font-weight: bold;
                     font-size: 22px;
                     line-height: 24px;
                     color: #062931;
                    //  border-bottom: 1px solid $green-color;
                    border-bottom: 1px solid #FF9F59;
                }

             }
         }
    }
}
 @media only screen and (max-width: 550px){
    .myChart{
        
    padding-left: 16px;
    padding-right: 16px;
    
    .up{
        align-items: initial;
            padding-top: 5px;
        p{
            font-weight: 500;
            font-size: 15px;
            line-height: 25px;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            /* Gray turquoise */

            color: #5A7E87;
            width: 200px;
            margin-top: 6px;

        }
    }
        .up>div{
        height: fit-content;
        align-self: flex-start;
    }
    }

    .myChart.hideContent{
        padding-bottom: 15px;
        .up{
            padding-top: 5px;
        }
    }
}

