
          @import "@/assets/css/vars.scss";
        


















































.wrappAssetsImg {
    margin-right: 30px;
    margin-bottom: 4px;
    // background:red;
    .imgDiv{
        box-sizing:border-box;
        width: 297px;
        height: 222px;
        border: 1px solid #C3C6D0;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        img{
          margin:0;
          padding:0;
          border: 0;
          width: 297px;
          box-sizing: border-box;
        //   height: 222px;
        }
    }
    .info {
       // box-sizing: border-box;
        height: 119px; // 104 + 15px
        padding-top: 10px;
        // padding-top: 9px;
        .titleDiv{
            box-sizing: border-box;
            width: 297px;
            // font-family: 'Gotham Pro Medium';
            // color: #062931;
            // font-style: normal;
            // font-weight: 500;
            // font-size: 18px;
            // line-height: 24px;
            // font-weight: bold;
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;

            color: #062931;

            p{
                margin: 0 0 0 0 ;
            }
        }
        .txtDiv{
            padding-top: 8px;
            a{
                display: flex;
                align-items: center;
                text-decoration: none;
                text-transform: uppercase;
                // font-family: Gotham Pro;
                // font-style: normal;
                // font-weight: normal;
                // font-size: 13px;
                // line-height: 24px;
                // letter-spacing: 0.02em;
                // color: #062931;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                /* identical to box height, or 133% */

                letter-spacing: 0.04em;

                /* Gray turquoise */

                color: #5A7E87;

                cursor: pointer;
               
                img{
                    height:12px;
                    // margin-right:7px;
                    margin-left:5px;
                }

            }
        }
        .txtDiv:hover{
            a{
                color: #134753;
            }
            
        }
    }


}
@media only screen and (max-width: 1330px){
    .wrappAssetsImg {
        padding-right: 30px;
        margin-right: 0px;
        // width: calc(33% - 60px);
        width: 33%;
        box-sizing: border-box;
        .imgDiv{
            box-sizing:border-box;
            width: 100%;
            // height: 222px;
            height: auto;
            border: 1px solid #C3C6D0;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            padding-top:30px;
            padding-bottom:30px;
            img{
            margin:0;
            padding:0;
            border: 0;
            // width: 297px;
            width: 100%;
            box-sizing: border-box;
            //   height: 222px;
            }
        }
        .info {
            box-sizing: border-box;
            height: 104px;
            padding-top: 10px;
            .titleDiv{
                box-sizing: border-box;
                width: 100%;
                p{
                    margin: 0 0 0 0 ;
                }
            }
        }

    }

}
@media only screen and (max-width: 768px){ //768
.wrappAssetsImg {
    padding-right: 30px;
    margin-right: 0px;
    // width: calc(33% - 60px);
    width: 50%;
    .imgDiv{
        max-width: 342px;
        max-height: 257px;
        width:100%;
        height:initial;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        padding-top:20px;
        padding-bottom:20px;
        img{
          margin:0;
          padding:0;
          border: 0;
          width: 342px;
          box-sizing: border-box;
        //   height: 222px;
        }
    }
    .info {
        box-sizing: border-box;
        height: 104px;
        height: 124px;
        // padding-top: 10px;
        padding-top: 5px;
        .titleDiv{
            box-sizing: border-box;
            width: 100%;
            // font-family: 'Gotham Pro Medium';
            // font-style: normal;
            // font-weight: 500;
            // font-size: 18px;
            // color: #062931;
            font-weight: bold;
            font-size: 22px;
            line-height: 30px;
            /* identical to box height, or 136% */


            /* black */

            color: #062931;
            p{
                margin: 0 0 0 0 ;
            }
        }
        .txtDiv{
            a{
                display: flex;
                align-items: center;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 15px;
                line-height: 20px;
                // font-family: Gotham Pro;
                // font-style: normal;
                // font-weight: normal;
                // font-size: 13px;
                // line-height: 24px;
                // letter-spacing: 0.02em;
                // color: #062931;
                cursor: pointer;
               
                img{
                   height:12px;
                    margin-right:7px;
                }

            }
        }
    }


}

}
@media only screen and (max-width: 600px){
.wrappAssetsImg {
    margin-right: 0px !important;
    padding-right: 0px !important;
    width:100%;
    // background:red;
    .imgDiv{
        //max-width: 344px;
        max-width: 100%;
        width:100%;
        //height: 258px;
        max-height: initial;
        height: initial;
        overflow: hidden;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding-top:20px;
        padding-bottom:20px;
        img{
          width:100%;
          margin:0;
          padding:0;
          border: 0;
          box-sizing: border-box;
          height: initial;
        }
    }
    .info {
        box-sizing: border-box;
        height:initial;
        // padding-top: 10px;
        padding-top: 12px;
        padding-bottom: 32px;
        padding-bottom: 45px;
        .titleDiv{
            box-sizing: border-box;
            width: 100%;
            // font-family: 'Gotham Pro Medium';
            // font-style: normal;
            // font-weight: 500;
            // font-size: 17px;
            // line-height: 24px;
            // color: #062931;
            font-size: 21px;
            line-height: 25px;
            p{
                margin: 0 0 0 0 ;
            }
        }
        .txtDiv{
            padding-top: 15px;
            a{
                display: flex;
                align-items: center;
                text-decoration: none;
                text-transform: uppercase;
                // font-family: Gotham Pro;
                // font-style: normal;
                // font-weight: normal;
                // font-size: 13px;
                // line-height: 24px;
                // letter-spacing: 0.02em;
                // color: #062931;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                cursor: pointer;
               
                img{
                   height:12px;
                    margin-right:7px;
                }

            }
        }
    }


}

}
