
          @import "@/assets/css/vars.scss";
        





































































































































































































































































.wrapProfilwData {
    display:flex;
    .data {
        width: 406px;
        margin-right: 127px;
        .titleProfile {
            // font-family: 'Gotham Pro Medium';
            // font-style: normal;
            // font-weight: 500;
            // font-size: 32px;
            // line-height: 50px;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 40px;
            display: flex;
            align-items: center;
            color: #062931;


            display: flex;
            align-items: center;
            color: #082633;
        }
        .mailProfile {
            height: 24px;
            // left: 321px;
            // top: calc(50% - 24px/2 - 363.5px);

            // font-family: Gotham Pro;
            // font-style: normal;
            // font-weight: normal;
            // font-size: 16px;
            // line-height: 24px;

            // display: flex;
            // align-items: center;
            // color: #062931;

            font-weight: normal;
            font-size: 17px;
            line-height: 25px;
            color: #062931;


            // margin-top: 6px;
            margin-bottom: 35px;

            margin-top: 34px;

        }
        .label {
            // font-family: Gotham Pro;
            // font-style: normal;
            // font-weight: 500;
            // font-size: 16px;
            // line-height: 30px;
            // display: flex;
            // align-items: center;

            // color: #082633;

            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 25px;
            /* identical to box height, or 147% */

            display: flex;
            align-items: center;

            /* black */

            color: #062931;
            margin-bottom: 3px;


            
        }
        .round {
            position: relative;
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            // margin-bottom: 21px;
             margin-bottom: 20px;
             margin-bottom: 22px;
            .input {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                background: #FFFFFF;
                border: 1px solid #C3C6D0;
                border-radius: 5px;
                outline: none;
                padding-left: 10px;

                // text 
                // font-family: Gotham Pro;
                // font-style: normal;
                // font-weight: normal;
                // font-size: 16px;
                // line-height: 24px;
                // display: flex;
                // align-items: center;

                // color: #9498A0;

                font-weight: normal;
                font-size: 15px;
                line-height: 20px;
                /* identical to box height, or 133% */

                display: flex;
                align-items: center;
                letter-spacing: 0.01em;

                /* black */

                color: #062931;


            }
            input[type=password]{
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none; 
                letter-spacing: 5px; 
            }
            .input:focus{
                background: #FFFFFF;
                border: 1px solid #787883;
                box-sizing: border-box;
                border-radius: 5px;
            }
            .input:read-only{
                border: 1px solid #C3C6D0;
            }
            .inputErr{
                // border: 1px solid #FF6359;;
                border: 1px solid $err-color;
            }
            .editTxt, .editImg{
                position: absolute;
                right:0;
                //top:50%;
                bottom: calc(50% - 10px);
                padding-right: 10px;

                cursor: pointer;
                //text
                // font-family: Gotham Pro;
                // font-style: normal;
                // font-weight: 500;
                // font-size: 12px;
                // line-height: 20px;
                // display: flex;
                // align-items: center;
                // text-align: right;
                // letter-spacing: 0.02em;
                // // text-transform: uppercase;
                // color: #9CB2B5;

                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                /* identical to box height, or 167% */

                display: flex;
                align-items: center;
                text-align: right;
                letter-spacing: 0.02em;
                // text-transform: uppercase;

                /* Gray turquoise - */

                color: #9CB2B5;
            }
            .editTxt:hover{
                color: #5A7E87;
            }
            .editImg{
                bottom: calc( 50% - 8px ); // it's middle 15px is height of img
            }
        }
        .noMarginInput{
            // margin-bottom: 0;
        }

        .roundButton{
            margin-top:40px;
            margin-bottom: 42px;
            display: flex;
            // align-items: center;
            flex-direction: column;
            .button {
                box-sizing: border-box;
                // width:116px;
                // height: 50px;

                width:140px;
                height: 50px;

                border-radius: 52px;
                background-color: $main-color;
                text-align: center;
                // padding: 13px 0;
                // padding-left: 40px;
                cursor: pointer;

                // font-family: Gotham Pro;
                // font-style: normal;
                // font-weight: 500;
                // font-size: 15px;
                // line-height: 24px;

                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0.01em;

                /* White */

                color: #FFFFFF;

                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                color: #FFFFFF;
                margin-right: 20px;
            }
            .button:hover{
                background-color: $btn-hover;
            }
            .disabled {
                background-color: #EEEFF3;
                cursor: initial;

            }
            .disabled:hover{
                background-color: #EEEFF3;
                cursor: initial;
            }
            .noChanges{
                margin-top: 20px;
                // font-weight: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                /* identical to box height, or 133% */

                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: 0.01em;

                /* black */

                color: #062931;

                // font-family: Gotham Pro;
                // font-style: normal;
                // font-weight: normal;
                // font-size: 14px;
                // line-height: 24px;
                // // display: flex;
                // // align-items: center;
                // // text-align: center;

                // color: #4C4C52;
            }
        }
    }
    .containerImg {
        padding-top: 137px;  // 93 + 40
       
    }



 .forgotpass {
            // font-family: Gotham Pro;
            // font-style: normal;
            // font-weight: normal;
            font-size: 13px;
            // line-height: 22px;

            color: #5A7E87;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 0.01em;
            color: #5A7E87;
            margin-top: 8px;


            span {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            .txt {
                color: #5A7E87;
                text-decoration: none;
                // padding-top:3px;
                padding-left: 4px;

            }


        }
        .forgotpass:hover{
            .txt{
                color: #134753;
            }
        }
}
.bold{
    padding-left: 3px;
    color: $main-color;
    cursor: pointer;
    font-weight: 500 !important;
}
.bold:hover {
    color: #FF4B49 !important;
}
.msg{
    // font-family: Gotham Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;

    // color: $main-color;
    color: $err-color;
    margin-top:20px;

}


// .wrapProfilwData {
//     .data {
//         .titleProfile {}
//         .mailProfile {}
//         .label {}
//         .round {
//             .input {}
//             .editTxt{}
//         }
//         .roundButton{
//             .button {   
//             }
//             .disabled {
//             }
//             .noChanges{  
//             }
//         }
//     }
//     .containerImg {
    
//         .wrappImg {
              
//         }
//     }
// }

@media only screen and (max-width: 768px){ // 767
     .wrapProfilwData {
      
      .data {
        width: 404px;
        margin-right: 79px;
        margin-right: 62px;
        .titleProfile {
            // font-size: 30px;
            // line-height: 40px;
            font-size: 36px;
            line-height: 40px;
        }
        .mailProfile {
            font-size: 17px;
            line-height: 25px;

        }
        .label {}
        .round {
           margin-bottom: 22px; 
            .input {}
            .editTxt{}
        }
    }

}
}
@media only screen and (max-width: 750px){
     .wrapProfilwData {
        flex-direction: column-reverse;
        margin-bottom: 73px;
        width: 100%;
        

        .data {
            width: 343px;
            margin-right: 79px;
            padding-bottom: 81px;
            .label {}
            .round {
            margin-bottom: 21px; 
                .input {}
                .editTxt{}
            }
            .roundButton{
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                margin-bottom: 0;
                margin-top: 30px;
                .button {   
                    // margin-bottom: 21px;
                    width:120px;
                    height: 48px;
                    border-radius: 52px;
                    display: flex;
                    justify-content: center;
                    padding-left: 0;

                }
                .disabled {
                    // margin-bottom: 21px;
                }
                .noChanges{  
                    margin-top: 15px;
                    margin-bottom: 0px;

                }
            }

            
        }

        .containerImg {
            padding-top: 0;
            // margin-bottom: 43px;
            margin-bottom: 37px ;
            // padding-bottom: 37px;
            min-height: 370px !important;
            .titleProfile {
                // font-family: 'Gotham Pro Medium';
                // font-style: normal;
                // font-weight: 500;
                // font-size: 28px;
                // line-height: 50px;
                // /* identical to box height, or 179% */


                // color: #000000;

                font-weight: bold;
                font-size: 34px;
                line-height: 40px;
                /* identical to box height, or 118% */


                /* black */

                color: #062931;


            }
            .mailProfile {
                // font-family: Gotham Pro;
                // font-style: normal;
                // font-weight: normal;
                // font-size: 15px;
                // line-height: 24px;
                // display: flex;
                // align-items: center;

                // margin-bottom:32px;
                font-weight: normal;
                font-size: 16px;
                line-height: 25px;
                /* identical to box height, or 156% */

                display: flex;
                align-items: center;

                /* black */

                color: #062931;


                margin-top:34px;
                margin-bottom: 43px;

            }
            .wrappImg {
                
            }
        }

    }

    .msg{
        // font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        // display: flex;
        // align-items: center;

        /* Orange primary */

        // color: #FF6359;
        color: $err-color;

        margin-bottom: 23px;


    }

}
@media only screen and (max-width: 500px){
     .wrapProfilwData {
        //  padding-bottom:90px;
        .noChanges{
            margin-bottom: 90px;
        }
     }
}

