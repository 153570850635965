
          @import "@/assets/css/vars.scss";
        













.loader-wrapper {
  position: absolute;
  display: flex;
  // align-items: center;
  padding-top: calc(20% + 70px);
  justify-content: center;
  top: -70px;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(248, 248, 249, 0.8);
  z-index: 5012;

  img {
    height: 60px;
    width: 60px;
  }
}
