
          @import "@/assets/css/vars.scss";
        





























































































.wrappNewsletter{
    // background: #F7EBE2;
    // background: #CDD8D8;
    background-color: #C8DEDC;
    height: 420px;
    width: 100%;
    display: flex;
    justify-content: center;
    // align-items: center;
     .holdContent {
         // background-color: gold;
         margin:0 auto;
         display:flex;
         justify-content: space-between;
         // align-items: center;
         width:1278px;
        // box-sizing: border-box;
         height: calc(100% - 84px);
         padding-top:84px;
         .content{
             padding-left:147px;
             display: flex;
            .imgNewsletter {
                display:initial;
                padding-right: 50px;
                padding-top:19px;
            }

            .formNewsletter {
                display:flex;
                flex-direction: column;
               //  justify-content: center;
                align-items: center;
                .titleNewsletter {
                   // font-family: "Gotham Pro Medium";
                    font-style: normal;
                    font-weight: bold;
                    font-size: 51px;
                    line-height: 55px;
                    /* identical to box height, or 108% */

                    display: flex;
                    align-items: center;

                    /* black */

                    color: #062931;

                    br{
                        display:none;
                    }
                }

                .roundInput{
                    box-sizing:border-box;
                    width:100%;
                    margin-top: 41px;
                    margin-bottom: 70px;
                    margin-bottom: 71px;
                    position: relative;
                @import '@/assets/css/inputButton3.scss';
                .msg{
                    width: 100%;
                    position: absolute;
                    text-align: center;
                    left:auto;
                    bottom: -50px;
                    color:  $main-color;
                    color: $err-color;
                }
                }

                .textNewsletter {
                    // font-family: Gotham Pro;
                    // font-style: normal;
                    // font-weight: normal;
                    // font-size: 14px;
                    // line-height: 20px;
                    // /* or 143% */

                    display: flex;
                    align-items: center;
                    text-align: center;

                    // color: rgba(90, 126, 135, 0.7);
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    text-align: center;
                    color: #5A7E87;
                    letter-spacing: .01em;
                    opacity: 0.7;
// --(Link moze da promeni opacity na 90% na hover)
                    letter-spacing: 0.01em;
                    p {
                        margin: 0;
                    }
                    // opacity: 0.7;
                    .big{
                        display: initial;
                    }
                    .small{
                        display: none;
                    }
                     .msg {
                        // font-family: Gotham Pro;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        /* identical to box height, or 150% */

                        display: flex;
                        align-items: center;
                        text-align: center;

                        /* Gray turquoise */

                        color: #5A7E87;

                    }
                    .err {
                        // color: #FF5654 !important;
                        color: $err-color;

                    }
                }
            }
         }
     }
     .bold{
       // font-family: "Gotham Pro Medium";
        font-style: normal;
        font-weight: 500;
        // opacity: 0.7 !important;
     }
     .link{
          // color: rgba(90, 126, 135, 0.7);
           color: #5A7E87;
     }
     .link:hover{
        //  color: #5A7E87;
         opacity: 0.9 !important;
     }
}

@media only screen and (max-width: 1330px){
    .wrappNewsletter{
        .holdContent{
            box-sizing: border-box;
            margin:0 auto;
            //width:1278px;
            width: calc(100% - 58px );
            margin: 0 29px;
            height: 100%;
            .content{
               min-width:80%;
              justify-content: space-around;
              margin: 0 auto;
              padding-left:0px;
              // min-width:80%;

                .imgNewsletter {
                    display:initial;
                    padding-right: 0px;
                }

                .formNewsletter {
                    
                    .roundInput{
                        box-sizing:border-box;
                    
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 800px){ // 768px
    .wrappNewsletter{
        padding-top: 94px;
        // padding-bottom: 80px;
        box-sizing: border-box;
        height: 450px;
        .holdContent{
            box-sizing: border-box;
            margin:0 auto;
            display:flex;
            width: 100%;
            padding:0 27px 0 27px;
            height: fit-content;
            
           .content{
               display: flex;
               justify-content: center;
               align-items: center;
                padding-left:0px;
                width:100%;
                height: fit-content;
                .imgNewsletter {
                    display:none;
                }

                .formNewsletter {
                    .titleNewsletter {
                        text-align: center;
                        font-size: 46px;
                        line-height: 55px;
                        br{
                            display:none;
                        }
                    }
                    
                    .roundInput{
                        box-sizing:border-box;
                        margin-top: 46px;
                        margin-bottom: 75px;
                    
                    }
                    .textNewsletter {
                        font-size: 14px;
                        line-height: 20px;
                        .big{
                          display: initial;
                        }
                        .small{
                            display: none;
                        }
                    }
                }

            }
        }
    }
}

@media only screen and (max-width: 620px){ //375
 .wrappNewsletter{
        // height: 350px;
        height: fit-content;
        min-height: 335px;
        padding-top: 41px;
        // padding-bottom: 55px;
        .holdContent{
            // padding-top: 47px;
            // padding-bottom: 39px;
            margin:0 auto;
            height: initial;
            padding:0 16px 0 16px;
           .content {
                .titleNewsletter {
                    font-size: 36px;
                    line-height: 36px;
                    
                    br{
                        display:initial;
                    }
                }
                
                .formNewsletter {
                    .roundInput{
                        box-sizing:border-box;
                        margin-top: 31px;
                        margin-bottom: 42px;
                    }
                    .textNewsletter {
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 17px;
                        p { 
                           margin: 0; 
                        }
                        .big {
                            display: none;
                        }
                        .small{
                            display: initial;
                        }
                    }
                }
           }
        }
    }
}

@media only screen and (max-width: 492px){ //375
 .wrappNewsletter{
        // height: 350px;
        height: fit-content;
        // min-height: 350px;
        min-height: 375px;
        padding-top: 41px;
        // padding-bottom: 55px;
        .holdContent{
            // padding-top: 47px;
            // padding-bottom: 39px;
            margin:0 auto;
            height: initial;
            padding:0 16px 0 16px;
           .content {
                .titleNewsletter {
                    font-size: 36px;
                    // line-height: 36px;
                    // font-size: 36px;
                     line-height: 40px;
                    
                    br{
                        display:initial;
                    }
                }
                
                .formNewsletter {
                    .roundInput{
                        box-sizing:border-box;
                        margin-top: 31px;
                        margin-bottom: 42px;
                    }
                    .textNewsletter {
                        // font-weight: normal;
                        // font-size: 13px;
                        // line-height: 17px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 17px;
                        p { 
                           margin: 0; 
                        }
                        .big {
                            display: none;
                        }
                        .small{
                            display: initial;
                        }
                    }
                }
           }
        }
    }
}


