
          @import "@/assets/css/vars.scss";
        














































































































.delete-news-wrapper {
   padding-bottom: 100px;
  .showeMore {
    margin: 35px auto;
    // font-family: Gotham Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    width:fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4C4C52;
    border: 1px solid #C3C6D0;
    box-sizing: border-box;
    border-radius: 52px;
    padding: 14px 30px;
    cursor:pointer;

  }
}

