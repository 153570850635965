
          @import "@/assets/css/vars.scss";
        





















































































































































.modalShare {
    position: fixed;
    top:0 !important;
    left:0 !important;
    width:100vw;
    height:100vh;
    background: #000000e1;
    // z-index:1000 !important;
    z-index: 9999 !important;
    display:flex;
    justify-content: center;
    // align-items: center;
    .exit {
       // position: absolute;
        top:0px;
        right:0px;
        margin-top:60px;
        margin-right: 59px;
        position: absolute;
        cursor: pointer;
        img {
            width:22px;
        }
    }
    .contentShare {
        // background: rgba(231, 236, 237, 0.555);
        // padding-top: 190px; 
        // margin-top: 100px;
        // padding-top: 90px;
        margin-top: 180px;
        width: 624px;
        min-height: 469px;
        // overflow: auto;
        .txt {
            text-align: center;
            // padding-bottom: 25px;
            // position: relative;
            // height: 230px;
            // padding-bottom: 60px;
            // height: initial;
            // height: 230px;  the comment says it should go 10 px lower the element below
            height: 240px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
           
            .title {
                text-align: center;
                // font-family: Gotham Pro;
                // font-style: normal;
                // font-weight: normal;
                // font-size: 80px;
                // line-height: 80px;
                // color: #FFFFFF;
                font-weight: 500;
                font-size: 103px;
                line-height: 100px;
                /* identical to box height, or 97% */

                // display: flex;
                // align-items: center;

                /* White */

                color: #FFFFFF;

                margin-top:0px;
                // margin-bottom: 35px;
                margin-bottom: 27px;
            }
            .description {
                // font-family: 'Gotham Pro Medium';
                font-style: normal;
                // font-weight: 500;
                // font-size: 16px;
                // line-height: 24px;
                // text-align: center;
                // color: #FFFFFF;

                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 25px;
                /* or 147% */

                text-align: center;

                /* text 90 */

                // color: #E7ECED;
                color: #FFFFFF;

                margin-top:0;
                margin-bottom:10px;
                margin-right:30px;
                margin-left:30px;
            }
            .link {
                // font-family: 'Gotham Pro Light';
                font-style: normal;
                font-weight: 400;
                // font-size: 16px;
                // line-height: 24px;

                font-size: 17px;
                line-height: 25px;

                text-align: center;
                // color: #C4C4C4;
                color: #E7ECED;
                margin-top:0;
                text-decoration: none;
                margin-right:30px;
                margin-left:30px;
                a {
                    // color: #C4C4C4;
                    // color: #E7ECED; 
                    color: #6a7f83;
                    // color: #E7ECED;
                    text-decoration: none;
                    cursor: pointer;
                }
                a:hover{
                    color: #5c96a7 !important;
                }
                 a:active {
                    color: #C4C4C4;
                    text-decoration: none;
                    cursor: grabbing;
                }
            }
            .first {
                a {
                    color: #4d7b87 !important;
                }
            }
            .errormsg {
                width: 513px;
                max-width: 100%;
                height: 56px;
                // background: #000000;
                // /* text 80 */

                // border: 1px solid #CFD8DB;

                background: #000000;
                border: 1px solid #CFD8DB;

                box-sizing: border-box;
                border-radius: 2px;

                display:flex;
                justify-content: center;
                align-items: center;
                
                // font-family: Gotham Pro;
                // font-style: normal;
                // font-weight: normal;
                // font-size: 16px;
                // line-height: 24px;
                font-style: normal;
                font-weight: normal;
                font-size: 17px;
                line-height: 25px;
                /* identical to box height, or 147% */

                text-align: center;

                /* text 90 */

                color: #E7ECED;


                text-align: center;
                color: #FFFFFF;
                margin:0 auto;
                // margin-bottom: 30px;
                img{
                     margin-right:10px;
                }
             
            }

        }

        .shareIcon{
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            width:80%;
            justify-content: center;
            align-items: center;
            .spanImg{
                margin:3px 15px;
                margin-bottom: 27px;
                width:79px;
                width:80px;
                text-decoration: none!important;
                text-align: center;
                // margin-bottom: 51px;
                //  margin-bottom: 33px; // only the space between twitter and icon below is different 32px
                // margin-bottom: 32px; ask for 2px up
                //  transition-duration: .3s;
                .aLink{
                        // height: 80px !important;
                        display: inline-block;
                        min-height: 100%;
                        text-decoration: none;
                }
                .img {
                    background: #C4C4C4;
                    width: 79px;
                    height: 79px;
                    border-radius: 50%;
                    transition-duration: .3s;

                }
               
                p{
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 15px;
                    text-align: center;
                    letter-spacing: 0.01em;
                    text-align: center;
                    color: #E7ECED;
                    text-decoration: none!important;
                    // margin:5px 0;
                    margin: 0;
                    // margin-top: 13px; ask for 5px up
                     margin-top: 8px;
                }
            }
            .spanImg:hover{
                position:relative;

                // width: 90px;
                // margin:0 10px;
                // margin-bottom: 20px;

                width: 86px;
                margin:0 12px;
                margin-bottom: 27px; // 27 init
                 img{
                    // position: absolute;
                    width: 86px!important;
                    height: 86px; 
                }
                p{
                    // margin-top: -2px;
                    margin-top:5px;  // 8 init
                    color:#5C96A7;
                }
            }
        }
    }
    .sBr {
        display: none;
    }
}
// @media only screen and (max-width: 768px){

//     .modalShare {
//         .contentShare {
//                 // background: rgba(231, 236, 237, 0.555);
//                 // padding-top: 95px;
//                 // padding-top: 25px;
//                 // margin-top: 70px;
//                 box-sizing: border-box;
//                 width: 100%;
//                 height: initial;
//                 overflow: auto;
                
//             .txt {
//                // padding-bottom: 32px;
//                height: 210px;
//                  .title {
//                         // font-size: 60px;
//                         // line-height: 80px;
//                         font-weight: 500;
//                         font-size: 103px;
//                         line-height: 100px;
//                         color: #FFFFFF;
//                         margin-top:0px;
//                         margin-bottom: 10px;
//                     }
            
//                 .errormsg {
//                     width: initial;
//                     height:initial;
//                     padding: 28px 33px;
//                     font-size: 15px;
//                     line-height: 24px;
//                     /* or 160% */

//                     text-align: center;

//                     /* White */

//                     color: #FFFFFF;

//                     display:flex;
//                     justify-content: center;
//                     align-items: initial !important;
                    
//                     font-family: Gotham Pro;
//                     font-style: normal;
//                     font-weight: normal;
//                     font-size: 14px;
//                     line-height: 20px;
//                     text-align: center;
//                     color: #FFFFFF;
//                     margin:0 auto;
//                    // margin-bottom: 30px;
//                     img{
//                         height: fit-content;
//                         margin-top: 6px;
//                         margin-right:10px;
//                         margin-left: 10px;
//                     }
                
//                 }

//             }

//         }
//         .sBr {
//             display: initial;
//         }
//     }

//  }
@media only screen and (max-width: 600px){

    .modalShare {
        .contentShare {
                // background: rgba(231, 236, 237, 0.555);
                // padding-top: 95px;
                padding-top: 25px;
                margin-top: 70px;
                box-sizing: border-box;
                width: 100%;
                height: initial;
                overflow: auto;
                
            .txt {
               // padding-bottom: 32px;
               //    height: 210px;
               height: 250px;
                 .title {
                        // font-size: 60px;
                        // line-height: 80px;
                        color: #FFFFFF;
                        margin-top:0px;
                        margin-bottom: 10px;
                    }
            
                .errormsg {
                    width: initial;
                    height:initial;
                    padding: 28px 33px;
                    font-size: 15px;
                    line-height: 24px;
                    /* or 160% */

                    text-align: center;

                    /* White */

                    color: #FFFFFF;

                    display:flex;
                    justify-content: center;
                    align-items: initial !important;
                    
                    // font-family: Gotham Pro;
                    // font-style: normal;
                    // font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #FFFFFF;
                    margin:0 auto;
                   // margin-bottom: 30px;
                    img{
                        height: fit-content;
                        margin-top: 6px;
                        margin-right:10px;
                        margin-left: 10px;
                    }
                
                }

            }
            

        }
        .sBr {
            display: initial;
        }
    }

 }
@media only screen and (max-width: 438px){ //450
        .modalShare {
            // overflow: auto;
            .exit {
                position: absolute;
                top:0px;
                right:0px;
                margin-top:40px;
                margin-right: 39px;
                img {
                    width: 15px;
                }
            }
            .contentShare {
                // background: rgba(231, 236, 237, 0.555);
                // padding-top: 95px;
                // padding-top: 25px;
                // margin-top: 70px;
                padding-top: 0px;
                margin-top: 77px;
                box-sizing: border-box;
                width: 100%;
                height: 80vh;
                overflow: auto;
                .txt {
                    // height: 210px;
                    height: fit-content;
                    text-align: center;
                    padding-bottom: 35px;
                 //  padding-bottom: 0px;
                   // height: initial;
                   // min-height: 230px;
                    .title {
                        // font-size: 60px;
                        // line-height: 80px;
                        font-size: 80px;
                        line-height: 80px;
                        color: #FFFFFF;
                        margin-top:0px;
                        margin-bottom: 20px ;
                       // margin-bottom: initial;
                    }
                    .description {
                        // font-size: 15px;
                        // line-height: 24px;
                        font-size: 16px;
                        line-height: 25px;
                        // padding-top:10px;
                        margin-top:0;
                        margin-bottom:10px;
                        margin-right:30px;
                        margin-left:30px;
                    }
                    .link {
                        // font-size: 15px;
                        font-size: 16px;
                        line-height: 25px;
                        margin-top:0;
                        text-decoration: none;
                        margin-right:30px;
                        margin-left:30px;
                        margin-bottom: 0px;
                        a {
                            color: #C4C4C4;
                            text-decoration: none;
                        }
                    }
          

                }

                .shareIcon{
                    margin: 0 auto;
                    display: flex;
                    flex-wrap: wrap;
                    width:80%;
                    width:65%;
                    height: fit-content;
                    justify-content: center;
                    align-items: center;
                    .spanImg,.spanImg:hover{
                        width: fit-content;
                        text-decoration: none!important;
                        text-align: center;
                        // margin-bottom: 22px !important;
                        margin-top:0px;
                        margin-bottom: 27px;
                        margin-left: 0;
                        margin-right: 15px;
                        .aLink{
                            // width: 60px;
                            // height: 60px;
                            text-decoration: none;
                        }
                        img{
                             width: 60px !important;
                             height: 60px ;
                        }
                        .img {
                            background: #C4C4C4;
                            // width: 79px;
                            // height: 79px;
                            width: 60px !important;
                            border-radius: 50%;

                        }
                        p{
                            // font-family: 'Gotham Pro Light';
                            // font-style: normal;
                            // font-weight: 300;
                            // font-size: 13px;
                            // line-height: 24px;
                            font-size: 13px;
                            line-height: 15px;
                            /* identical to box height, or 115% */

                            text-align: center;
                            letter-spacing: 0.01em;
                            text-align: center;
                            color: #FFFFFF;
                            margin-top: 8px;
                        }
                    }
                   .spanImg:hover{
                        p{
                            color:#5C96A7;
                        }
                    }
                    .spanImg:nth-of-type(3n){
                        margin-right: 0;
                    }
                }
            }
    }
}
@media only screen and (max-width: 341px){ 
    .modalShare {
        .contentShare {
             .txt {
                    height: initial;
              }

            .shareIcon{
                
                .spanImg:nth-of-type(3n){
                    margin-right: 15px;
                }
            }
        }
    }
}

