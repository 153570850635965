
          @import "@/assets/css/vars.scss";
        






















































































.question{
    width: 184px;
    height: 235px;
    background: #103D49;
    border: 1px solid rgba(90, 126, 135, 0.8);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 15px 10px;
  .upQuestion{

    // font-family: Gotham Pro;
    width:148px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */

    letter-spacing: 0.01em;

    /* text 80 */

    color: #CFD8DB;    
      p{
        margin: 0;
      }


  }
  .downQuestion{
    padding-top: 16px;
  }

}



@media only screen and (max-width: 1024px){
  .question{
        width: 154px;
        height: 235px;
//     display: flex;
//     align-items: center;
//     flex-wrap: wrap;

    .upQuestion{
         width: 114px;

    }
//     .downQuestion{
//       padding-top: 0px;
//       display: flex;
//     }
  }
}
@media only screen and (max-width: 550px){
  .question{
    display: block;
    width: 100%;
    min-height: 85px;
    height:fit-content;
    padding: 10px;
    padding-bottom: 0px;
    margin-top: 62px;

    .upQuestion{
      width: initial;
      font-size: 13px;
      line-height: 17px;
      /* identical to box height */

      letter-spacing: 0.01em;

    }
    .downQuestion{
      
      padding-top: 10px;
      display: flex;
      height: fit-content;
    }
  }
}
