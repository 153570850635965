
          @import "@/assets/css/vars.scss";
        















































































































































































































































































































@import "@/assets/css/statsOverlay.scss";
// .similar-wrapper .section-wrapper {
//   border-top: 1px solid #C3C6D0;
//   border-radius: 0;
// }

.section-wrapper {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  padding-top: 22px;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
  min-height: 420px;
  display: flex;
  flex-direction: column;
  position: relative;

  .section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-bottom: 60px;
    .titleLeft{
      display: flex;
      flex-direction: column;
      margin-top: 3px;
      h3 {
        // font-size: 18px;
        // line-height: 28px;
        // letter-spacing: 0.01em;
        // color: $text-darker;
        margin: 0px;
  
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height, or 143% */
  
        letter-spacing: 0.05em;
        text-transform: uppercase;
  
        /* Gray turquoise */
  
        color: #5a7e87;
  
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50px;
      }
      span {
        // margin-top: -5px;
        margin-bottom: 4px;
        font-weight: 400;
        text-transform: none;
      }
      .hakic{
        margin-left: 0;
        margin-top: 53px;
      }

    }
    .titleRight {
      align-self: flex-start;
      display: flex;
      min-width: fit-content;
      margin-top: 5px;

      .better-pos {
        margin-top: -2px;
      }
    }

    img {
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -5px;
    margin-bottom: 40px;

    .view-all-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 52px;
      margin-bottom: 40px;
      .view-all {
        // font-size: 15px;
        // line-height: 24px;
        // color: #4C4C52;
        color: #082633;
        width: 168px;
        height: 52px;
        // border: 1px solid #4C4C52;
        box-sizing: border-box;
        border-radius: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        // font-family: 'Gotham Pro Medium';
        font-weight: 500;
        cursor: pointer;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        /* identical to box height, or 133% */

        letter-spacing: 0.01em;
      }
    }

    .item {
      width: calc(100% - 4px);
      padding: 15px 5px;
      padding-right: 16px;
      // line-height: 29px;
      box-sizing: border-box;
      // font-size: 14px;
      position: relative;
      // border-top: 1px solid #DEE0E5;
      // padding-top: 11px;
      // padding-bottom: 9px;

      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;

      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      /* identical to box height, or 133% */

      letter-spacing: 0.01em;

      /* black */

      color: #062931;
      .mobile-wrapper {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
      }
      .dark {
        // font-family: 'Gotham Pro Medium';
        font-weight: 400;
      }
      .wider {
        margin-left: 3px;
        margin-right: 3px;
      }
      .circle-empty {
        width: 5px;
        height: 5px;
        box-sizing: border-box;
        border: 1px solid #062931;
        border-radius: 2.5px;
        margin-top: -2px;
      }
      .circle {
        width: 5px;
        height: 5px;
        box-sizing: border-box;
        background: #062931;
        border-radius: 2.5px;
        margin-top: -2px;
      }
      .last-two {
        font-weight: 400;
      }
      .indicator {
        position: absolute;
        height: 100%;
        width: 5px;
        left: 0;
        top: 0;
      }
      .indicator.greatPrice {
        background-color: #51e6fb;
      }
      .indicator.pricy {
        background-color: #4982ff;
      }
      span {
        margin-left: 5px;
        margin-right: 5px;
      }

      .price-tooltip {
        font-weight: 700;
        font-size: 14px;
        line-height: 12px;
        color: #062931;
        letter-spacing: 0.04em;
        font-style: normal;

        height: 20px;
        width: 101px;

        margin-left: auto;
      }
    }

    .item:nth-child(odd) {
      background-color: #fcf9f2;
    }
  }

  .spanArrow {
    padding: 0 5px;
    cursor: pointer;
    .rotate {
      transform: rotate(180deg);
    }
  }
}
@media only screen and (max-width: 1024px) {
  .section-wrapper {
    border-radius: 0px;
    width: 100%;
  }
}
@media screen and (max-width: 768px){
  .section-wrapper{
    .section-header{
      margin-bottom: 29px;
    }
  }
}

@media only screen and (max-width: 650px) {
  .section-wrapper {
    padding-bottom: 60px !important;
    .section-header {
      // margin-bottom: 20px;
      margin-bottom: 33px;

      h3 {
        // font-size: 18px;
        // line-height: 28px;
        // letter-spacing: 0.01em;
        font-family: "Poppins";
        font-size: 15px;
        line-height: 25px;
        /* or 167% */

        letter-spacing: 0.05em;
        // color: $text-darker;
        margin: 0px;
        margin-right: 20px;
        span {
          text-transform: capitalize;
        }
      }
      img {
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .section-wrapper {
    // padding: 20px 16px;
    padding: 22px 16px;
    .section-header {
      .titleLeft {
        h3 {
          // width: 90%;
          display: unset;
          span {
            width: 240px;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        } 
      }
    }
    .content {
      .item {
        align-items: unset;
        .mobile-wrapper {
          max-width: 50%;
          width: 50%;
          .circle {
            display: none;
          }
        }
        .dark:first-of-type {
          font-weight: 600;
        }
        .dark:nth-of-type(2) {
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (max-width: 375px){
  .section-wrapper{
    .section-header{
      margin-bottom: 19px;
      .titleLeft{
        .hakic{
          margin-top: 33px;
        }
      }
    }
  }
}
.hide-section {
  min-height: min-content !important;
  padding-bottom: 0 !important;
}
