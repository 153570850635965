
          @import "@/assets/css/vars.scss";
        





































































































































































































































































































































































































@import '@/assets/css/statsOverlay.scss';
.section-wrapper {
 
  background-color: white;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 22px 20px;
  // padding-bottom: 52px; // 74px -52 =22      52-22 =30
  padding-bottom: 19px;
  padding-top: 16px;
  box-sizing: border-box;
  // width: calc(50% - 5px);
  width:100%;
  min-height: 489px;
  // min-height: 510px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;

  .section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 46px;
    div{
      display: flex;
      align-items: center;
    }

    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #5A7E87;

      margin: 0px;
    }
    img {
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    // width: 65%;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
    // margin-top: 54px;

    .exclamation-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;

      img {
        margin-right: 4px;
        margin-bottom: 3px;
      }
    }
    span {
      font-size: 13px;
      letter-spacing: 0.01em;
      color: #132727;
    }

    #advertised-price-chart, #advertised-price-chart2 {
      min-height: 300px !important;
      max-height: 300px !important;
      height: 300px !important;
    }

    .chart-wrapper {
      position: relative;

      .indicator {
        position: absolute;
        // top: 0;
        // top: -8px;
        top: -41px;
        // height: calc(100% - 41px);
        // height: calc(100% - 39px);
        height: 100%;
        width: 1px;
        white-space: nowrap;
        // background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 85%,rgba(0,0,0,0) 100%);
        border-left: 1px solid #FF9F59;
        span {
          // color: #062931;
          // font-weight: bold;
          // font-size: 22px;
          // line-height: 24px;
          margin-left: 5px;
          // background: white;
          // padding: 4px 2px 0 2px;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          // line-height: 35px;
          padding: 0px 2px 0 5px;

          color: #062931;

        }
        span.left {
          margin-left: -105%;
        }
      }
      .indicator.horizontal {
        border-left: none;
        height: 1px;
        width: calc(100% - 87px);
        left: 87px;
        text-align: right;
        // background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 85%,rgba(0,0,0,0) 100%);
        border-top: 1px solid #FF9F59;
        span {
          // font-weight: bold;
          // font-size: 22px;
          // line-height: 24px;

          // color: #062931;

          // height: auto;
          // margin-top: 5px;
          // background: white;
          // padding: 4px 2px 0 2px;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;

          text-align: right;

          color: #062931;
        }
      }
    }
  }
}
.section-wrapper.hideContent{
      min-height:fit-content !important;
      padding-bottom: 6px;
      padding-top: 17px;
      .section-header {
        margin-bottom: 10px;
      }
}
@media only screen and (max-width: 1024px){
  .section-wrapper {
    border-radius: 0px;
    width: 100%;
    .content{
      width: 80%;
      // margin: 0 0;
      // padding-left: 47px;
    }
  }
}
@media only screen and (max-width: 768px){
      .section-wrapper{
        min-height: fit-content;
        padding: 20px 16px;
        .content{
          width: 100%;
          padding-left: 0px
        }
      }
 }
@media only screen and (max-width: 550px){
      .section-wrapper{
        // height: 380px;
        padding: 20px 16px;
        padding-bottom: 60px;
        padding-top:15px !important;

        .section-header {
          display: flex;
          flex-direction: row;
          align-items: initial;
          // justify-content: initial;
          margin-bottom: 48px;

          h3 {
            // margin-top: 2px!important;
            margin-top: 5px!important;
            font-weight: 500;
            font-size: 15px;
            line-height: 25px;
            /* or 167% */

            letter-spacing: 0.05em;
            text-transform: uppercase;

            /* Gray turquoise */

            color: #5A7E87;
            width: 200px;
          }
        }
        .section-header>div{
            height: fit-content;
            align-self: flex-start;

            margin-top: 0px;
        }
        .content{
          width: 100%;
        }
      }
 }
