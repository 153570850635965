
          @import "@/assets/css/vars.scss";
        


















































































































































































.payment-wrapper {
  width: 100%;
  height: 100%;
  .title {
    width: 100%;
    height: 185px;
    background: linear-gradient(116.78deg, #104756 13.98%, #3D7484 75.44%);
    display: flex;
    justify-content: center;
    position: relative;
    h1 {
      margin: 0;
      position: absolute;
      bottom: 47px;

      font-size: 36px;
      color: #fff;
    }
  }

  .content-wrapper {
    width: 846px;
    margin: 0 auto;
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    .steps-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .step {
        width: 204px;
        border-bottom: 1px solid #C3C6D0;
        color: #B7B9C0;
        font-size: 15px;
        line-height: 30px;
        letter-spacing: 0.01em;
        font-weight: 500;
      }
      .active {
        color: #134753;
        border-color: rgba(19, 71, 83, 0.8);
      }
      .alt {
        width: 133px;
      }
    }
    .payment-content-wrap {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      .payment-step {
        width: 418px;
        // height: 300px;
        // background-color: red;
        .success-msg-wrap {
          .succ-title {
            font-weight: 500;
            font-size: 16px;
            color: #5A7E87;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            margin-bottom: 25px;
          }
          .text {
            font-size: 17px;
            line-height: 25px;
            letter-spacing: 0.01em;
            color: #062931;
          }
          .btn {
            margin-top: 33px;
            width: calc(213px - 80px);
            height: calc(44px - 24px);
            padding: 12px 40px;
            background-color: #FF6359;
            border-radius: 55px;
            color: #FFF;
            font-size: 15px;
            text-align: center;
            letter-spacing: 0.01em;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
      .cart {
        width: calc(311px - 32px);
        // min-height: 214px;
        height: fit-content;
        padding: 26px 15px 33px;
        background-color: #fff;
        border: 1px solid #5A7E87;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
        border-radius: 22px;
        .cart-title {
          padding: 0 5px;
          font-size: 20px;
          line-height: 25px;
          font-weight: 500;
          color: #062931;
          display: flex;
          justify-content: space-between;
          .details {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #9CB2B5;
            display: flex;
            align-items: center;
            cursor: pointer;
            .img-wrap {
              height: 25px;
              width: 8px;
              margin-left: 5px;
              display: flex;
              align-items: center;
              img {
                display: block;
                width: 100%;
              }
            }
          }
        }
        .section {
          width: calc(100% - 10px);
          padding: 0 5px;
          margin-top: 16px;
          .section-title-wrap {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 10px;
            .section-title {
              text-transform: uppercase;
              font-size: 17px;
              font-weight: 500;
              letter-spacing: 0.05em;
              color: #5A7E87;
            }
            .edit {
              font-size: 12px;
              letter-spacing: 0.02em;
              color: #9CB2B5;
              cursor: pointer;
            }
          }
          .details {
            font-size: 15px;
            line-height: 20px;
            color: #062931;
          }
        }
        .divider {
          width: 100%;
          border-bottom: 1px solid #9CB2B5;
          margin-top: 26px;
          margin-bottom: 11px;
        }
        .alt {
          margin: 15px 0;
        }
        .price-wrapper {
          width: calc(100% - 10px);
          padding: 0 5px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .total {
            font-size: 17px;
            font-weight: 500;
            color: #062931;
          }
          .price {
            font-size: 22px;
            font-weight: 500;
            color: #062931;
            mix-blend-mode: normal;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .payment-wrapper {
    .content-wrapper {
      width: calc(100% - 54px);
      .steps-wrapper {
        width: 710px;
        // margin: 0 auto;
        .step {
          width: 170px;
        }
      }
      .payment-content-wrap {
        .payment-step{
          width: 404px;
        }
        .cart {
          width: calc(280px - 32px);
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .payment-wrapper{
    .title {
      h1 {
        bottom: 32px;
        left: 16px;
        font-size: 34px;
      }
    }
    .content-wrapper {
      width: calc(100% - 32px);
      margin-top: 40px;
      .steps-wrapper {
        width: 100%;
        .step {
          width: 60px;
          font-size: 14px;
        }
        .alt {
          width: 133px;
        }
      }
      .payment-content-wrap {
        margin-top: 30px;
        flex-direction: column-reverse;
        .payment-step {
          width: 100%;
          margin-top: 42px;
          margin-bottom: 70px;
          .success-msg-wrap {
            .succ-title {
              font-size: 15px;
              margin-bottom: 20px;
            }
            .text {
              font-size: 17px;
            }
            .btn {
              padding: 12px 35px;
              font-size: 14px;
            }
          }
        }
        .cart {
          width: calc(100% - 30px);
          padding-bottom: 25px;
          .cart-title {
            font-size: 18px;
          }
          .section-wrapper {
            .section {
              .section-title-wrap {
                .section-title {
                  font-size: 15px;
                }
              }
              .details {
                font-size: 13px;
              }
            }
            .divider {
              margin-top: 16px;
              margin-bottom: 14px;
            }
            .price-wrapper {
              .total {
                font-size: 16px;
              }
              .price {
                font-size: 21px;
              }
            }
          }
        }
      }
    }
  }
}
