
          @import "@/assets/css/vars.scss";
        










































































































.roundAllShareComponent { 
          //  // margin-top:16px;
          //  background: #ffffff;
          //   margin-left: 130px;
          //   // font-family: 'Gotham Pro Medium';
          //   font-style: normal;
          //   font-weight: 500;
          //   font-size: 15px;
          //   line-height: 22px;
          //   width: 297px;
          //   color: #062931;
          //   height: fit-content;
          //   position: -webkit-sticky;
          //   position: sticky;
          //   top: 20px;
          //   padding-bottom:20px;
         z-index: 1020;
          display: flex;
          width: fit-content;
          background: #ffffff;


          .downloadDiv{
            margin-right: 10px;
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background: #467F8F;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .downloadDiv:hover{
            background: #134753;
          }
          .iconShare {
            cursor: pointer;

          }

        }
