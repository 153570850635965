
          @import "@/assets/css/vars.scss";
        















































































































































































































































































































































































































































// body{
//   overflow: hidden;
// }

.estimate-wrapper-background {
    //  transparent navbar
  overflow-x: hidden;
  // contain: paint;

    margin-top:-110px;
    padding-top: 110px;


    //  background-color: #467F8F;
    background-color: #ffffff;
    //         //   background: linear-gradient(90deg, rgba(238, 239, 243, 0) 1.8%, #EEEFF3 74.8%), #51E6FB;

    // background-image: linear-gradient(#467f8f 6.7%, #467f8fc9,#467f8f4d, #EEEFF3);

     background-image: linear-gradient(#467f8f 6.7%, #467f8fc9,#eeeff3ea, #EEEFF3);
      
    //         //background: linear-gradient(167.51deg, #467F8F 14.17%, rgba(70, 127, 143, 0) 79.85%);
    //  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 36.84%, #FFFFFF 100%), linear-gradient(270deg, #FFFFFF 0.03%, rgba(255, 255, 255, 0) 16.69%), linear-gradient(90deg, #FFFFFF 0.05%, rgba(255, 255, 255, 0) 16.85%), #EEEFF3;// min-height: calc(100vh - 80px);
    //         // background:linear-gradient(#467F8F 0%, #467f8f86 13%, #EEEFF3 25%);
    //  background: linear-gradient(167.51deg, #467F8F 14.17%, rgba(70, 127, 143, 0) 79.85%);

    //   width: 100%;
    //   position: relative;


    //   background-attachment: fixed;
    //          // background-position: center;
    //   
    //           // object-fit: scale-down;
    //   background-size: cover;
    //           // background-repeat: no-repeat;

    //   height: 100vh;
    //   height: fit-content;
               // overflow: auto;




// style with img
    // background:linear-gradient( #467f8f 0%, #EEEFF3 75%);
    background-image: url('../assets/images/Background.svg');
    // background-image: url('../assets/images/appSmall.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 5%;
    background-size: cover;
  
}
// .estimate-wrapper-background::before {
//   background: 50% 50% / cover;
//   bottom:0;
//   content:"";
//   left: 0;
//   position: absolute;
//   right: 0;
//   top: 0;
//   display: block;
//   //  background-image: linear-gradient(#467f8f 6.7%, #467f8fc9,#467f8f4d, #EEEFF3);
//    background-image: url('../assets/images/Background.svg');
//   background-size: cover;
//   transform-origin: center center 0;
//   transform: translateZ(-1px) scale(2);
//   z-index: -1;
//   min-height: 100vh;

// }

@media screen and (max-width: 450px) {
  .estimate-wrapper-background {
    padding-top: 180px;
  }
}

