
          @import "@/assets/css/vars.scss";
        
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.valuations-wrapper {
  width: 100%;
  .title {
    width: 100%;
    height: 185px;
    background: linear-gradient(116.78deg, #104756 13.98%, #3D7484 75.44%);
    display: flex;
    justify-content: center;
    position: relative;
    h1 {
      margin: 0;
      position: absolute;
      bottom: 47px;

      font-size: 36px;
      color: #fff;
    }
  }
  .content-wrapper {
    .info-wrap {
      width: calc(846px - 20px);
      padding-left: 20px;
      height: 50px;
      background-color: #FFF;
      border: 1px solid #5A7E87;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
      border-radius: 20px;
      font-size: 17px;
      color: #062931;
      margin: 0 auto;
      margin-top: 50px;
      display: flex;
      align-items: center;
      a {
        color: #ff6359;
        text-decoration: none;
      }
    }
    .reports-wrap {
      width: 846px;
      margin: 0 auto;
      margin-top: 55px;
      display: flex;
      flex-direction: column;
      .report-wrap:nth-child(even) {
        background-color: #EEEFF3;
      }
      .reports-header {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        .heading {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          // counter-reset: #5A7E87; ???
        }
        .car {
          margin-left: 50px;
          margin-right: 409px;
        }
        .expire {
          width: 97px;
          margin-right: 10px;
        }
      }
    }
    .steps-wrap {
      height: 30px;
      margin: 0 auto;
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      .box {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        color: #062931;
        cursor: pointer;
        &:hover {
          border-radius: 50%;
          background-color: #062931;
          color: #FFF;
        }
      }
      .active {
        border-radius: 50%;
        background: #062931;
        color: #FFF;
      }
      .chevron {
        &:hover {
          background-color: initial;
          color: #062931;
        }
        &.disabled {
          opacity: 50%;
        }
        .ico-wrap {
          height: 9px;
          width: 4px;
          img {
            display: block;
            height: 100%q;
          }
        }
      }
    }
    .see-fav-btn-wrap {
      .see-fav-btn {
        width: 234px;
        height: 50px;
        margin: 60px auto 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border: 1px solid rgba(6, 41, 49, 0.8);
        border-radius: 40px;
        font-weight: 500;
        font-size: 17px;
        line-height: 25px;
        color: #062931;
        cursor: pointer;
      }
    }
  }
  .no-reports-wrap {
    width: 542px;
    margin: 0 auto;
    margin-top: 57px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .no-report-text {
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      color: #062931;
    }
    .start-val-btn {
      width: 248px;
      height: 50px;
      background-color: #ff6359;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 17px;
      line-height: 25px;
      margin-top: 53px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {
  .valuations-wrapper {
    .content-wrapper {
      .info-wrap {
        width: 694px;
      }
      .reports-wrap {
        width: 714px;
        .reports-header {
          .car {
            margin-right: 245px;
          }
          .expire {
            margin-right: 30px;
          }
        }
      }
      .steps-wrap {
        .box {
          &:hover {
            border-radius: 50%;
            background-color: #fff;
            color: #062931;
          }
        }
        .dots {
          pointer-events: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .valuations-wrapper {
    .title {
      h1 {
        font-size: 34px;
        bottom: 32px;
        left: 16px;
      }
    }
    .content-wrapper {
      .info-wrap {
        width: 323px;
        height: 75px;
      }
      .reports-wrap {
        width: 343px;
      }
      .see-fav-btn-wrap {
        width: 343px;
        margin: 0 auto;
        .see-fav-btn {
          margin-left: 0;
          margin-top: 40px;
        }
      }
      .dummy-footer {
        height: 1px; // ?????
      }
    }
    .no-reports-wrap {
      width: 100%;
      // height: calc(100% - 30px);
      margin: 0;
      margin-top: 30px;
      align-items: unset;
      text-align: unset;
      .no-report-text {
        width: 320px;
        margin-left: 16px;
        font-size: 17px;
        line-height: 25px;
        text-align: unset;
      }
      .start-val-btn {
        width: calc(100% - 32px);
        margin: 0 auto;
        border-radius: 5px;
        position: fixed;
        bottom: 80px;
        left: 16px;
      }
    }
  }
}
