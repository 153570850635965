
          @import "@/assets/css/vars.scss";
        



































































































































































































.success-wrapper {
  min-height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;

  .success {
    font-size: 32px;
    line-height: 48px;
    color: #082633;
    text-align: center;
    // font-family: 'Gotham Pro Medium';
    margin-bottom: 10%;
    padding: 0 20px;
  }
  .success.small {
    display: none;
  }
}
.wrappHelp {
  min-height: calc(100vh - 150px);
}
.help-wrapper {
  max-width: 1275px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 30px;
  display: flex;
  min-height: calc(100vh - 150px);

  .content {
    max-width: 842px;
    width: 100%;

    h1 {
      // margin-top: 80px;
      margin-top: 98px;
      // font-size: 32px;
      // line-height: 50px;
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      // color: #082633;
      color: #062931;
      // margin-bottom: 20px;
      margin-bottom: 34px;
    }
    .sub-title {
      // font-size: 16px;
      // line-height: 24px;
      // color: #082633;
      font-weight: normal;
      font-size: 17px;
      line-height: 25px;
      /* or 147% */


      /* black */

      color: #062931;
      // max-width: 624px;
      margin-bottom: 45px;
      margin-bottom: 37px;
    }
    .inputs-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;

      .input-wrapper {
        width: auto;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-bottom: 20px;
        margin-bottom: 22px;

        .input-title {
          // font-size: 16px;
          // line-height: 30px;
          // color: #082633;
          // font-family: 'Gotham Pro Medium';
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;

          // display: flex;
          // align-items: center;
          color: #062931;
          padding-bottom: 3px;
        }
        input, textarea {
           font-family: 'DM sans' !important;
          width: 406px;
          height: 50px;
          box-sizing: border-box;
          margin: 0;
          border: 1px solid #C3C6D0;
          border-radius: 5px;
          padding-left: 10px;
          // font-size: 16px;
          // line-height: 24px;
          outline: none;
          // color: #132727;
          // font-family: 'Gotham Pro';
          // padding-top: 2px;
          padding-top: 1px;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 20px;
          /* or 133% */

          display: flex;
          align-items: center;
          letter-spacing: 0.01em;
          // word-spacing:0.1em;

          /* black */

          color: #062931;
        }
        input::placeholder, textarea::placeholder {
          // opacity: 1;
          // color: #929498;
          font-family: 'DM sans' !important;
          font-size: 15px;
          line-height: 20px;
          /* or 133% */

          // display: flex;
          // align-items: center;
          // letter-spacing: 0.1em;

          /* Gray 3 text */

          color: #9498A0;
        }
        textarea {
          height: 160px;
          width: 100%;
          // padding-top: 12px;
          // padding-top: 15px;
          padding-top: 13px;
        }
        input.red, textarea.red {
          // border: 1px solid $main-color;
           border: 1px solid $err-color;
        }
      }
    }
    .btn.round {
      background-color: $main-color;
      height: 50px;
      width: 140px;
      color: white;
      border-radius: 26px;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      /* or 133% */

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 0.01em;

      /* White */

      color: #FFFFFF;
      margin-top: 18px;
      margin-bottom: 30px;
      margin-bottom: 196px;
      cursor: pointer;
      transition-duration: .3s;
    }
    .btn.round:hover {
      background-color: #FF4B49;
    }
  }
}
@media only screen and (max-width: 900px){
  .wrappHelp{
    .inputs-wrapper {
      .input-wrapper {


        input{
            width: 342px!important;
            height: 50px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px){
  .wrappHelp{
    .success.small {
      display: block !important;
    }
    .success.big {
      display: none;
    }
    .content {
      max-width: 842px;
      width: 100%;

      h1 {
        font-weight: bold;
        font-size: 34px;
        line-height: 40px;
        margin-top: 73px !important;
      
        margin-bottom: 34px;
      }
      .sub-title {
        // font-size: 16px;
        // line-height: 24px;
        // color: #082633;
        margin-bottom: 37px;
        margin-bottom: 35px;
      }
    }
    .inputs-wrapper {
      .input-title {
        //styleName: Mobile/S1_mobile;
        font-size: 16px !important;
        line-height: 25px !important;
        letter-spacing: 0em !important;
      }
      .input-wrapper {
        width: 100% !important;

        input {
          width: 100% !important;
        }
      }
    }
    .btn.round {
        margin-bottom: 41px !important;
    }
    .success-wrapper {
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 10px;
      padding-top: 45px;
      min-height: calc(100vh - 443px);

      .success {
        text-align: left;
        font-size: 26px;
        line-height: 34px;
      }
    }
  }
}
