
          @import "@/assets/css/vars.scss";
        














































































































.expWrapp{
    width:100%;
    // padding-top:27px;
    padding-top: 40px;
    padding-bottom: 93px;
    // min-height: 440px;
    height: initial;
    display:flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff!important;
    .txt{
        text-align: center;
        // margin-bottom:14px;
        // margin-bottom:15px;
        margin-bottom: 42px;
        p{
           font-style: normal;
            font-weight: 500;
            
            // font-size: 15px;
            // line-height: 40px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 308% */
            text-align: center;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            // color: #9498A0;
            // color:#134753;
            color: #5A7E87;
            margin:0;
           // margin-top: 5px;
        }
    }
    .expWrapp2{
        // max-width:1905px;
        // width: 80%; 


        /* height: 440px; */
        box-sizing: border-box;
        display: flex;
        justify-content: center !important;
        // align-items: flex-start;

            width:1278px;
            max-width: 100% !important;
            justify-content: space-around !important;
        
    }
}
 @media only screen and (max-width: 1500px){
     .expWrapp{
        .expWrapp2 {
            max-width: 100% !important;
            justify-content: space-between !important;
        }
     }

  }
   @media only screen and (max-width: 1215px){
     .expWrapp{
        .expWrapp2 {
            max-width: 100% !important;
             width: 90%;
            justify-content: space-between !important;
        }
     }

  }
 @media only screen and (max-width: 767px){
     .expWrapp{
        .expWrapp2 {
            max-width: 98% !important;
            display: initial !important;
            .txt{
            margin-bottom:4px !important;
            }
        }
     }

  }

