
          @import "@/assets/css/vars.scss";
        


















































.modal{
    position:relative;
    background: #FFFFFF;
    border-radius: 5px;
    width: 486px;
    min-height: 342px;
    margin:0 auto;
    z-index: 212;
    .mailData{
        .mailTitle{
            font-family: 'Gotham Pro Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 40px;
            text-align: left;
            // margin-top: 50px;
            // margin-bottom: 20px;
            margin:44px 40px 15px 40px;
            color: #062931;
        }
        .mailText{
            font-family: Gotham Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            margin-top: 0;
            margin-bottom: 23px;
            color: #062931;
            padding-left:40px;
            padding-right: 30px;
        }
        .hwidth{
            display:initial;
        }
        .swidth{
            display:none;
        }
    }
    .mailSend{
        .roundInput{
            box-sizing:border-box;
            width:100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
           @import '@/assets/css/inputButton2.scss';

           .msg{
                height: 24px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                // color: $main-color !important;
                color: $err-color;
            }
          }
    }
    .exit{
        position: absolute;
        right:10px;
        top:10px;
        cursor: pointer;
        text-align: right;
        img{
            width:20px;
        }
    }
}

 @media only screen and (max-width: 550px){
     .modal{
        width: 343px;
        height: initial;
        margin:0 auto;
    .mailData{
        .mailTitle{
            font-size: 28px;
            text-align: left;
             margin-left:13px;
            margin-right:15px;
            .nbspace{
                white-space: nowrap;
            }
        }
        .mailText{
            font-family: Gotham Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 24px;
            text-align: left;
            margin-top: 0;
            margin-bottom: 32px;
            color: #062931;
            padding-left:13px;
            padding-right:15px;
        }
        .hwidth{
            display:none;
        }
        .swidth{
            display:initial;
        }
    }
    .mailSend{
        .roundInput{
            box-sizing:border-box;
            width:100%;
            display: flex;
            justify-content: center;
           @import '@/assets/css/inputButton2.scss';

           .msg{
                height: 24px;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                // color: $main-color !important;
                color:$err-color;
            }
          }
    }
}

          }

