
          @import "@/assets/css/vars.scss";
        




















































































.reportsAdmin{
    max-width: 100%;
  .flexButton{
    display: flex;
    flex-wrap: wrap;
   .buttonCreate,
   .buttonReset{
        height: 52px;
        min-width: 170px;
        line-height: 52px;
        color: white;
        border: 1px solid $main-color;
        // font-family: 'Gotham Pro Medium';
        text-align: center;
        background: $main-color;
        border-radius: 52px;
        cursor: pointer;
        transition-duration: .3s;
        margin-bottom: 40px;
        padding:0 20px;
        margin-right: 20px;
       p {
         margin: auto;

       }
    }
    .buttonReset {
      background: white;
      border: 1px solid $main-color;
      color: $main-color;
      
    }
    .buttonCreate:hover{
      background: $btn-hover;
    }
    .buttonReset:hover{
      border: 1px solid rgb(206, 15, 2);;
      color: rgb(206, 15, 2);
    }
  }
}
