
          @import "@/assets/css/vars.scss";
        
























































































































































































































































.create-blog-wrapper {
  padding-bottom: 30px;

  .btn-wrapper {
    display: flex;
    flex-direction: row;

    .btn:first-child {
      margin-right: 20px;
    }
  }
  .btn {
    height: 50px;
    width: 170px;
    line-height: 52px;
    color: white;
    // font-family: 'Gotham Pro Medium';
    text-align: center;
    background: $main-color;
    border-radius: 52px;
    cursor: pointer;
    transition-duration: .3s;
    margin-bottom: 40px;
  }
  .btn:hover {
    background: #FF4B49;
  }
  .btn.reset {
    background: white;
    border: 1px solid $main-color;
    color: $main-color;
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .title {
      margin-bottom: 5px;
    }
    
    .setCheck{
      display: flex;
      flex-wrap: wrap;


      .itemCheck{
        margin-right:20px;
        word-wrap: unset;
        padding: 10px ;
      }
    }
  }
  .preview {
    margin-top: 30px;
    margin-bottom: 30px;

    .content {
      border: 1px solid rgb(204, 204, 204);
      padding: 10px;
    }
  }
}
