
          @import "@/assets/css/vars.scss";
        





































.marketValue{
    width:49%;
    height: 40%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -1px;
    margin-bottom: 9px;
    .value{
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 35px;
        text-align: center;
        color: #062931;

    }
    .name{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #76898E;
        margin: 5px ;
        margin-top: 1px;
        margin-bottom: 14px;
    }
    .valueLine{
        width: 27px;
        height: 35px;
        font-family: Gotham Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        text-align: center;

        color: #062931;
    }
     .pLineMV{
            width: 27px;
            height:3px;
            font-family: Gotham Pro;
            // font-family: 'Gotham Pro Bold';
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 35px;
            /* identical to box height, or 117% */

            text-align: center;
            margin:17px;

            /* black */

            background-color: #062931;
        }
}

@media only screen and (max-width: 1024px){
  .marketValue {
    
  }
}
@media only screen and (max-width: 550px){
  .marketValue {
      height: 60px;
      .value{
        font-size: 25px;
        line-height: 30px;
      }
      .name{
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.01em;
      }
       
    
  }
  .pLineMV{
            width: 27px;
            height:3px !important;
            /* black */
            margin:10px !important;
            background-color: #062931;
        }
}

