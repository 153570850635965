
          @import "@/assets/css/vars.scss";
        




































































































































.media-wrapper {
  padding-bottom: 30px;

  .show-more-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 120px;
    max-width: 735px;

    .show-more {
      cursor: pointer;
      font-size: 15px;
      line-height: 24px;
      color: #5A7E87;
      // font-family: 'Gotham Pro Medium';
    }
  }
  .btn {
    height: 50px;
    width: 170px;
    line-height: 52px;
    color: white;
    // font-family: 'Gotham Pro Medium';
    text-align: center;
    background: $main-color;
    border-radius: 52px;
    cursor: pointer;
    transition-duration: .3s;
    margin-bottom: 40px;
  }
  .btn:hover {
    background: #FF4B49;
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .title {
      margin-bottom: 5px;
    }
  }
  .image-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
    flex-wrap: wrap;

    .img-wrapper {
      height: 100px;
      width: 100px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .info {
      display: flex;
      flex-direction: column;

      div {
        cursor: pointer;
      }
    }
  }
}
