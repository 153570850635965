
          @import "@/assets/css/vars.scss";
        
























































.admin-login-wrapper {
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;

  .login-wrapper {
    padding: 30px;
    border: 1px solid $main-color;
    display: flex;
    flex-direction: column;
    margin-bottom: 10%;

    input {
      margin-bottom: 15px;
      outline: none;
    }
    .btn {
      margin-left: auto;
      background-color: $main-color;
      padding: 7px 10px 5px 10px;
      color: white;
      cursor: pointer;
    }
  }
}
