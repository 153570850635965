
          @import "@/assets/css/vars.scss";
        


















































































































.options-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #5A7E87;
  }
  .option {
    width: calc(100% - 22px);
    height: 48px;
    background-color: #fff;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #DEE0E5;
    border-radius: 5px;
    cursor: pointer;
    .left-wrap {
      display: flex;
      .check {
        height: 14px;
        width: 14px;
        border-radius: 50%;
        border: 1px solid #103D49;
        margin-right: 7px;
        cursor: pointer;
      }
      .active {
        background-color: #103D49;
      }
      .name {
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.01em;
        color: #062931;
        margin-top: -1px;
      }
    }
  }
  .open {
    border-radius: 5px 5px 0 0;
  }
  .inputs-wrap {
    width: calc(100% - 22px);
    max-width: 418px;
    height: 173px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f8f8f9;
    border: 1px solid #DEE0E5;
    border-radius: 0 0 5px 5px;
    .singleInput {
      width: calc(100% - 10px);
      height: 40px;
      background-color: #fff;
      border-radius: 5px;
      border: none;
      padding: 0;
      padding-left: 10px;
      // color: #9498A0;
      color: #062931;
      letter-spacing: 0.01em;
      font-family: 'DM Sans';
      font-size: 15px;
      line-height: 20px;
      input {
        ::placeholder {
          color: #9498A0;
          letter-spacing: 0.01em;
          font-family: 'DM Sans';
          font-size: 15px;
          line-height: 20px;
        }
        &:active {
          border: 1px solid rgba(6, 41, 49, 0.8);
        }
      }
    }
  }
  .card {
    margin-top: 33px;
    .right-wrap {
      width: 86px;
      display: flex;
      justify-content: space-between;
      .img-wrap {
        height: 25px;
        img {
          height: 100%;
        }
      }
    }
  }
  .paypal {
    margin-top: 20px;
    .right-wrap {
      .img-wrap{
        height: 22px;
        img {
          height: 100%;
        }
      }
    }
  }
  .terms {
    width: calc(100% - 10px);
    padding-left: 10px;
    margin-top: 36px;
    display: flex;
    .check {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      border: 1px solid #103D49;
      margin-right: 7px;
      cursor: pointer;
    }
    .active {
      background-color: #103D49;
    }
    span {
      width: 380px;
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0.01em;
      color: #062931;
      a {
        text-decoration: none;
        font-weight: 500;
        color: #062931;
      }
    }
  }
  .btn-wrap {
    margin-top: 40px;
    .btn {
      font-family: inherit;
      padding: 12px 40px;
      border: 1px solid rgba(6, 41, 49, 0.8);
      border-radius: 55px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: 0.01em;
      color: #062931;
      background-color: #fff;
      cursor: pointer;
    }
    .filled {
      color: #fff;
      background-color: #FF6359;
      border: none;
      margin-left: 10px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .options-wrap {
    .title {
      font-size: 15px;
    }
    .option {
      .left-wrap {
        .check {
          margin-right: 10px;
        }
        .name {
          font-size: 14px;
        }
      }
    }
    .card {
      margin-top: 23px;
    }
    .terms {
      margin-top: 26px;
      .check {
        margin-right: 10px;
        margin-top: 10px;
      }
      span {
        width: 307px;
      }
    }
  }
}
