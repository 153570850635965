
          @import "@/assets/css/vars.scss";
        

























.termsComp{
    margin-bottom: 32px;
    width:100%;
    box-sizing: border-box;
    .line{
        box-sizing: border-box;
        // border-top: 1px solid #062931;
        height: 0px;
    }
    .maintxt{
        margin-top:10px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        /* identical to box height, or 150% */

        // display: flex;
        // align-items: center;
        letter-spacing: 0.01em;

        /* black */

        color: #062931;
    }
    p{
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */


        /* black */

        color: #062931;
    }
    ul li {
        margin-bottom: 20px;
       // list-style-image: url('../../assets/images/color.png');
       color: #5A7E87 !important; 
    }

}

 @media only screen and (max-width: 767px) {
     .termsComp{
         p{
            // font-family: Gotham Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 15px !important;
            line-height: 24px;
            
            color: #082633;
         }
     }
 }

