
          @import "@/assets/css/vars.scss";
        























.expVideo{
    .rowWrap{
        display: flex;
        padding:6px 10px;
        color:#062931;
        .spanImg{
            margin-left:10px;
            // margin-right:25px;
            margin-right:19px;
            height: 100%;
            // margin-top: 4px;
            margin-top: 2px;
        }

        p{
            margin: 0;
            padding: 0;
            font-size: 17px;
            line-height: 25px;
            color:inherit;
        }
    }
     .bold{
            // font-weight: bold;
            font-weight: 500;
        }
}
@media only screen and (max-width: 600px){
    .expVideo{
        .rowWrap{
            padding:6px 15px;
            .spanImg{
                margin-left:5px;
                margin-right:16px;
                height: 100%;
                margin-top: 4px;
            }

        }
        
    }
}
