
          @import "@/assets/css/vars.scss";
        































































































































































a {
  text-decoration: none;
}
#index {
  position: relative;
}
.sample-report-wrapper {
  background: $main-color;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
  z-index: 999;
  position: sticky !important;
  align-self: flex-start;
  top: 0;


  @keyframes example {
    from {right: -200px;}
    to {right: 2760px;}
  }

  .sample-report {
    width: auto;
    // font-family: 'Gotham Pro Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    // color: #E7ECED;
    color: #ffffffb3;
    cursor: default;
    position: absolute;
    animation-duration: 40s;
    animation-timing-function: linear;
    animation-name: example;
    animation-iteration-count: infinite;
    white-space: nowrap;
    animation-fill-mode: forwards;
    right: -200px;
  }
}

.header-wrapper {
  // height: 80px;
  height: 70px;
  width: 100%;
  padding: 0 30px;
  max-width: calc(1440px + 60px);
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index:210;

  span {
    // font-family: $main-font;
    cursor: pointer;
    color: #4C4C52;
    margin-top: 8px;
  }
  #value-my-car {
    margin-left: 25px;
  }
  #blog {
    margin-left: 25px;
  }
  @media only screen and (max-width: 768px) {
    #how-it-works, #blog {
      display: none;
    }
  }
  .btn {
    font-size: 16px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: right;
    color: $main-color;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid rgba(255, 86, 84, 0.7);
    border-radius: 25px;
    padding: 0px 30px;
    cursor: pointer;
    font-weight: 500;
    // font-family: 'Gotham Pro Medium';
    &:hover {
      border: 1.8px solid #FF5654;
    }
  }
  .logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    #smallLogo {
      display: none;
    }
    #bigLogo {
      display: block;
      height: 25px;
    }
  }
  @media only screen and (max-width: 450px) {
    .logo-wrapper #smallLogo {
      display: block;
    }
    .logo-wrapper #bigLogo {
      display: none;
    }
  }
}
 .background{
   position: relative;
   z-index:210 !important;
   background-color: rgb(255, 255, 255);
  //  height: 70px;
   height: fit-content;
 }
 .background.sampleReport{
   height: 110px !important;
   background-color: #fff !important;
 }
.background.blue {
  background-color: #104756;
  z-index:110;

  span {
    color: #E7ECED;
  }
}
.background.orange {
  background-color: #FF5654;
  z-index:110;

  span {
    color: white;
  }
}
.background.lightGr{
  background-color: transparent;
  // padding-top: 11px;
  box-sizing: border-box;
  // background-color: transparent;
  z-index:110;
  .header-wrapper{
  //  height: 60px;
  }
  span {
    color: white;
  }
}
@media only screen and (max-width: 1024px) {
  .lightGr {
    margin-right: 60px;
  }
}
@media only screen and (max-width: 550px) {
  .lightGr {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 450px) {
  .disappearing {
    display: none;
  }
  .header-wrapper {
    padding: 0 16px;
  }
}
