
          @import "@/assets/css/vars.scss";
        









































































































































































































  .imageFrame{
    z-index:1000;
    position: relative;
    height:216px;
    width: 216px;
    // background-color: rgb(5, 5, 5);
    // border:1px solid #000000;
    // background: linear-gradient(150.58deg, #000000 1.1%, rgba(0, 0, 0, 0) 42.66%);
    // padding-left: 12px;
    .wrapImg{
        height: 188px;
        width: 188px;
        border-radius: 2.69213px;
        overflow: hidden;
        // background: #C4C4C4;
        position: relative;
        background: #CFD8DB;
        img{
            height: 100%;
            width: 100%;
        }
        .imageFilter{
            position: absolute;
            top: 0;
            left: 0;
            height: 188px;
            width: 188px;
            // background: #c4c4c488;
            // background: #ffffffb6;
            background: #fff;
            opacity: 0.7;
            overflow: hidden;

        }
        .divImg{

            position: absolute;
            top:0;
            left: 0;
            box-sizing: border-box;
            height: 188px;
            width: 188px;
            border-radius:50%;
            overflow: hidden;
            img{
                height: 100%;
                width: 100%;
            }
            
        }
    }
    .wrapTxt{
        // font-family: Gotham Pro;
        // font-style: normal;
        // font-weight: 500;
        // font-size: 12px;
        // line-height: 20px;
        /* identical to box height, or 167% */

        // display: flex;
        // align-items: center;
        // // justify-content: space-between;
        // letter-spacing: 0.02em;
        // // text-transform: uppercase;

        // /* Gray turquoise - */

        // color: #9CB2B5;
        // padding-top: 8px;
        // font-style: normal;

        padding-top: 7px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;
        letter-spacing: 0.01em;

        /* Gray turquoise - */

        color: #9CB2B5;

        
        label, span{
            cursor: pointer;
        }
        label:first-of-type{
            // margin-right: 55px;
            // margin-right: 37px;
            margin-right: 35px;
        }
        label:hover{
            color:  #5A7E87;
        }

    }

}

@media only screen and (max-width: 750px){
    .imageFrame {
        padding-left: 0;
        .wrapImg{
            height: 200px;
            width: 200px;

            .imageFilter{
                position: absolute;
                top: 0;
                left: 0;
                height: 200px;
                width: 200px;
                // background: #c4c4c488;

            }
            .divImg{

                position: absolute;
                top:0;
                left: 0;
                box-sizing: border-box;
                height: 200px;
                width: 200px;
            }
        }
    }
}

