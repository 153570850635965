
          @import "@/assets/css/vars.scss";
        

























































































































































































































































































































































































































































































































































@media only screen and (max-width: 500px) {
 
   .wrappBadges{
     position: fixed;
     left:0;
     bottom:61px !important;
     z-index: 2;
   }
   .wrappBadgesIOS{
          // position: fixed;
          // left:0;
          // // bottom:61px !important;
          // z-index: 2;
          // background-color: brown !important;
    } 


 @supports (-webkit-overflow-scrolling: touch) { 

        // padding-bottom:70px
        // height: 60px; standard 
       .wrappBadgesIOS{
          position: fixed;
          left:0;
          // bottom:90px !important;
          z-index: 2;
          // background-color: brown !important;
        } 
    }
}


