
          @import "@/assets/css/vars.scss";
        




































































































































































































































































































































































.blog-list-wrapper{
  a:first-of-type>.blog-item-wrapper{
    padding-top: 32px;

  }
   a:last-of-type>.blog-item-wrapper{
    border-bottom: none;

  }
}
