
          @import "@/assets/css/vars.scss";
        





































































































































































































































































































































// @font-face {
//   font-family: 'Gotham Pro';
//   font-style: normal;
//   font-weight: normal;
//   src: local('Gotham Pro'), url('./assets/fonts/GothaProReg.woff') format('woff');
// }
// @font-face {
//   font-family: 'Gotham Pro Bold';
//   font-style: normal;
//   font-weight: bold;
//   src: local('Gotham Pro Bold'), url('./assets/fonts/GothaProBol.woff') format('woff');
// }
// @font-face {
//   font-family: 'Gotham Pro Light';
//   font-style: normal;
//   font-weight: lighter;
//   src: local('Gotham Pro Light'), url('./assets/fonts/GothaProLig.woff') format('woff');
// }
// @font-face {
//   font-family: 'Gotham Pro Medium';
//   font-style: normal;
//   font-weight: lighter;
//   src: local('Gotham Pro Medium'), url('./assets/fonts/GothaProMed.woff') format('woff');
// }
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DM Sans';
  // min-height: 700px;
  letter-spacing: 0.02em;

  input{
    letter-spacing: inherit;
  }
}

#app {
  height: calc(100vh - 100px);
  // width: 100vw;
  //  #header{
  //    z-index:1110;
  //  }
  .ie-wrapper {
    // width: 450px;
    margin: 0 auto;
    text-align: center;
    padding-top: 10%;

    div {
      text-align: center;
      margin-bottom: 50px;
      font-family: 'DM Sans';
      font-size: 20px;
      line-height: 30px;
      color: #062931;
      width: 100%;
    }
    img {
      margin: 0 auto;
      width: auto;
    }
  }
}
.gate {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  .inputs {
    border: 1px solid $main-color;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: -20%;

    span {
      margin-bottom: 20px;
    }
    input {
      margin-bottom: 20px;
      // border: none;
      outline: none;
      background: #F8F8F9;
      border: 1px solid #DEE0E7;
      padding: 5px;
      border-radius: 5px;
    }
    .submit {
      background-color: $main-color;
      height: 50px;
      width: 140px;
      color: white;
      border-radius: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 24px;
      font-size: 15px;
      font-family: 'DM Sans';
      margin-top: 10px;
      cursor: pointer;
      transition-duration: .3s;
    }
    .submit:hover {
      background-color: #FF4B49;
    }
  }
}
.workaround {
  height: 100%;
  width: 100%;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
@media only screen and (max-width: 500px) {
  #app {
  //  height: fit-content !important;
  }
}
