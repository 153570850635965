
          @import "@/assets/css/vars.scss";
        

























































































































































































































































































































































































































































































































































































































































































































































































/* .content-wrapper .content p {
  margin: 0;
} */
#singleBlog{
  .return {
    position: absolute;
    z-index: 999;
    top: 14px;
    left: 15px;

    span {
      margin-left: 5px;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: #fff;
    }
  }

}

#aSnipet{
   transition-duration: .1s;
}
#aSnipet:hover {
      background-color:  $btn-hover!important;
}
