input[type=text]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;  
}
input[type=password]{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
    letter-spacing: 5px; 
}
.buttonSocial {
    box-sizing: border-box;
    width: 406px;
    height: 50px;
    background: #EEEFF3;
    border: 1px solid transparent;
    padding:0 18px 0 10px;
    padding: 0 15px 0 10px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    // margin-bottom:15px;
    margin-bottom:13px;
    border-radius: 5px;

    /*text*/
    // font-family: 'Gotham Pro Medium';
    // font-style: normal;
    // font-weight: 500;
    // font-size: 16px;
    // line-height: 24px;
    // display: flex;
    // align-items: center;
    // color: #062931;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    /* or 133% */

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    /* black */

    color: #062931;
     span:last-of-type{
         height: 16px ;
         img{
            
         }
     }

}
.basic {
    box-sizing: border-box;
    border: 1px solid #C3C6D0;
    width: 406px;
    height: 50px;
    // margin-bottom:15px;
    // margin-bottom:25px;
    margin-bottom: 20px;
    border-radius: 5px;
    padding-left: 10px;
    padding-bottom: 2px;
    outline: none;

    // font-family: Gotham Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;

    /* black */

    color: 132727; // #062931;
}
input::placeholder{
    // font-size: 16px;
    // line-height: 24px;
    // display: flex;
    // align-items: center;
    // color: #9498A0;
    // // padding-left: 10px;
    
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    
    color: #9498A0;


}

.basic:focus{
    border: 1px solid #787883;
}

.or {
    // font-family: Gotham Pro;
    // font-style: normal;
    // font-weight: normal;
    // font-size: 16px;
    // line-height: 24px;
    font-size: 15px;
    line-height: 20px;
    

    color: #062931;
    // padding-top: calc(12px + 5px); // + 15px margin from input field = 32px space 
    // padding-bottom: calc(29px + 4px);
    // padding-top: 5px!important;
    padding-top: 2px ;
    // padding-bottom: 17px!important;
    // padding-bottom: 13px!important; // 2px down
    padding-bottom: 15px!important;
    margin:0;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .line{
        width: 186px;
        height: 0;
        border-top: 1px solid #EEEFF3; 
    }
}



.buttonSend {
    cursor: pointer;
    width: 406px;
    height: 50px;
    // font-family: 'Gotham Pro Medium';
    // font-style: normal;
    // font-weight: 500;
    // font-size: 16px;
    // line-height: 24px;
    background: #FF5654;
    border-radius: 5px;

    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;

    /* White */

    color: #FFFFFF;


    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    // padding-top: 4px;
    box-sizing: border-box;

}
.buttonSend:hover{
    background-color: $btn-hover; // is same  background-color: #FF4B49; 
   
}
 .haveaccount {
    // margin-top: 20px;
    margin-top: 12px;
    margin-top: 22px;
    // font-family: Gotham Pro;
    // font-style: normal;
    // font-weight: normal;
    // font-size: 15px;
    // line-height: 25px;
    // color: #062931;
    font-size: 15px;
    line-height: 20px;
    /* or 133% */

    letter-spacing: 0.01em;

    /* black */

    color: #062931;

}
.orange{
    // font-family: Gotham Pro;
    // font-style: normal;
    // font-weight: normal;
    // font-size: 15px;
    // line-height: 25px;
    font-size: 15px;
    line-height: 20px;
    /* or 133% */

    letter-spacing: 0.01em;
    cursor: pointer;
    color: #FF6359;
    text-decoration: none;
    font-weight: 500;
}
.orange:hover{
    color:#FF4B49;
    // Log in (hover: #FF4B49)
}
.inputErr{
    // border: 1px solid #FF6359;
    border: 1px solid $err-color;
    box-sizing: border-box;
    border-radius: 5px;
}
.errMsg {
    // font-family: Gotham Pro;
    // font-style: normal;
    // font-weight: normal;
    // font-size: 15px;
    // line-height: 24px;
    font-family:'DM Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: #FF41D5;
    display: flex;
    align-items: center;
    // justify-content: center;
    // color: #FF6359;
    color: $err-color;
    margin-top:32px; 
    margin-top: 28px; // on design is 32 but the comment is that it has to go up 4px
    margin-top: 31px; // now comment on figma 3px down
    margin-bottom: 30px; // 3px up
    margin-bottom: 27px;
    img{
        align-self: center;
        height: 16px;
        margin-right: 4px;
        // margin-bottom: 2px;
    }

}
.round {
    width:fit-content;
    position: relative;

    img{
        position:absolute;
        top:17px;
        right:10px;
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
//   border: 1px solid green;
//   -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

@media only screen and (max-width: 500px){
    .buttonSocial {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        margin-bottom:15px;
        border-radius: 5px;
    
        /*text*/
        font-size: 14px;
        line-height: 24px;
    }
    .round {
        width: 100%;
        position: relative;
    
        img{
            position:absolute;
            top:17px;
            right:10px;
        }
    }

    .basic {
        width: 100%;
        height: 50px;
        margin-bottom:15px;
        border-radius: 5px;
        font-size: 14px;
        line-height: 24px;
        color: #132727;
        
        
    
    }
    input::placeholder{
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.01em;
    }
    
    .or {
    
    
        color: #062931;
        // padding-top: 7px; // 15 px margin from input field // 22 
        // padding-bottom: 23px;
        margin:0;
        text-align: center;
        padding-top: 7px!important;
        padding-bottom: 23px!important;

        .line {
            width: 150px;
        }
    }
    
    
    
    .buttonSend {
        cursor: pointer;
        width: 100%;
        height: 50px;
        font-size: 15px;
        line-height: 24px;
    }
     .haveaccount {
        font-size: 14px;
        line-height: 25px;
        margin-top: 34px;
        margin-top: 13px;
        
    }
    .orange{
        font-size: 14px;
        line-height: 25px;
    }
    .errMsg {
        margin-top: 30px;
    }
    
    

}