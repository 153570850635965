.tooltip {
    display: block !important;
    z-index: 10000;
   
    .tooltip-inner {
     /* background: black;
      color: white; */
      max-width: 349px !important;
      background: white;
      color: #787883;
      border-radius: 3px;
      padding: 10px 10px;
      border: 0.5px solid rgba(146, 148, 155, 0.555);
      font-size: 14px;
    }
   
    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: rgba(146, 148, 155, 0.555);
      z-index: 1;
    }
   
    &[x-placement^="top"] {
      margin-bottom: 5px;
   
      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }
   
    &[x-placement^="bottom"] {
      margin-top: 5px;
   
      .tooltip-arrow {
        border-width: 0 5px 5px 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }
   
    &[x-placement^="right"] {
      margin-left: 5px;
   
      .tooltip-arrow {
        border-width: 5px 5px 5px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }
   
    &[x-placement^="left"] {
      margin-right: 5px;
   
      .tooltip-arrow {
        border-width: 5px 0 5px 5px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }
   
    &.popover {
      $color: #f9f9f9;
   
      .popover-inner {
      //  background: $color;
        background: $main-color;
        color: #ffffff!important;
        padding: 10px 13px 14px 20px;
        //padding:0px;
        border-radius: 5px;
        box-shadow: 0 5px 20px rgba(black, .2);
        border: 2px solid #DEE0E7;
        box-sizing: border-box;
        box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.14);
        border-radius: 5px;
        }
   
      .popover-arrow {
       // border-color: $color;
       border-color: $main-color;
      }
    }
   
    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity .15s, visibility .15s;
    }
   
    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity .15s;
    }
  }
  