
          @import "@/assets/css/vars.scss";
        





































































































.sidebar-wrap{
  width: 311px;
  height: 625px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  // gap: 1px;
  justify-content: space-around;
  background-color: rgba(213, 230, 230, 0.5);
  font-size: 15px;
  line-height: 20px;
  // opacity: 0.5;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  .sidebar-option{
    width: 100%;
    min-height: 59px;
    background-color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    &:hover {
      background-color: rgb(255, 255, 255);
    }
    .title{
      color: #134753;
      text-decoration: none;
      width: 278px;
      position: relative;
      top: 19px;
      left: 15px;
    }
    &:first-child{
      border-radius: 5px 5px 0 0;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    }
    &:nth-child(2){
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    }
    &:nth-child(3){
      line-height: 25px;
      height: 84px;
      .title{
        top: 16px;
        white-space: pre-line; 
        word-wrap: break-word;
        font-family: inherit;
      }
    }
    &:last-child{
      border-radius: 0 0 5px 5px;
    }
  }
  .active{
    background-color: rgb(255, 255, 255);
  }
}
