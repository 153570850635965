
          @import "@/assets/css/vars.scss";
        






































































































































































































































































































@import '@/assets/css/statsOverlay.scss';
.section-wrapper {
  background-color: white;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 22px;
  padding-left: 20px;
  padding-top: 16px;
  padding-bottom: 11px;
  box-sizing: border-box;
  // width: calc(50% - 5px);
  width: 100%;
  min-height: 427px;
  display: flex;
  flex-direction: column;
  position: relative;

  .section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 30px;
    margin-bottom: 32px;
    div{
      display: flex;
      align-items: center;
    }

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;

      letter-spacing: 0.05em;
      text-transform: uppercase;


      color: #5A7E87;

      margin: 0px;
    }
    img {
      cursor: pointer;
    }
  }
  .content {
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    // justify-content: space-between;

    .chart-wrapper {
      // margin-right: 50px;
      margin-top: 8px;
      margin-right: 24px;
      margin-bottom: 20px;
      // margin-left: 20px;
      margin-left: 20px;
      // width: 50%;
    }
    .pie-chart-legend {
      margin-bottom: 23px;
      margin-top: -3px;
      // margin-left: 30px;
      margin-left: 20px;
      margin-right: 145px;
      .legend {
        display: flex;
        flex-direction: row;
        // align-items: center;
        min-width: 136px;
        height: 50px;
        margin-bottom: 10px;
        .legendRegion{
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0.01em;
          color: #76898E;
        }
        .divCircle {
           color: #ee7d75; 
          height: 9px;
          width: 9px;
          border: 1px solid #C3C6D0;
          border-radius: 6px;
          margin-right: 9px;
          margin-top: 7px;
        }
        .span {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          /* or 156% */

          letter-spacing: 0.01em;

          /* 55%black */

          color: #062931;

        }
        .bold {
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;
          letter-spacing: 0.01em;

        }
      }
    }
  }
}
.section-wrapper.hideContent{
      min-height:fit-content !important;
      padding-bottom: 6px;
      padding-top: 17px;
      .section-header {
        margin-bottom: 10px;
      }
}
@media only screen and (max-width: 1024px){
  .section-wrapper {
    border-radius: 0px;
    width: 100%;
       .content {
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    // justify-content: space-between;

      .chart-wrapper {
        // margin-right: 50px;
        margin-right: 24px;
        margin-bottom: 20px;
        // margin-left: 20px;
        margin-left: 20px;
        // width: 50%;
      }
      .pie-chart-legend {
        margin-bottom: 20px;
        // margin-top: 20px;
        // margin-left: 30px;
        margin-left: 40px;
        margin-right: 65px;
        .legend {
          display: flex;
          flex-direction: row;
          // align-items: center;
          min-width: 136px;
          height: 50px;
          margin-bottom: 10px;
          .legendRegion{
            font-weight: normal;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.01em;
            color: #76898E;

          }
          .divCircle {
            color: #ee7d75; 
            height: 9px;
            width: 9px;
            border: 1px solid #C3C6D0;
            border-radius: 6px;
            margin-right: 9px;
            margin-top: 7px;
          }
          .span {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 25px;
            /* or 156% */

            letter-spacing: 0.01em;

            /* 55%black */

            color: #062931;

          }
          .bold {
            font-weight: 500;
            font-size: 17px;
            line-height: 25px;
            letter-spacing: 0.01em;

          }
        }
      }
    }
    
  }
}
@media only screen and (max-width: 768px){
  .section-wrapper {
    .chart-wrapper {
     // margin: 0 auto !important;
    }
    .pie-chart-legend {
      margin-top: 45px !important;
    }
  }
}
@media only screen and (max-width: 550px){
      .section-wrapper{
          padding-left: 16px;
          padding-right: 16px;
          // padding-top:20px !important;
          padding-top:15px !important;

          .section-header {
            align-items: initial;
            h3{
              // margin-top: 2px!important;
              margin-top: 5px!important;
              width:200px;
            }
          }
          .section-header>div{
             height: fit-content;
             align-self: flex-start;
          }
     
      .content{
         display: flex;
          flex-direction: column-reverse !important;
          padding-bottom: 23px;
          .chart-wrapper {
            margin: 0 auto !important;
          }
          .pie-chart-legend{
             margin-left: 14px !important;
             margin-right: 16px !important;
             margin-bottom: 0 !important;
             display: flex;
             flex-wrap: wrap;

             .legend {
               box-sizing: border-box;
                display: flex;
                flex-direction: row;
                // align-items: center;
                min-width: 50%;
                width: 50%;
                height: fit-content !important;
                min-height: 50px;
                // margin-bottom: 20px;

                .legendRegion{
                    font-size: 15px;
                    line-height: 20px;
                    width: 100%;
                }
                .span{
                  font-size: 14px;
                  line-height: 20px;
                }
                .bold {
                  font-size:15px;
                  line-height: 20px;
                }
             }
          }
      }
    }
 }
