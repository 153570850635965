
          @import "@/assets/css/vars.scss";
        











































































































































































































































































































































































































































































































@import '@/assets/css/statsOverlay.scss';
.estimate-wrapper-background {
  // transparent navbar
  margin-top: -130px;
  padding-top: 49px;
  // contain: paint;
  overflow-x: hidden;

    // background-color: #F8F8F9;
    // background-image: linear-gradient( #467F8F, #EEEFF3);
    // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 36.84%, #FFFFFF 100%), linear-gradient(270deg, #FFFFFF 0.03%, rgba(255, 255, 255, 0) 16.69%), linear-gradient(90deg, #FFFFFF 0.05%, rgba(255, 255, 255, 0) 16.85%), #EEEFF3;// min-height: calc(100vh - 80px);
    // background:linear-gradient(#467F8F 0%, #467f8f86 13%, #EEEFF3 25%);
    // width: 100%;
    // position: relative;

  //  background-color: #ffffff;
    background: linear-gradient(#467f8f 6.7%, #467f8fc9,#eeeff3ea, #EEEFF3);
      // background: linear-gradient(#467f8f 6.7%, #467f8fc9);

// style with img
     background-image: url('../assets/images/Background.svg');
    // background-image: url('../assets/images/appSmall.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 5%;


    .sample-report-wrapper {
    background: $main-color;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow-x: hidden;
    z-index: 999;
    position: fixed !important;
    // align-self: flex-start;
    top: calc(100vh - 40px);
    // top: 70px;


    @keyframes example {
      from {right: -200px;}
      to {right: 2760px;}
    }

    .sample-report {
      width: auto;
      // font-family: 'Gotham Pro Bold';
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #E7ECED;
      cursor: default;
      position: absolute;
      animation-duration: 40s;
      animation-timing-function: linear;
      animation-name: example;
      animation-iteration-count: infinite;
      white-space: nowrap;
      animation-fill-mode: forwards;
      right: -200px;
    }
  }
  .view-similar-weapper {
    min-height: calc(100vh - 80px);
    background: white;

    .view-similar-weapper-inner {
      min-height: calc(100vh - 150px);

      .view-similar {
        .view-similar-message {
          min-height: 430px;
          height: fit-content;
          margin: 0 auto;
          max-width: 582px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          box-sizing: border-box;

          span {
            // font-size: 20px;
            // line-height: 30px;
            // text-align: center;
            // color: #062931;
            // margin-bottom: 35px;
            margin-bottom: 53px;
            margin-top: -30px;

            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 30px;
            /* or 136% */

            text-align: center;

            /* black */

            color: #062931;

          }
          .btn {
            height: 50px;
            width: 170px;
            // line-height: 52px;
            // color: white;
            // font-family: 'Gotham Pro Medium';
            // text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            /* identical to box height, or 133% */

            display: flex;
            justify-content: center;
            align-items: center;
            // text-align: center;
            letter-spacing: 0.01em;
            color: #FFFFFF;

            background: $main-color;
            border-radius: 52px;
            cursor: pointer;
            transition-duration: .3s;
          }
          .btn:hover {
            background: $btn-hover;
          }
        }
        .similar-wrapper {
          max-width: 886px;
          padding: 0 20px;
          box-sizing: border-box;
          margin: 0 auto;
          // border-top: 1px solid #C3C6D0;
          margin-bottom: 60px;

          .section-wrapper {
            padding-left: 0px;
            padding-right: 0px;
            min-height: 0px;
          }
        }
      }

    }
  }

  
 .h3-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin: 0 20px;
    margin-bottom: 30px;
    

    h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #5A7E87;
        margin: 0px;

    }
    div{
      display:flex;
      
      align-items: initial;
      justify-content: flex-end;
    }
    img {
      cursor: pointer;
    }
  }
  .h3-wrapper.visibilityMain {
    padding-top: 17px !important;
    margin-bottom: 15px !important;
  }

  .lower-sections-wrapper {
    max-width: 1275px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  .estimate-wrapper {
    max-width: 1275px;
    margin: 0 auto;
    // padding: 20px 10px 0px 10px;
    padding: 120px 10px 80px 10px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin-bottom: 10px;
    position: relative;

    .left-wrapper2,
    .right-wrapper {
      border-radius: 5px;
      box-sizing: border-box;
    }

    .left-wrapper2{
      margin-right: 10px;
      max-width: 311px;
      overflow: hidden;
      width: 100%;
      padding: 0;
      height: 625px;
      display:flex;
      flex-direction: column;
      justify-content: center;

      position: -webkit-sticky;
      position: sticky;
      top: 60px;
    }
    .right-wrapper {
      max-width: 846px;
      width: 100%;
      max-height: fit-content;
       height: 100%;
        box-sizing: border-box;
      .report-wrapper2 {
          display: flex;
          margin-top: 10px;  
          box-sizing:border-box;
          width:100%;
          display: flex;
          flex-direction: row;
          // position: relative;
      }
      .main-wrapper {
        background: white;
        min-height: 735px;
        display: block;
        box-sizing: border-box;
        position: relative;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 5px 5px;
        // add this for gap in safari
          position: relative;
          height: fit-content;
          min-height: fit-content;

         .slider-parent-wrapper {
          margin-bottom: 100px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .instruction{
            display:flex;
            justify-content: flex-end;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;

            color: #B7B9C0;
            span{
                margin-left: 20px;
                margin-right: 20px;

            }
        }
         .generalMsg{
            background: #FEF5EB;
            box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.05);
            border-radius: 0px;
            height: 59px;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            color: #F18700;
            margin-top: 17px;
            margin-bottom: 42px;
           
        }

        @media only screen and (max-width: 450px){
          .slider-parent-wrapper {
            margin-bottom: 52px;
            padding: 0px;
          }
          // .instruction {
          //   margin-bottom: 52px;
          // }
        }
        .analytics {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

            font-family:'DM Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            /* or 133% */

            letter-spacing: 0.01em;

            /* black */
            color: #062931;
            margin-left:41px;
            margin-bottom: 60px;
            margin-bottom: 10px;
            padding-bottom: 50px;

                        .left1 {
                margin-right: 81px;
                width: fit-content;
                .analGreen{
                    margin-bottom: 0px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 25px;
                    /* or 156% */

                    letter-spacing: 0.01em;

                    /* blue primary text */

                    color: #06DBF9;

                }

            }
            .left{
                margin-right: 50px;
                width: 204px;

            }
            .right, .left, .left1{
                span{
                    display: inline-block;
                    margin-bottom: 10px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 25px;
                    /* or 156% */

                    letter-spacing: 0.01em;
                } 

           .analTxt{
                margin-bottom: 0px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 25px;
                letter-spacing: 0.01em;

                color: #9498A0;
           }
            
          }
          span {
            font-weight: 700;
          }
        }
      }
    }
  }
  .mobile-top-nav {
    width: 100%;
    height: 90px;
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 999;
    .back {
      width: 70px;
      position: relative;
      top: 14px;
      left: 15px;
      span {
        margin-left: 5px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;

        color: #787883;
      }
    }
  }

}
.msg{
    height: 24px;
    margin: 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: $main-color !important;
}
// .colorBlackBlure{
//   position: fixed !important;
//   top: 0;
//   left: 0;
//   background: #062931f1;
//   // opacity: 0.94;
//   width: 100vw;
//   height: 100vh;
//   z-index:1001;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: auto !important;
// }
@media only screen and (max-width: 1024px){
  .estimate-wrapper-background {
    // .lower-sections-wrapper {
    //   padding: 0px;
    // }
    .estimate-wrapper {
      width: 100%;
      padding: 0px;
      .left-wrapper,
      .left-wrapper2 {
        display: none;
      }
      .line {
        display: none;
      }
      .right-wrapper {
        // max-width: 100%;
        max-width: calc(100% - 70px); // 70px side newShare
        height: 100%;
        box-sizing: border-box;

        .main-wrapper {
          // display: block;
          // margin-bottom: 10px;
          // padding: 20px;
          // border-radius: 0px;
          // position: relative;
          display: block;
          margin-bottom: 10px;
          padding-bottom: 1px;
          //   padding: 20px;
          border-radius: 0px;
          position: relative;
          height: fit-content;
          min-height: fit-content;
           .h3-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding-top: 20px;
              margin: 0 20px;
              // margin-right: 0;
              // padding-right: 20px;
              margin-bottom: 20px;
              
              div{
                display:flex;
                // width: 94px;
                align-items: center;
                justify-content:space-between !important;
                div{
                  width:fit-content
                }
              }
            }
        }
        
      //      .price-wrapper{
      //       box-sizing: border-box;
      //       // height:418px;
      //       min-height: 382px ;
      //       position: relative;
      //       padding-top:25px;
      //       padding-bottom: 33px;
      //       .br{
      //         display:none;
      //       }
      //       .txt{
      //         box-sizing:border-box;
      //         // min-height:20%;
      //         // padding-top: 25px;
      //         p{
      //           margin: 0 !important;
      //           // font-style: normal;
      //           // font-weight: bold;
      //           // font-size: 18px;
      //           // line-height: 26px;
      //           // color: #082633;
      //           font-family:'DM Sans';
      //           font-style: normal;
      //           font-weight: 500;
      //           font-size: 22px;
      //           line-height: 30px;
      //           /* or 136% */


      //           /* black */

      //           color: #062931;

      //         }
      //       }
      //       // .price{
      //       //   height: 268px;
      //       //   height: 268px;
      //       //   // padding-top:109px;
      //       //   display:flex;
      //       //   justify-content: center;
      //       //   align-items: center;
      //       //   .p{
      //       //     // font-family: 'Gotham Pro Bold';
      //       //     // font-style: normal;
      //       //     // font-weight: bolder;
      //       //     // font-size: 48px;
      //       //     //line-height: 48px;
      //       //     /* identical to box height, or 109% */
      //       //     // margin-bottom: 40px;

      //       //     font-family:'DM Sans';
      //       //     font-style: normal;
      //       //     font-weight: bold;
      //       //     font-size: 51px;
      //       //     line-height: 55px;
      //       //     /* or 108% */

      //       //     display: flex;
      //       //     align-items: center;
      //       //     text-align: center;

      //       //     /* black */

      //       //     color: #062931;
      //       //     text-align: center;
      //       //     color: #082633;
      //       //   }
      //       // }
      // }
    //  .line {
    //          display:block !important;
    //           box-sizing: border-box;
    //           width:100%;
    //           height: 3px;
    //           position: absolute;
    //           left: 0px;
    //           bottom: 0px;
    //           background: #103D49;
    //   }
      .report-wrapper2{
       // height:220px;
        box-sizing:border-box;
        width:100%;
        display: flex;
        flex-direction: column;
        div{
          box-sizing: border-box;
          width:49.25%;
          display:block;
        }
        div:first-of-type{
          margin-right: 1.5%;
        }

      }
      // .report-wrapper{
      //   height:454px;
      //   box-sizing:border-box;
      //   width:100%;
      //   .txt{
      //     box-sizing:border-box;
      //     min-height:20%;
      //     padding-top: 25px;
      //     p{
      //       margin: 0 !important;
      //       font-style: normal;
      //       font-weight: 500;
      //       font-size: 18px;
      //       line-height: 26px;
      //       color: #082633;
      //     }
      //   }
      //   .secPart{
      //     width: 100%;
      //     height: 80%;
      //     display:flex;
      //     flex-direction:column;
      //     justify-content: space-around;
      //     align-items: flex-start;
      //     p{
      //      font-style: normal;
      //       font-weight: normal;
      //       font-size: 15px;
      //       line-height: 24px;
      //       color: #4C4C52;
      //     }
      //     .roundInput{
      //       box-sizing:border-box;
      //       width:100% !important;
      //       // padding-bottom: 20px;
      //      @import '@/assets/css/inputButton.scss';
      //     }
      //      .sample{
      //        font-style: normal;
      //         font-weight: 500;
      //         font-size: 13px;
      //         line-height: 24px;
      //         letter-spacing: 0.02em;
      //          color: #5A7E87 !important;
      //      }
      //      .sample:hover{
      //        cursor: pointer;
      //      }
      //   }
      // }
      }
    }
  }
}
@media only screen and (max-width: 768px){
   .estimate-wrapper-background {
     .estimate-wrapper {
       .right-wrapper{
         .price-wrapper{
            position: relative !important;
          .line{
              display:block !important;
              box-sizing: border-box;
              width:100%;
              height: 3px;
              position: absolute;
              left: 0px;
              bottom: 0px;
              background: #103D49;
          }
       }
          .report-wrapper{
            .secPart{
               .roundInput{
                 width:100% !important;
               }
            }
          }
           .report-wrapper2{
       // height:220px;
          box-sizing:border-box;
          width:100%;
          display: block;
          div{
            box-sizing: border-box;
            width:100%;
            display:block;
          }
          div:first-of-type{
            margin-right: 0 !important;
          }

        }
       }
     }
      .view-similar {
        .view-similar-message {
          box-sizing: border-box;
          
         min-height: 396px !important;
         span{
          //  margin-top: 0px!important;
           padding-top: 63px;
           margin-bottom:42px!important ;
         }
        }
         
      }
   }

}
@media only screen and (max-width: 550px){
  .estimate-wrapper-background {
    // padding-top: 165px;
    padding-top: 222px;
    background-position: top !important;
     .estimate-wrapper {
      .right-wrapper {
          .price-wrapper{
                padding-left: 16px;
                padding-right: 16px;
                padding-top: 33px;
                .br{
                  display:initial;
                }

          }
          .main-wrapper{
            //  padding-left: 16px;
            //  padding-right: 16px;
          }
      }
     }
  }
  .is-scrolled {
    background-color: rgba(252, 252, 252, 1);
    border-bottom: 1px solid #dee0e5;
  }
 }

@media only screen and (max-width: 500px){
  .estimate-wrapper-background {
    .sample-report-wrapper{
      top: calc(100vh - 101px);
    }
     .estimate-wrapper {
       margin-bottom: 0px ; // newShare 
      .right-wrapper {
           max-width: 100%;
          // .price-wrapper{
          //       padding-left: 16px;
          //       padding-right: 16px;
          //       min-height: 190px;
          //       .br{
          //         display:initial;
          //       }
          //       .price{
          //         height: 131px;
          //         .p{
          //           font-size: 40px;
          //           line-height: 48px;
          //         }
          //       }

          // }
          .main-wrapper{
            //  padding-left: 16px;
            //  padding-right: 16px;
          }
      }
     }
  }
 }


