
          @import "@/assets/css/vars.scss";
        







































































































































































































































































































































.singinComponent{
    .basic{
        // margin-bottom: 15px;
    }
    .errMsg{
        margin-top: 30px;
    }
      // }
        .rember {
            // margin-top: 26px;
            // margin-bottom: 28px;
            // margin-top: 25px;
            // margin-bottom: 40px;
            // margin-top: 42px;
            // margin-top: 40px;
            margin-top: 42px;
            margin-bottom: 32px;
        }
        .or{
            //  padding-bottom: 13px !important;
            padding-bottom: 15px !important;
        }
}
@media only screen and (max-width: 500px){
    .singinComponent{
        .basic{
            // margin-bottom: 15px;
        }
        // }
        .rember {
            margin-bottom: 30px;
            margin-top: 29px;
        }
        .or{
            //  padding-bottom: 13px !important;
            // padding-bottom: 15px !important;
        }
        .haveaccount{
            margin-top: 24px !important;
        }
    }

}

