
          @import "@/assets/css/vars.scss";
        



































.wrappItemPressReleases {
     margin-bottom: 28px;
     text-decoration: none;
     .upp {
        text-transform: capitalize;
     }
    .titleItemPressReleases {
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
        /* identical to box height, or 136% */


        /* black */

        color: #062931;

        margin-bottom: 5px;
        margin-top:2px;
        cursor: pointer;

    }
    .dateItemPressReleases {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        letter-spacing: 0.04em;

        /* black */

        color: #062931;
        margin:0;


    }
    .readMore{
      font-weight: 500;
      font-size: 15px;
      line-height: 25px;
      text-decoration: none;
      // color: #5A7E87;
      color: #467F8F;

      img{
        margin-left: 5px;
      }

    }
    .readMore:hover{
      opacity: .8;
    }
}
// .wrappItemPressReleases:hover{
//     .titleItemPressReleases {
//         color:#000000;
//     }
// }

