
          @import "@/assets/css/vars.scss";
        
























.widgetBlack {
    width: 300px;
    height: 600px;
    //background: #050807;
    margin-left: 40px;
    position: sticky;
    top: 25px;

    .holdContent {
        // background-color: gold;
        //  padding:25px;
        padding: 23px 25px; 
        width:100%;
        height: 100%;
        background: #FCF9F3;
        border: 1px solid rgba(222, 224, 229, 0.5);
        box-sizing: border-box;
        border-radius: 5px;
        color: #103D49;


        .recommends{
            margin-bottom:15px;
            height:25px;
            width:194px;
            box-sizing:border-box;
            display:flex; 
            align-items: center;
            justify-content:center; 
            background: #C0D4FF;
            border-radius: 5px;
            // line-height: 25px;
            font-size: 14px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #103D49;
            flex: none;
            order: 0;
            flex-grow: 0;
            
        }

         .widgetTitle{
            font-family: Poppins;
            font-style: normal;
            font-weight: bold;
            font-size: 64px;
            line-height: 65px;
            color: #103D49;

            .big {
                display: initial;
            }
            .medium {
                    display: initial;
            }
            .small{
                display: none;
            }
         }

         .widgetTxt{
            font-family: DM Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 30px;
            /* or 150% */


            /* Dark blue - */

            color: #134753;

            padding:8px 0 57px 0;



            .small{
               display: none;
             }
             .big {
                display: initial;
            }

         }

         .button{
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            width: 257px;
            height: 50px;
            background: #FF6359;
            border-radius: 35px;
            font-family: DM Sans;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            /* identical to box height, or 133% */

            display: flex;
            align-items: center;
            letter-spacing: 0.01em;

            /* White */

            color: #FFFFFF;

         }
         .button:hover{
             background: $btn-hover;
         }
    }
}
@media only screen and (max-width: 1080px){// 1280px
    .widgetBlack{
        width: initial;
        height:fit-content;
        min-height: 305px ;
        padding-bottom: 40px;
        position: initial;
        // margin: 0 29px;
         margin-left: 0px;
        .holdContent{
            box-sizing: border-box;
            margin:0 auto;
           
            // width:1278px;
            // width: calc(100% - 58px );
            width: calc(100% - 32px);
            height: 100%;
            padding: 20px 30px;
            padding-bottom: 60px;
            background: #FCF9F3;
            border: 1px solid rgba(222, 224, 229, 0.5);
            box-sizing: border-box;
            border-radius: 5px;


            .recommends{
                margin-bottom:29px;
            }

            .widgetTitle{
                font-family: Poppins;
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
                line-height: 40px;
                /* identical to box height, or 100% */


                /* Dark blue */

                color: #103D49;

                .big {
                    display: none;
                }
                .medium {
                    display: none;
                }
                .small{
                    display: none;
                }
            }
            .widgetTxt {
                padding: 15px 0 45px 0;
                font-size: 20px;
                line-height: 25px;

                .small{
                    display: none;
                 }
                .big {
                     display: none;
                }
            }
             .button{
                width: 168px;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0.01em;
             }
        }
    }
}
@media only screen and (max-width: 800px){ // 768px
    .widgetBlack{
        width: initial;
        height: 355px ;
        .holdContent{
            box-sizing: border-box;
            // width: 100%;
            .widgetTitle{
                .big {
                    display: none;
                }
                .medium {
                    display: initial;
                }
                .small{
                    display: none;
                }
            }

            .widgetTxt{
                // font-size: 20px;
                // line-height: 30px;

                 padding:12px 0 74px 0;
                 .small{
                    display: none;
                 }
                 .big {
                     display: none;
                }
            }

            .button{}
        }
    }
}
@media only screen and (max-width: 620px){ //375
 .widgetBlack{
        height: fit-content;
        min-height: 330px;
        padding-bottom: 0px;
        margin-right: 0;
        .holdContent{
            
            border-radius: initial;
            width: 100%;
            margin:0 auto;
            height: 100%;
            
            padding: 20px 16px ;
            padding-bottom:40px;
            background-position: 70% 50%;

            .recommends{
                width: 184px;
                height: 25px;
                font-size: 13px;
                line-height: 25px;
                margin-bottom: 18px;
            }

            .widgetTitle{
                
                // font-weight: 800;
                // font-size: 48px;
                // line-height: 50px;
                /* or 104% */
                font-size: 34px;
                line-height: 40px;

                letter-spacing: 0.02em;

                .big {
                    display: none;
                }
                .medium {
                    display: none;
                }
                .small{
                    display: initial;
                }
            }

            .widgetTxt{
                //  padding:12px 0 44px 0;
                padding: 8px 0 39px 0;
                font-size: 19px;
                line-height: 25px;
                 .small{
                     display: initial;
                 }
                 .big{
                     display: none;
                 }
            }

            .button{
                // width: 164px;
                width: 100%;
                height: 50px;
              

                // font-weight: 500;
                // font-size: 15px;
                // line-height: 20px;
               

                display: flex;
                align-items: center;
                letter-spacing: 0.01em;


            }

        }
    }
}
@media only screen and (max-width: 368px){ //375
 .widgetBlack{
        // height: initial;
        // min-height: 380px;
        // padding-bottom: 0px;
 }
}

