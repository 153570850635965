
          @import "@/assets/css/vars.scss";
        

































































































































@import '@/assets/css/statsOverlay.scss';
 .newPrice{

        margin-top: 2px;
        position: relative;
        box-sizing: border-box;
        min-height: 319px;
        background: #062931;
        padding:19px 25px; // 15 +4 -oragngeLIne
        padding-top:19px !important;
        padding-right: 21px;
        border-radius: 6px 6px 0px 0px;
        color: #fff;

        display: flex;
        justify-content: space-between;

        .oragngeLIne{
            position: absolute;
            width: 100%;
            box-sizing: border-box;
            height: 5px;
            left: 0%;
            top: -1px;
            background: #FB9100;
            border-radius: 5px 5px 0px 0px;
        }
        .wrapValue{
            .txt{
                margin-top: -5px;
                .title{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 51px;
                    line-height: 55px;
                    /* or 108% */

                    // margin-top: 23px;
                    margin-top: 13px;
                    margin-bottom : 0px;
                    color: #FB9100;
                }
                .description{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 30px;
                    /* or 108% */

                    margin-top: 10px;
                    margin-bottom : 0px;
                    color:#CFD8DB; 

                }

            }
            .price{
                margin-top: 45px;
                position: relative;
                width:fit-content;
                p{
                    margin: 0;
                }
                .txtPrice{
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    opacity: 0.4;
                    // margin-bottom: 5px;
                    margin-bottom: 10px;
                }
                .p{
                    display: inline-block;
                    position: relative !important;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 36px;
                    line-height: 40px;
                    /* or 111% */

                    margin-left: 3px;

                    text-align: center;

                    /* Bg gray (6) */

                    color: #F8F8F9;
                }
            }

        }  

 }
.safari{
  // background-color: rgb(134, 94, 44) !important;
//    padding:0px 20px!important;
//    padding-right: 10px !important;
//   .txt{
//       p{
//         margin: 0 !important;
//         font-style: normal;
//         font-weight: bold;
//         font-size: 18px;
//         line-height: 26px;
//           color: $text-darker;
//       }
//     }
}
@media only screen and (max-width: 1024px){
      .newPrice{
            margin: 10px 10px 10px 12px !important;
            padding-left: 15px;
            padding-right: 15px;
            border-radius: 6px 6px 5px 6px;
      }
 }

@media only screen and (max-width: 550px) {
     .newPrice{
         display: block;
        .wrapValue{
            .txt{
                .title{
                    font-weight: 500;
                    font-size: 34px;
                    line-height: 35px;
                }
                .description{
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .price{
                margin-top: 45px;
                position: relative;
                width:fit-content;
                p{
                    margin: 0;
                }
                .txtPrice{
                    font-size: 14px;
                    line-height: 20px;
                }
                .p{
                    font-size: 34px;
                    line-height: 40px;
                    padding-left: 5px;
                }
            }

        }
      }
    
}

 @supports (-webkit-overflow-scrolling: touch) { 
     .newPrice{
             // padding-right:86px !important;
      }
        
}
