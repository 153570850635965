
          @import "@/assets/css/vars.scss";
        


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































  .main-wrapper{
    .badge-wrapper{
        border-radius: 5px 5px 0 0 !important;
        // margin-right:200px;
        height: 20px;
        width: auto;
        padding: 4px;
        line-height: 20px !important;
        font-size: 12px !important;
        
        box-sizing: border-box;
        // margin-left: 2px;
        padding-top:0px!important;
        padding-bottom: 0px!important;
        
        text-align: center;
    }
  }

// @media only screen and (max-width: 500px) {
//    .wrappBadgesEstim{
//     //  position: fixed !important;
//     //  left:0;
//     //  bottom:61px !important;
//     //  z-index: 15;
//    }
//      @supports (-webkit-overflow-scrolling: touch) { 
//     .wrappBadgesEstim {
//       // bottom:90px;
//       // z-index: 2;
//     }
//   }
// }
