.vinDiv3{
    // margin: 0;
    margin:0 auto;
    box-sizing: border-box;
    width: 512px;
    height: 60px;
    // border: 1px solid #92949B;
    border: 1px solid transparent;
    border-radius: 52px;
    position: relative;
    background: #F8F8F9;
    display: flex;
    flex-direction: row;
    align-items: center;
    input{
      background-color:#F8F8F9;
      // color: #9498A0;
      color: #062931;
      box-sizing: border-box;
      padding-left:29px;
      padding-right: 10px;
      flex-grow: 1;
      height:100%;
      border: none;
      border-radius: 52px;
      outline: none;
      // font-family: Gotham Pro;
       font-style: normal;
       font-weight: 400;
      //  font-size: 14px;
      //  font-size: 15px;
      font-size: 17px;
      line-height: 25px;
      //  line-height: 24px;
       /* or 171% */

       display: flex;
       align-items: center;
       letter-spacing: inherit;

       /* Gray 3 */

       // color: #B7B9C0;

    }
    input::placeholder{
      color: #9498A0; //#929498;
    }
    .buttonVin {
      cursor: pointer;
       // width: 101px;
       padding-left:37px;
       padding-right:38px;
       height: 50px;
       display: flex;
       margin-right: 3px;
       align-items: center;
       text-align: center;
       justify-content: center;
       background: $main-color;
       border-radius: 52px;
       position: relative;
       a{
         box-sizing: border-box;
        // width:100%;
         height: 100%;
         text-decoration:none;
       }
       p {
         box-sizing: border-box;
         // font-family: Gotham Pro;
         font-style: normal;
        //  font-weight: 500;
        //  font-size: 16px;
        //  line-height: 20px;
        line-height: 20px;
        font-weight: 500;
        font-size: 15px;
        letter-spacing: 0.01em;
         color: #FFFFFF;
         white-space: nowrap;
         margin-right:0;
         margin-left:0;

       }
    }
    .buttonVin:hover{
      background: $btn-hover;
    }

  }
  .positionOrange{
    position: absolute;
    top:0%;
    right: -10px;
  }
 
  @media only screen and (max-width: 768px){
    .vinDiv3{
      width: 402px;
      height: 60px;
      position: relative;
      input{
        padding-left:29px;
        padding-right: 10px;
        flex-grow: unset;
        width: 60% !important;
        height:100%;
        border: none;
        border-radius: 52px;
        outline: none;
         font-size: 12px;
         line-height: 24px;
         color: #B7B9C0;
         position: relative;
  
      }
       .buttonVin {
          cursor: pointer;
         // width: 98px;
         // flex-grow: 1;
         padding-left:20px;
         padding-right:20px;
          height: 48px;
          display: flex;
          margin-right: 3px;
          align-items: center;
          text-align: center;
          justify-content: center;
          background: $main-color;
          border-radius: 52px;
          position: absolute;
          right: 0!important;
          a{
            box-sizing: border-box;
            height: 100%;
            text-decoration:none;
            padding: 0;
            margin: 0;
          }
          p {
            // font-family: 'Gotham Pro Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 13px;
            color: #FFFFFF;
            margin:17px 0;
          }
    }

    }
  }//98,48
  @media only screen and (max-width: 500px){ //375
    .vinDiv3{
      width: 315px;
      height: 50px;
      position: relative;
      input{
        padding-left:20px;
        padding-right: 10px;
        flex-grow: unset;
        width: 62% !important;
        height:100%;
        border: none;
        border-radius: 52px;
        outline: none;
         font-size: 12px;
         line-height: 24px;
         color: #B7B9C0;
         position: relative;
  
      }
       .buttonVin {
          cursor: pointer;
         // width: 98px;
         // flex-grow: 1;
         padding-left:26px;
         padding-right:26px;
          height: 42px;
          display: flex;
          margin-right: 3px;
          align-items: center;
          text-align: center;
          justify-content: center;
          background: $main-color;
          border-radius: 52px;
          position: absolute;
          right: 0!important;
          a{
            box-sizing: border-box;
            height: 100%;
            text-decoration:none;
            padding: 0;
            margin: 0;
          }
          p {
            // font-family: 'Gotham Pro Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 13px;
            color: #FFFFFF;
            margin:15px 0;
          }
    }

    }
  }//98,48