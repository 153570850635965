
          @import "@/assets/css/vars.scss";
        



























.faqFeedback{
    display: flex;
    height: fit-content;
    align-items: center;
    padding: 21px 0;
    span{
        padding-right: 10px;
    }
    img{
        padding-right: 5px;
        cursor: pointer;
    }
}
