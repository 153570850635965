
          @import "@/assets/css/vars.scss";
        

















































































.sharePressRele { 
           // margin-top:16px;
           background: #ffffff;
            margin-left: 130px;
            // font-family: 'Gotham Pro Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            width: 297px;
            color: #062931;
            height: fit-content;
            position: -webkit-sticky;
            position: sticky;
            top: 20px;
            padding-bottom:20px;
            z-index: 230;
            .downloadDiv{
                border-top: 1px solid #DEE0E5;
                border-bottom: 1px solid #DEE0E5;
               p{
                   margin: 10px 0;
                   cursor: pointer;
                   width:fit-content;
                }
            }
            .iconShare {
                .txt{
                    p{
                        margin-top: 11px;
                        margin-bottom: 6px;
                    }

                }

            }

        }

@media only screen and (max-width: 1000px){ //change from 768px, due to the design and display of other elements
    .sharePressRele{
       width: 100% !important;
       margin-left: 0px !important;
    }
}
@media only screen and (max-width: 1000px){ //change from 768px, due to the design and display of other elements
    .sharePressRele{
       width: 100% !important;
       margin-left: 0px !important;
       // margin-bottom:60px; /* set in sharePressRele2 style */
    }
}
