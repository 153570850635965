
          @import "@/assets/css/vars.scss";
        













































































































































































































































































.latestPress{
    .latestContent{
        .contentWrapp{
            .latestPressContentItem{
                margin-right:30px;
                position: relative;
            }
            .latestPressContentItem:nth-of-type(3){
                margin-right: 0px!important;
            }
           
        }
    }  
}
@media only screen and (max-width: 1330px){
    .latestPress{
        .latestContent{
              padding-left: 15px !important;
            .contentWrapp{
                .latestPressContentItem{
                    margin-right:30px;
                    position: relative;
                }
                .latestPressContentItem:nth-of-type(2){
                    margin-right: 30px!important;
                }
                .latestPressContentItem:nth-of-type(3){
                    // margin-right: 30px!important;
                    // margin-top: 40px;
                    
                }
            
            }
        }  
    }

}
@media only screen and (max-width: 954px){ //768
    .latestPress{
    .latestContent{
        .contentWrapp{
            .latestPressContentItem{
                margin-right:20px;
            }
            .latestPressContentItem:nth-of-type(2){
                margin-right: 0px!important;
            }
             .latestPressContentItem:nth-of-type(3){
                margin-right: 30px!important;
                margin-top: 56px!important;
            }
           
        }
    }  
}
}
@media only screen and (max-width: 939px) {
    .latestPress{
    .latestContent{
        .contentWrapp{
            .latestPressContentItem{
                margin-right:20px;
            }
            .latestPressContentItem:nth-of-type(2){
                margin-right: 0px!important;
            }
             .latestPressContentItem:nth-of-type(3){
                margin-right: 30px!important;
                margin-top: 56px!important;
            }
           
        }
    }  
}
    
}
@media only screen and (max-width: 740px){ //375
    .latestPress{
    .latestContent{
          padding-left: 0px !important;
        .contentWrapp{
            
            .latestPressContentItem{
                margin-right:0px;
                margin:0 auto;
                margin-top: 32px;
            }
             .latestPressContentItem:nth-of-type(1){
                 margin:auto !important;
                 margin:0 auto !important;
                 margin-top: 0px;
            }
             .latestPressContentItem:nth-of-type(2){
                margin: auto !important;
                 // margin:0 auto !important;
                 margin-top: 32px !important;
            }
             .latestPressContentItem:nth-of-type(3){
                margin: auto !important;
                margin-top: 32px !important;
            }
        }
    }  
}
}
