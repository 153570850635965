
          @import "@/assets/css/vars.scss";
        

























































 @import '@/assets/css/singUp.scss';
    .enterEmail{
        width:406px;
        text-align: center;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
        .title{
            // font-family: Gotham Pro;
            // font-family: 'Gotham Pro Medium';
            // font-style: normal;
            // font-weight: 500;
            // font-size: 28px;
            // line-height: 40px;
            // text-align: center;

            color: #062931;
            font-weight: bold;
            font-size: 36px;
            line-height: 40px;
            /* identical to box height, or 111% */

            display: flex;
            align-items: center;
            text-align: center;
             margin-bottom: 34px;


        }
        .txt {
            // font-family: Gotham Pro;
            font-style: normal;
            // font-weight: normal;
            // font-size: 16px;
            // line-height: 24px;
            // text-align: center;
            // color: #062931;
            margin-bottom: 24px;

            font-weight: normal;
            font-size: 17px;
            line-height: 25px;
            /* or 147% */

            text-align: center;

            /* black */

            color: #062931;
            margin-bottom: 33px;

        }
        .sendButton {
            cursor: pointer;
            // margin-top: 30px;
            // margin-top: 15px;
             margin-top: 20px;
            // width: 366px;
            // height: 50px;
            // border-radius: 55px;

            // background: #FF6359;
            // border-radius: 55px;
            // // font-family: Gotham Pro;
            // font-style: normal;
            // font-weight: 500;
            // font-size: 16px;
            // line-height: 24px;
            // /* identical to box height, or 150% */

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #FFFFFF;
            width: 127px;
            height: 50px;
            left: 897px;
            top: 465px;

            /* Orange primary */

            background: #FF6359;
            border-radius: 35px;

        }
        .sendButton:hover{
            background: $btn-hover;
        }

    }
@media only screen and (max-width: 500px){
    .enterEmail{
        max-width:335px;
        margin:0 20px;
        .title{
            font-size: 28px;
            line-height: 30px;
        }
        .txt {
            font-size: 15px;
            line-height: 24px;
            margin-top: 39px;
            margin-bottom: 22px;
        }
        .sendButton {
            // margin-top: 30px;
            margin-top: 15px;
            width: 100%;
            height: 50px;
            border-radius: 55px;
            box-sizing: border-box;
            font-size: 15px;
            line-height: 24px;

        }

    }
    
}

