
          @import "@/assets/css/vars.scss";
        













































































































































































































.createNewsWrapper {
  padding-bottom: 100px;
  .input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .title {
      margin-bottom: 5px;
    }
    //   .img{
    //     box-sizing:border-box;
    //     width: 286px;
    //     height: 215px;
    //     background: #F8F8F9;
    //     overflow: hidden;
    //     img{
    //          object-fit: cover;
    //          min-width: 100%;
    //          min-height: 100%;
    //     }
    // }
    .img{
        box-sizing:border-box;
        width: 280px;
        height: 215px;
        background: #F8F8F9;
        overflow: hidden;
        img{
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
            height: 215px;
            width: auto;
        }
    }

  }
  .err{
    border: 1px solid  $main-color;
    border: 1px solid $err-color;
  }
  .errTxt {
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        // color: $main-color;
        color: $err-color;
        margin-top: 40px;
        // font-family: 'Gotham Pro Medium'
  }
  .flexButton{
    display: flex;
   .buttonCreate,
   .buttonReset{
        height: 50px;
        width: 170px;
        line-height: 52px;
        color: white;
        // font-family: 'Gotham Pro Medium';
        text-align: center;
        background: $main-color;
        border-radius: 52px;
        cursor: pointer;
        transition-duration: .3s;
        margin-bottom: 40px;
       p {
         margin: auto;

       }
    }
    .buttonReset {
      background: white;
      border: 1px solid $main-color;
      color: $main-color;
      margin-right: 20px;
    }
    .buttonCreate:hover{
      background: $btn-hover;
    }
    .buttonReset:hover{
      border: 1px solid rgb(206, 15, 2);;
      color: rgb(206, 15, 2);
    }
  }
}
