
          @import "@/assets/css/vars.scss";
        





















































































































































































































@import '@/assets/css/statsOverlay.scss';
.expMarket{
    position:relative;
    background: #FFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;
    //height: 100%;
    height: 503px;
    margin-bottom: 10px;
    // text-align: center;
    display:flex;
    flex-direction: column;
    align-items: center;
     .up{
         display:flex;
         width:100%;
         box-sizing: border-box;
         justify-content: space-between;
         align-items: center;
        //  font-style: normal;
        //  font-weight: bold;
        //  font-size: 18px;
        //  //line-height: 28px;
        //  letter-spacing: 0.01em;
        font-family: 'DM Sans';
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        /* or 143% */

        letter-spacing: 0.05em;
        text-transform: uppercase;

        /* Gray turquoise */

        color: #5A7E87;

        //  color: $text-darker;
         padding:0 20px;
         padding-top: 11px;

         p{
             margin-top: 11px;
         }
        .right-btns{
            display: flex;

            margin-top: -4px;
            
        }
     }
     .down{
        // width:82.5%;
        //  padding-left:40px;

        //  padding-top:20px;
         padding: 0px 40px;
         margin :0 auto;
         margin-top: -12px;
         margin-bottom: -18px;
         height:83%;
         display: flex;
         flex-direction: column;
         justify-content: center;
        // align-items: center;

         p{
            font-style: normal;
            // font-weight: normal;
            // font-size: 16px;
            margin-top:0;
            // line-height: 28px;
            //letter-spacing: 0.02em;
            color: #062931 !important;
            //margin: 5px !important;

            font-size: 17px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
            span{
                // font-family: 'Gotham Pro Bold';
                // font-style: normal;
                // font-weight: bold !important;
                font-size: 26px;
                line-height: 30px;
                /* or 106% */

                /* Text primary darker */

                font-style: normal;
                font-weight: bold;
                // font-size: 36px;
                // line-height: 40px;
                /* or 111% */


                /* black */

                color: #062931;

                // color:#062931;
            }


         }
         p:nth-child(odd) {
            span{
                font-style: normal;
                // font-weight: bold !important;
                // font-size:34px;
                //line-height: 36px;
                /* or 106% */
                 font-size: 26px;
                line-height: 30px;

                /* Text primary darker */

                color: #062931;
            }
             .upp{
                //  font-family: 'Gotham Pro';
                // font-style: normal !important;
                // font-weight: normal !important;
                // font-size: 16px !important;
                // text-transform: capitalize !important;

                font-size: 17px;
                font-weight: 400;
            }
            .low{
                //    font-family: 'Gotham Pro';
                //     font-style: normal !important;
                //     font-weight: normal !important;
                //     font-size: 16px !important;
                //     text-transform: lowercase !important;

                font-size: 17px;
                font-weight: 400;
              }

         }

        .upp{
            // font-family: 'Gotham Pro';
            // font-style: normal !important;
            // font-weight: normal !important;
            // font-size: 16px !important;
            // text-transform: capitalize !important;

            font-size: 17px;
            font-weight: 400;
            }
        .low{
            //    font-family: 'Gotham Pro';
            //     font-style: normal !important;
            //     font-weight: normal !important;
            //     font-size: 16px !important;
            //     text-transform: lowercase !important;

            font-size: 17px;
            font-weight: 400;
        }


     }
}

@media only screen and (max-width: 1024px){
  .expMarket {
    border-radius: 0px;
    width: 100%;
     .down{
         margin: 0 auto;
         width:70%;
         text-align: left;
         p{
            span{

                font-style: normal;
                font-weight: bold;
                font-size: 28px;

            }

         }
          p:nth-child(odd) {
            span{
                font-style: normal;
                font-weight: bolder !important;
                font-size:35px;
            //      .upp{
            //      font-family: 'Gotham Pro';
            //     font-style: normal !important;
            //     font-weight: normal !important;
            //     font-size: 16px !important;
            //     text-transform: capitalize !important;
            // }
            // .low{
            //    font-family: 'Gotham Pro';
            //     font-style: normal !important;
            //     font-weight: normal !important;
            //     font-size: 16px !important;
            //     text-transform: lowercase !important;
            //   }


            }

         }
      }
  }
}
@media only screen and (max-width: 768px){
  .expMarket {
    border-radius: 0px;
    width: 100%;
    height:initial;
    padding-bottom: 70px;
        .up{
            margin-top:20px ;
            p{
                margin-top:2px;
            }
        }
     .down{
         padding: 45px;
         padding-bottom: 13px;
         min-height: min-content;
         p{
             font-size:16px !important;
             line-height: 25px;
            span{
                // font-style: normal;
                // font-weight: bold;
                // font-size: 20px;
                font-style: normal;
                font-weight: 700 !important;
                font-size:25px;
                line-height: 30px;
            }
             .upp{
                 
                font-style: normal !important;
                font-weight: normal !important;
                font-size: 16px !important;
                // font-size: 15px !important;
                // text-transform: capitalize !important;
            }
            .low{
               
                font-style: normal !important;
                font-weight: normal !important;
                font-size: 16px !important;
                // font-size: 15px !important;
                // text-transform: lowercase !important;
            }

         }
          p:nth-child(odd) {
            span{
                font-style: normal;
                font-weight: 700 !important;
                font-size:25px;
                line-height: 30px;

            }
             .upp{
                 
                font-style: normal !important;
                font-weight: normal !important;
                font-size: 16px !important;
                // text-transform: capitalize !important;
            }
            .low{
               
                font-style: normal !important;
                font-weight: normal !important;
                font-size: 16px !important;
                // font-size: 15px !important;
                // text-transform: lowercase !important;
            }

         }
      }
  }
}
@media only screen and (max-width: 450px){
      .expMarket{
        .up{
            padding:0 16px !important;
        }
   }
 }
.hide-section {
    min-height: min-content;
    height: min-content;
    padding-bottom: 5px;
}
