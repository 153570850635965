
          @import "@/assets/css/vars.scss";
        















































































@import '@/assets/css/statsOverlay.scss';
.wrappMarketValue{
    position:relative;
    background: #FFFFFF;
    border-radius: 5px;
    width: 100%;
    //height: 100%;
    height: 379px;
    margin-top: 10px;
    margin-bottom: 10px;
    // padding-bottom: 9px;
    .up{
        display:flex;
        width:100%;
        box-sizing: border-box;
        // padding:0px 20px;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height, or 156% */

        letter-spacing: 0.05em;
        text-transform: uppercase;

        /* Gray turquoise */

        color: #5A7E87;
         padding:0 20px;
         padding-top: 10px;
         div{
             display: flex;
             align-items: center;
         }
         p{
             margin-top: 12px;
         }
     }
    .wrapMarketPrice{
        display:flex;
        width: 60%;
        width:65%;
        margin:auto;
        height: 68% !important;
        justify-content: center;
        align-items: center;
        margin-top: 2px;
        .border:first-of-type{
            border-right:1px solid #DEE0E5;
            
        }
    }


}
.wrappMarketValue.hideContent{
    height:fit-content;
    padding-bottom: 6px;
}
@media only screen and (max-width: 1024px){
  .wrappMarketValue {
    border-radius: 0px;
    width: 100%;
    margin-bottom: 10px;
    height: 300px;
  }
}
@media only screen and (max-width: 768px){
//   .wrappMarketValue {
//     border-radius: 0px;
//     width: 100%;
//     margin-bottom: 10px;
//     height: 470px;
//     .wrapMarketPrice{
//         display:flex;
//         flex-direction: column;
//          .border:first-of-type{
//             border-right:1px solid transparent;
//             position: relative;
//             // height:60%;
//             //border-bottom:1px solid #082633;
//         }

//         .border:first-of-type:after {
//             content: '';
//             position: absolute;
//             bottom: 0px;
//             width: 20%;
//             border-bottom:1px solid #082633;
//          }
//   }
// }
}
@media only screen and (max-width: 550px){
      .wrappMarketValue{
          height: 260px;
          .up{ 
            padding:10px 16px ;
            font-size: 15px;
            line-height: 25px;
            letter-spacing: 0.05em;
            align-items: initial;
            // padding-top: 20px;
            padding-top: 16px;
            p{ 
                // margin-top: 2px;
                // margin-top: 4px;
                  margin-top: 6px;
                width:200px;
            }
         }
        .up>div{
           height: fit-content;
           align-self: flex-start;
        }
         .wrapMarketPrice{
              height: 43% !important;
         }
      }
}
.wrappMarketValue.hideContent{
    height:fit-content;
    padding-bottom: 0px!important;
    .up{
        padding-bottom: 0!important;
        p{
            margin-bottom:14px;
        }
    }
}
