
          @import "@/assets/css/vars.scss";
        

























































































.searchBlog{
  width:100%;
  min-height: 100px;
  height:fit-content;
  
  border-radius:5px;
  border: 1px solid #DEE0E5;
  .wrapSearch{
    box-sizing: border-box;
    height: 50px;
    width: 100%;
    position: relative;

    input{
      box-sizing: border-box;
      width:100%;
      height: 100%;
      background: #F8F8F9;
      border: 1px solid transparent;
      border-bottom: 1px solid #DEE0E5;
      border-radius: 5px 5px 0 0;
      outline: none;
      padding-left: 21px;
       font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      color: #9498A0;
    }
    input::placeholder{
      // font-style: normal;
      // font-weight: normal;
      // font-size: 15px;
      // line-height: 20px;
      // /* identical to box height, or 133% */
      // letter-spacing: 0.01em;

      // /* Gray 3 text */

      // color: #9498A0;


      font-family: DM Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      color: #9498A0;


    }

    .imgSearch{
      position: absolute;
      top: 15px;
      right: 12px;
      cursor: pointer;
    }
 

  }
  .wrapCategory{
    min-height: 50px;
    padding-left: 21px;
    display: flex;
    align-items: center;
    
  }
}

