
          @import "@/assets/css/vars.scss";
        
























































































































.explanationStep{
     width: 28%;
     width:25%;
     margin: 0 1%;
     display: flex;
     justify-content: center;
     align-items: flex-start;

//     background-image: url("../../assets/images/bgImage/Background-Icons.png");
//     background-repeat: no-repeat;
//    /* background-position: center top; */
//     background-position: center;
//     background-size: cover;
//     background:red;
    .imgExp{
       // padding-top:10px;
        max-width: 296px;
        max-height: 296px; 
        max-width: 188px;
        max-height: 188px; 
//       // width:30%; ---arrow is visible
//       // width:45%;
        margin:0 auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        
      }
.expText {
    width:100%;
    text-align: center;
    box-sizing: border-box;
    font-style: normal;
    color: #062931;

    .expTitle {
        // font-weight: bold;
        // font-size: 20px;
        // line-height: 24px;
        // /* or 120% */
        // // text-align: center;
        // /* black */
        // color: #132727;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        color: #062931;
        margin-top: 31px ;
        margin-bottom: 11px;
    }
    .expDesc {
        font-size: 17px;
        line-height: 25px;
        text-align: center;
        color: #062931;
        margin-top: 0px ;
        margin-bottom: 0px;
    }
  }
}
.smallSize{
    width:21.46%;
}
 @media only screen and (max-width: 1000px){
    .explanationStep{
        width:25%;
        width: 29%;

        .expText {
   

        .expTitle {
            font-size: 26px;
            line-height: 30px;
        }
        .expDesc {
            font-size: 17px;
            line-height: 25px;
        }
     }
    }
    // .smallSize{
    //    width:26.46%;
    // }

  }
  @media only screen and (max-width: 767px){
    .explanationStep{
        box-sizing: border-box;
        width:100%;
        padding: 3% 7%;
        flex-direction: column;
        align-items: center;
    .expText {
        .expTitle {
            font-weight: bold;
            font-size: 19px;
            line-height: 24px;
            /* or 120% */
            // text-align: center;
            /* black */
            color: #132727;
        }
        .expDesc {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #062931;
        }

    }
  }
    .smallSize{
        box-sizing: border-box;
        width:100%;
    }
    #video-container{
        width:75%;
        padding:0px;
    }
    // .imgExp{
    //      width:10%;
    //     img{
    //        transform: rotate(90deg) !important;
    //     }
    // }

  }
