
          @import "@/assets/css/vars.scss";
        























































































































.wrappLogin{
    display: inline-block;
    margin-left:25px;
    margin-top: auto;
    position: relative;
    width: 35px;
    cursor: pointer;
    .loginImg{
        display: flex;
        height: 20px;
        margin-top: auto;
        justify-content: space-between!important;
        // align-items:flex-end;
        position: absolute;
        bottom: -4px;
        width: 100%;
        .imgRoundWrapp{
            // position: absolute;
            // bottom: -2px;
            // left: 0;
            width: 20px;
            height:20px;
            .imgRound{
              object-fit: cover;
              border-radius: 50%;
              height: 100%;
              width: 100%;
            }
         
        }
        .arrowRoundWrapp{
            width: fit-content;
            height:20px;
            padding-top: 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: 6px;
                
            }
        }
    }


    .loginContent{
        position: absolute;
        width: 154px;
        height: 70px;
        right: -10px;
        top: 46px;
        z-index: 1000;
        background: #ffffff;
        /* analytic container shadow */

        // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        // box-shadow: h-offset v-offset blur spread color ;
        // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.13);
        border-radius: 5px;
        padding: 6px 11px 7px 10px;
        .div{
            text-decoration: none;
            // font-family: Gotham Pro;
            font-style: normal;
            font-weight: normal;
            // font-size: 14px;
            // line-height: 26px;
            font-size: 15px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #4C4C52;
            box-sizing: border-box;
            //height: calc(50% - 7px);
            height: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                display: flex;
                justify-content: center;
                align-items: center;
                .arrow {
                    transform: rotate(-90deg);
                }
            }
        }
        .div:hover{
            color: #062931;
        }
        
    }
    .wide {
        height: 140px;
        .div {
            height: 25%;
        }
    }
}
@media only screen and (max-width: 768px){
    .wrappLogin{
         .loginContent{
            right: 0px;
         }

    }
}

