
          @import "@/assets/css/vars.scss";
        





















































































.singin{
    display: flex;
    width: 100%;
    height: calc(100vh - 80px);
    justify-content: center;
    // align-items: center;
    // padding-top: 87px;
    padding-top: 98px;
    
}
@media only screen and (max-width: 500px){
    .singin{
        height: initial;
        padding-top: 73px;
    }
}

