
          @import "@/assets/css/vars.scss";
        














































































.login {
  display: flex;
  max-width: 100vw;
  height: calc(100vh - 80px);
  justify-content: center;
	flex-direction: column;
  // align-items: center;
  // padding-top: 87px;
  // padding-top: 98px;
	.title {
		height: 185px;
		width: 100%;
    background: linear-gradient(116.78deg, #104756 13.98%, #3D7484 75.44%);
		display: flex;
		justify-content: center;
		position: relative;
		margin-bottom: 60px;
		h1 {
			margin: 0;
			position: absolute;
			bottom: 45px;
			font-size: 36px;
			color: #fff;
		}
	}
}
@media only screen and (max-width: 500px) {
  .login {
    height: initial;
    overflow-y: auto;
    // padding-top: 73px;
		.title {
			h1 {
				left: 16px;
				bottom: 30px;
			}
		}
  }
}
