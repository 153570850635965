
          @import "@/assets/css/vars.scss";
        




































































































































































































































































































































































































































































































































































































































































@media only screen and (max-width: 500px) {
 .priceSteps{
   .wrappBadges{
     position: fixed;
     left:0;
     bottom:61px !important;
     z-index: 2;
   }

 }
 @supports (-webkit-overflow-scrolling: touch) { 
    .priceSteps {
        // padding-bottom:70px
        // height: 60px; standard 
       .wrappBadgesIOS{
          // position: fixed;
          // left:0;
          // bottom:90px;
          // bottom:61px;
          // z-index: 2;
        } 
    }
}
}


