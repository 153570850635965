
          @import "@/assets/css/vars.scss";
        




































.rember {
    // margin-top:21px;
    // margin-top: 36px;
    // margin-bottom: 26px;
    // margin-top: 30px;
    margin-top: 25px;
    // margin-bottom: 25px;
    margin-bottom: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;

    span{
        padding-right: 8px;
        padding-right: 9px;
        height: 16px;
    }
    .textRember{
        // padding-top:5px;
        font-size: 15px;
        line-height: 20px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;
        letter-spacing: 0.01em;

        /* black */

        color: #062931;

    }
}
@media only screen and (max-width: 500px) {
    .rember{
        font-size: 14px;
        // line-height: 22px;
        display: flex;
        align-items: center;
        margin-bottom: 28px;
        
        span{
            padding-right: 9px;
        }
    }
    
}

