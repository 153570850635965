
          @import "@/assets/css/vars.scss";
        

















































































































































































































































































    .loginComponent{
        .last{ // basic:last-of-type
            // margin-bottom: 8px;
            margin-bottom: 7px;
        }
        .rember {
            // margin-top: 24px;
            // margin-bottom: 32px;
            // margin-top: 29px;
            // margin-top: 38px;
            // margin-bottom: 25px;
            // margin-bottom: 32px;
            margin-top: 0px;
            // margin-bottom: 30px;
            margin-bottom: 33px;
        }
        // .errMsg{
        //     margin-bottom: 7px !important;
        // }
    }
@media only screen and (max-width: 500px){
    .loginComponent{
        // .basic:last-of-type{
        //     margin-bottom: 10px;
        // }
        .rember {
            // margin-top: 26px;
            // margin-bottom: 28px;
            margin-top: 27px;
            margin-bottom: 40px;
        }
    }
}

