
          @import "@/assets/css/vars.scss";
        




















































































































@import '@/assets/css/statsOverlay.scss';
 .attractivness{
     position:relative;
     box-sizing:border-box;
     background: #ffffff;
     border-radius: 5px;
     box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
     padding:10px 20px;
     height: 383px;
     width: calc(50% - 5px)!important;
     margin-right:10px;

     .up{
         display:flex;
         width:100%;
         box-sizing: border-box;
        // padding:0px 20px;
         justify-content: space-between;
         align-items: center;
        //  font-style: normal;
        //  font-weight: bold;
        //  font-size: 18px;
        //  line-height: 28px;
        //  letter-spacing: 0.01em;
        //  color: $text-darker;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height, or 156% */

        letter-spacing: 0.05em;
        text-transform: uppercase;

        /* Gray turquoise */

        color: #5A7E87;
        div{
            display: flex;
            align-items: center;
        }

        p{
            margin-top:12px;
        }
     }
    //  .up>div{
    //      margin-top: 12px;
    //  }
     .down{
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         height: calc(100% - 105px);
         margin-top: -4px;
         margin-bottom: 4px;
         .number{
               width: 55%;
               margin-bottom: 10px;
             .p{
                //  position: relative;
                // font-family: 'Gotham Pro Bold';
                // font-style: normal;
                // font-weight: bold;
                // font-size: 22px;
                // line-height: 35px;
                // color: #132727;
                font-style: normal;
                font-weight: bold;
                font-size: 26px;
                line-height: 30px;
                line-height: 27px;
                //  overflow: hidden;
                display: flex;
                align-items: flex-end;
                // justify-content: space-between;
                color: #062931;
                margin: 0;
                width: 100%;
                // width: fit-content;
                .big{
                    //  display: block;
                    // margin-right:-13px;
                    margin-right:-3px;
                    position: relative;
                }

                .light{
                    //  display: block;
                    // margin-left: -8px;
                    margin-left: -3px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 22px;
                    line-height: 25px;
                    color: #76898E;
                    position: relative;
                    
                }
                .wrappLine{
                //    display: inline-block;
                   height: 20px; 
                   width: fit-content;
                   position: relative;
                   margin-bottom: 3px;
                   width: 20px;
                }
                .lightLine{
                   
                    // height: 100%;
                    // width: 17%;
                //    display: block;
                //     // border: 1px solid #9498A0;
                //     // transform: matrix(0.48, -0.84, 0.94, 0.44, 0, 0);
                //     width: 20px;
                //     border-bottom: 1px solid #9498A0;
                //     transform: rotate(-45deg) ;

    height:1px;
    width: 20px;
    background: #9498A0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    // transform: rotate(-45deg);
    transform: rotate(-65deg);



                }
             }
         }
         .diagram{
             box-sizing: border-box;
             height: 10px;
             width: 55%;
            //  padding:1px 2px;

             background: #FFFFFF;

             border: 1px solid rgba(222, 224, 229, 0.7);
            box-sizing: border-box;
            border-radius: 15px;
             display:flex;
             align-items: center;
             .red{
                 height: 100%;
                 border-radius: 15px;
                 box-sizing: border-box;
                 background: $main-color;
                 background: #FF9F59;
                 transition-duration: .5s;
             }
         }
     }
 }
    .pLine{
            width: 27px;
            height:3px;
            font-family: Gotham Pro;
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 35px;
            text-align: center;
            background-color: #062931;
            margin-top:40px;
         }
.attractivness.hideContent{
    height:fit-content;
    padding-bottom: 6px;
}
 @media only screen and (max-width: 1024px){
      .attractivness{
           box-sizing: border-box;
            margin: 0 0 !important;
            border-radius: 0px !important;
            box-shadow: none;
            width: 100% !important;
            height: 300px;

             .down{
                height: calc(100% - 89px);
             }
      }
 }

 @media only screen and (max-width: 550px){
    .attractivness{
        padding:10px 16px;
        height: 240px;

        .up{
            font-size: 15px;
            line-height: 25px;
            letter-spacing: 0.05em;
            align-items: center;
            // margin-top: 10px;
            margin-top: 6px;

            p{
                //  margin-top:2px; 
                //  margin-top: 10px;
                margin-top: 6px;
               
            }
        }
        .up>div{
          height: fit-content;
          align-self: flex-start;
					align-items: center;
        }

        .down{
            height: calc(100% - 77px);
            .number{
                width: 55%;
                margin-bottom: 10px;
                p{
                    font-size: 25px;
                    line-height: 30px;
                    .light{
                        font-size: 21px;
                        line-height: 25px;
                    }
                }
            }
            .diagram{
            
                width: 55%;
                .red{
                    height: 100%;
                    border-radius: 15px;
                    box-sizing: border-box;
                    background: $main-color;
                    transition-duration: .5s;
                }
            }
        }

    }
 }
