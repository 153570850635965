
          @import "@/assets/css/vars.scss";
        























































































































































































































































































































.edit-news-wrapper {
   padding-bottom: 100px;
  .showeMore {
    margin: 35px auto;
    // font-family: Gotham Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    width:fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4C4C52;
    border: 1px solid #C3C6D0;
    box-sizing: border-box;
    border-radius: 52px;
    padding: 14px 30px;
    cursor:pointer;

  }
  .edit {
    padding-bottom: 100px;
    .input-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      .title {
        margin-bottom: 5px;
      }
        .currentImg {
          // display: flex;
          // flex-direction: row;
          // align-items: center;
          width: 280px;
          height: 215px;
          overflow: hidden;
          
          .current-image {
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
            height: 215px;
            width: auto;
          }
      }
      .remove {
            cursor: pointer;
            margin-left: 20px;
            color:$main-color;
          }
    }
    .err{
      // border: 1px solid  $main-color;
      border: 1px solid $err-color;
    }
    .errTxt {
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          // color: $main-color;
          color: $err-color;
          margin-top: 40px;
          // font-family: 'Gotham Pro Medium'
    }
    .flexButton{
      display: flex;
   .buttonCreate,
    .buttonReset{
          height: 50px;
          width: 170px;
          line-height: 52px;
          color: white;
          // font-family: 'Gotham Pro Medium';
          text-align: center;
          background: $main-color;
          border-radius: 52px;
          cursor: pointer;
          transition-duration: .3s;
          margin-bottom: 40px;
        p {
          margin: auto;

        }
      }
      .buttonReset {
        background: white;
        border: 1px solid $main-color;
        color: $main-color;
        margin-right: 20px;
      }
      .buttonCreate:hover{
        background: $btn-hover;
      }
      .buttonReset:hover{
        border: 1px solid rgb(206, 15, 2);;
        color: rgb(206, 15, 2);
      }
    }
  }
}
.return {
    margin-bottom: 30px;
    margin-left: auto;
    cursor: pointer;
    width: 50px;
  }
