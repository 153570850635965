
          @import "@/assets/css/vars.scss";
        

































































































































































.wrappFooterDown {
  background: #103d49;
  .holdContent {
    width: 100%;
    height: 330px;
    display: flex;
    flex-direction: column;
		background-color: #F8F8F9;
    .footer-wrapper {
			max-width: 1272px; // 1920 - 2x 324px
			width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      .footer-links {
        height: 100%;
        max-width: 725px;
				width: 100%;
        display: flex;

        .link-section {
					height: calc(100% - 55px);
					padding-top: 55px;
					width: auto;
          max-width: 145px;
					margin-right: 80px;
          display: inline-flex;
          flex-direction: column;
          .section-title {
						inline-size: max-content;
						font-weight: 500;
						font-size: 14px;
						letter-spacing: 0.05em;
						text-transform: uppercase;
						color: #5A7E87;

						margin-bottom: 7px;

          }
          .section-link {
						font-weight: 400;
						font-size: 15px;
						letter-spacing: 0.01em;
						line-height: 25px;
						color: rgba(6, 41, 49, 0.8);
						inline-size: max-content;
						text-decoration: none;
						cursor: pointer;
          }
        }
      }
      .footer-socials {
        height: calc(100% - 60px);
        width: 110px;
        display: flex;
        justify-content: space-between;
				padding-top: 60px;

        .social-wrapp {
          height: 20px;
          width: 20px;
					cursor: pointer;
					img {
						height: 100%;
					}
        }
      }
    }
    .copyright {
      max-width: 1272px;
			width: 100%;
      height: 15px;
			font-weight: 400;
			font-size: 12px;
			letter-spacing: 0.01em;
			color: rgba(6, 41, 49, 0.4);
      margin: 0 auto;
			margin-bottom: 22px;
    }
  }
}
.wrappFooterDown2 {
  background: #ffffff;
}
.wrappFooterDown3 {
  background: #f3f5f6 !important;
}

@media only screen and (max-width: 768px) {
	.wrappFooterDown {
		.holdContent {
			height: 370px;
			padding: 0 27px;
			.footer-wrapper {
				flex-direction: column;
				.footer-links {
					.link-section {
						padding-top: 45px;
						margin-right: 60px;
					}
				}
				.footer-socials{
					padding-top: 0;
					padding-bottom: 53px;
					max-height: 20px;
				}
			}
		}
	}
}
