
          @import "@/assets/css/vars.scss";
        


















































.litleBadge{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 25px;
    /* identical to box height, or 208% */
    height: 30px;

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    /* black */
    background: rgba(255, 255, 255, 0.07);
    border-radius: 50px;
    margin: 10px 0px;
    color: rgba(205, 216, 216, 0.8);
    cursor: pointer;
    transition-duration: .3s;
    div{
        margin: auto;
    }

}
.litleBadge:hover{
    cursor: pointer;
    background: rgba(255, 255, 255, 0.9);
    color: #062931;
}
// .disabled{
//     cursor: initial;
//     // opacity: 0.6;
//     // color: #062931c4;
// }
// .disabled:hover{
//     cursor: not-allowed;
//     background: #EEEFF3;
// }
.selected{
    background: rgba(255, 255, 255, 0.93);
    color: #062931;
}
.selected:hover{
     background: #DEE0E5;
}


@media only screen and (max-width: 550px) {
    .litleBadge{
        width: 60px;
        height: 25px;
        margin-right: 10px;
    }
}
