
          @import "@/assets/css/vars.scss";
        






















































































































































































































































































.mobileNav {
  position: absolute;
  pointer-events: auto;
  // position:sticky;

  /*position: fixed doesn't work in most of the older versions of iOS and Blackberry.
    I have tried this fix in most of the mobile browsers and it worked smoothly without any JavaScript plugins.*/
  -webkit-backface-visibility: hidden !important;

  left: 0;
  bottom: 0px;
  width: 100vw;
  // height: 60px;
  // left: calc(50% - 375px/2);
  height: 61px;
  // height: fit-content!important;
  border-top: 1px solid #eeeff3;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  // justify-content: space-around;
  flex-direction: column;
  //  transform: translate3d(0,0,0);
  .separator {
    padding: 12px 47px 0px 47px;
    width: calc(100% - 94px);
    height: 61px;
    display: flex;
    justify-content: space-around;
  }
  .toolbarItem {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-bottom: 7px;
      box-sizing: border-box;
      height: 27px;
      .imgMore {
        //  height: 20px;
      }
    }
    .menuTitle {
      // font-family: Gotham Pro;
      // font-style: normal;
      // font-weight: 500;
      // font-size: 10px;
      // line-height: 10px;
      /* identical to box height */
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;

      display: flex;
      align-items: flex-end;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: capitalize;

      /* black */

      color: #787883;
    }
    .activeTitle {
      font-weight: 500;
      color: #062931;
    }
  }
  .sample-report-bar {
    // position: fixed;
    // left: 0;
    // bottom: 61px;
    height: 40px;
    width: 100%;

    .sample-report-wrapper-in-nav {
      background: $main-color;
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow-x: hidden;
      z-index: 999;
      // align-self: flex-start;
      // top: 100px;

      @keyframes example {
        from {
          right: -200px;
        }
        to {
          right: 2760px;
        }
      }

      .sample-report-in-nav {
        width: auto;
        // font-family: 'Gotham Pro Bold';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #e7eced;
        cursor: default;
        position: absolute;
        animation-duration: 40s;
        animation-timing-function: linear;
        animation-name: example;
        animation-iteration-count: infinite;
        white-space: nowrap;
        animation-fill-mode: forwards;
        right: -200px;
      }
    }
  }
}
.big {
  // height: 61px;
}
.red {
  color: red !important;
}
.alt {
  height: 101px;
}
// just for safari because he is only who support this
@supports (-webkit-overflow-scrolling: touch) {
  .mobileNav {
    // padding-bottom:70px
    // height: 60px; standard
    // height: 90px;
  }
  .big {
    height: 90px !important;
    //  background: brown;
  }
}
