
          @import "@/assets/css/vars.scss";
        


































































































































































































































.links{
       #link{
           text-decoration: unset !important;
           font-style: normal;
           font-weight: normal;
           font-size: 14px;
           line-height: 26px;
           color: #4C4C52;
           margin-left:30px;
           white-space: nowrap;

       }
       #link:hover{
           // color: #132727;
           color: #062931;
           cursor: pointer;
       }
}
@media only screen and (max-width: 1100px){
    .links{
        #link{
             margin-left:0;
             margin-right: 30px;
        }
    }
}

