
          @import "@/assets/css/vars.scss";
        



































































































































































































































.home-dropdown-wrapper {
  width: 167px;
  position: relative;
  height: 38px;
  box-sizing: border-box;
  // padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border-left: 1px solid #787883;
  // overflow-x: auto;

  .not-searchable {
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
    outline: none;
    // border-right: 1px solid #B7B9C0;
    padding-right: 10px;
    color: #92949B;
    font-size: 16px;
    padding-top: 3px;
    cursor: pointer;
  }
  .not-searchable.black {
    color: #132727;
  }

  input {
    max-width: 100%;
    border: none;
    margin: 0;
    padding: 0;
    outline: none;
    height: 38px;
    // border-right: 1px solid #B7B9C0;
    padding-right: 10px;
    // color: #132727;
    // font-size: 16px;
    cursor: pointer;
    // font-family: "Gotham Pro";
    
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;

    color: #062931;

    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    /* identical to box height, or 147% */

    display: flex;
    align-items: center;

    /* Gray 3 text */

    // color: #9498A0;




  }
  input::placeholder {
    opacity: 1;
    color: #9498A0;
   // font-family: "Gotham Pro";
  }
  input.black::placeholder {
    color: #132727;
  }
  input.black::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #132727;
  }
  input.black::-moz-placeholder { /* Firefox 19+ */
    color: #132727;
    opacity: 1;
  }
  input:disabled {
    background: white;
  }
  .home-dropdown {
    position: absolute;
    z-index: 2;
    background: #FFF;
    display: flex;
    flex-direction: column;
    // top: calc(100% + 20px);
    // top: calc(100% + 15px);
    top: 69px;
    left: -43px;
    width: 400px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    // border-radius: 15px;
    padding-bottom: 15px;
    // max-height: 356px;
    max-height: 294px;
    // padding-right: 5px;

    .scrollbar {
      height: 100%;
      // height: 50%;
      overflow-y: scroll;
      // padding-right: 5px;
      // margin-right: -5px;
    }
    .scrollbar::-webkit-scrollbar {
      width: 6px;
      margin-left: -5px;
    }
    .scrollbar {
      scrollbar-width: thin;
      scrollbar-color: #4C4C52 white;
    }
    .scrollbar::-webkit-scrollbar-track {
      background: white;
    }
    .scrollbar::-webkit-scrollbar-thumb {
      background-color: #4C4C52;
      border-radius: 3px;
    }
    .scrollbar::-moz-scrollbar-thumb {
      background-color: #4C4C52;
      border-radius: 3px;
    }
    .overflowNone {
      overflow-y: hidden;
    }
    .option-wrapper {
      .line {
        // height: 1px;
        border-top: 2px solid  #DEE0E5;;
        color: #9CB2B5;
        // font-size: 11px;
        // line-height: 32px;
        padding-left: 20px;
        padding-top: 5px;
        // font-size: 17px;
        // line-height: 59px;
        // color: #062931;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 15px;
        /* or 136% */
        // display: flex;
        // align-items: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        /* Gray 3 */

        color: #B7B9C0;

      }

      &:only-child {
        .option.dark {
          border-bottom: none;
        }
      }
    }

    .option-wrapper.active {
      background-color: #F8F8F9;
      position: relative;
      // margin-top: -1px;

      &:only-child {
        .option {
          border-bottom: none;
        }

      }
    }
    .option-wrapper.hover {
      background-color: #F8F8F9;
      position: relative;
      // margin-top: -1px;
      .option {
        // border-bottom: none;
      }
    }
    .option-wrapper.hover.line-wrapper {
      background-color: white !important;
    }
    .option.dark {
      border-bottom: 1px solid #EEEFF3;
    }
    .option {
      min-height: 59px;
      display: flex;
      align-items: center;
      width: calc(100% - 20px);
      margin: 0 auto;
      box-sizing: border-box;
      cursor: pointer;
      border-bottom: 1px solid #F8F8F9;
      padding-left: 10px;

      .option-inner {
        height: 50px;
        display: flex;
        align-items: center;
        width: 100%;
        font-size: 17px;
        line-height: 59px;
        /* or 347% */


        /* black */

        color: #062931;


        span {
          margin-bottom: -5px;
        }
      }
    }
    // .option-wrapper:last-child .option {
    //   border-bottom: none;
    // }
    .option-wrapper.before-line .option {
      border-bottom: 1px solid transparent;
    }
    .option-wrapper.before-line:hover .option {
      border-bottom: 1px solid transparent !important;
    }
  }
  .home-dropdown.small {
    max-height: 302px;
  }
  .return {
    display: none;
    position: absolute;
    z-index: 2;
    // top: calc(100% + 10px);
    top: 100%; //calc(100% - 2px)
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: 0.01em;

    color: rgba(70, 127, 143, 0.8);
    padding-top: 44px;

    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    &:hover {
      color: rgba(70, 127, 143, 1);
      svg {
        path {
          stroke-opacity: 1;
        }
      }
    }
  }
}
.priceSteps {
  .return {
    display: block;
  }
  input {
    background-color: #F8F8F9 !important;
  }
  .home-dropdown {
    box-shadow: none;
    // width: 315px;
  }
  .option-wrapper.active, .option-wrapper.hover {
    background-color: white !important;
    margin-top: 0px !important;
    .option {
      border-bottom: 1px solid #EEEFF3 !important;

      .option-inner {
        // width: 300px !important;
        position: absolute;
        left: 0px;
        background-color: #F8F8F9;
        border-radius: 5px;
        padding-left: 20px;
        box-sizing: border-box;
      }
    }

    &:only-child {
      .option {
        border-bottom: none !important;
      }
    }
  }
  .option-wrapper {
    .line {
      padding-left: 0px !important;
      width: calc(100% - 20px);
      margin-left: 10px;
    }
  }
}
@media only screen and (max-width: 767px){
  .home-dropdown-wrapper {
    .home-dropdown {
      top: 69px;
      left: -50px;
      width: 400px;
    }
  }
}
@media only screen and (max-width: 450px){
  .home-dropdown-wrapper {
    .home-dropdown {
      top: 64px;
      left: -43px;
      width: 315px;
    }
  }
  .priceSteps {
    .center-wrapper {
      .return {
        position: fixed;
        top: 20px;
        left: 16px;
        padding-top: 0;
        line-height: 14px;
        margin-bottom: 0;
        width: fit-content;
        // top: -27px;
        // left: -170px;
      }
    }
  }
}
@media only screen and (max-width: 375px) {
  .priceSteps {
    .center-wrapper {
      .return {
        position: fixed;
        top: 20px;
        left: 16px;
      }
    }
  }
}
