
          @import "@/assets/css/vars.scss";
        































































































































































































































































































































































































































































































































.mediaAssetsPage{
        .holdContent{
            .logos,
            .otherImg {
                .wrappAssetsImg:nth-of-type(4n){
                    margin-right: 0px;
                }
            }
           
        }
    }

    
@media only screen and (max-width: 1330px){
   .mediaAssetsPage{
        .holdContent{
            .logos,
            .otherImg {
                   .wrappAssetsImg:nth-of-type(4n){
                   // padding-right: 30px;
                   // margin-right: 30px;
                }
                 .wrappAssetsImg:nth-of-type(3n){
                   //padding-right: 0px;
                   margin-right: 0px !important;
                }
            }
           
        }
    }
}
@media only screen and (max-width: 768px){
   .mediaAssetsPage{
        .holdContent{
            .logos,
            .otherImg {
                .wrappAssetsImg:nth-of-type(2n){
                    //padding-right: 0px;
                }
                .wrappAssetsImg:last-of-type{
                    //padding-right: 0px;
                    .info {
                        padding-bottom:0px;
                    }
                }
            }
           
        }
    }
}

