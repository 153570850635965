
          @import "@/assets/css/vars.scss";
        































































































.admin-page-wrapper {
  min-height: calc( 100vh - 80px );
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 30px;
  max-width: calc(1440px + 60px);
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 30px;

  .left {
    display: flex;
    flex-direction: column;
    width: 120px;

    .section {
      border-right: 1px solid #4C4C52;
      // font-family: "Gotham Pro Medium";
      cursor: pointer;
      color: #4C4C52;
      padding: 7px 0;
      cursor: pointer;
      transition-duration: .3s;
    }
    .section.active, .section:hover {
      color: $main-color;
    }
    span {
      text-transform: uppercase;
    }
  }
  .right {
    padding-left: 20px;
    width: 100%;
  }
}
