
          @import "@/assets/css/vars.scss";
        

















































































































































































































.wrappPressReleases {
  .reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .pressLine {
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #dee0e5;
  }
  .holdContent {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    width: 1278px;
    padding-bottom: 113px;
    min-height: 61vh;
    .title {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      /* identical to box height, or 111% */

      display: flex;
      align-items: center;

      /* black */

      color: #062931;
      margin-top: 98px;
      margin-bottom: 59px;
    }
    .roundTxt{
        max-width: 733px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 25px;
        /* or 147% */
        /* black */

        color: #062931;
        margin-bottom: 53px;
        p{
            margin-top: 0;
        }
        p:last-of-type{
            margin-bottom: 0;
        }


        .link{
            // color: $main-color;
            color: #062931;
            text-decoration: none;
            font-weight: 500;
        }
        .link:hover{
            // color: $btn-hover;
            opacity: .8;
        }
    }
    .year {
      width: fit-content;
      font-family: "Gotham Pro Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-decoration: line;
      letter-spacing: 0.02em;
      color: #062931;
      position: relative;
      span {
        // display:block;
        position: absolute;
        width: 100%;
        height: 0px;
        left: 0px;
        bottom: 0px;
        border-bottom: 1px solid #062931;
      }
    }
    .showMore {
      text-align: center;
      font-family: Gotham Pro;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      color: #5a7e87;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1330px) {
  .wrappPressReleases {
    .holdContent {
      box-sizing: border-box;
      margin: 0 auto;
      //width:1278px;
      width: calc(100% - 58px);
      margin: 0 29px;
      .title {
        // margin-top: 46px;
        // margin-bottom: 64px;
        // font-size: 40px;
        // line-height: 46px;
      }
    }
  }
}
@media only screen and (max-width: 620px) {
  //375
  .wrappPressReleases {
    .holdContent {
      padding-top: 47px;
      padding-bottom: 38px;
      margin: 0 auto;
      height: initial;
    }
  }
}
@media only screen and (max-width: 475px) {
  //375
  .wrappPressReleases {
    // background: bisque;
    .holdContent {
      padding-top: 34px;
      // padding-left: 16px;
      // padding-right: 16px;
      height: initial;
      margin-left: 16px;
      margin-right: 16px;
      width: initial;
    }
  }
}
