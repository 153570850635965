
          @import "@/assets/css/vars.scss";
        













































































































#home-page {
  max-width: 100%;
  background: $background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
